<nb-card class="col-md-8 mx-auto" accent="info">
  <nb-card-header>Mantenimiento de tarifas</nb-card-header>
  <nb-card-body>
    <form #form="ngForm" (ngSubmit)="onSubmit(form)">
        <div class="row">
            <div class="col-sm-5">
                <div class="form-group">
                    <label for="name" class="label">Nombre</label>
                    <input type="text" nbInput fullWidth name="name" nbTooltip="Nombre de la empresa" 
                            nbTooltipPlacement="top" [(ngModel)]="_rate.rate" required />
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="totalDeliveries" class="label">Envíos</label>
                    <input type="number" nbInput fullWidth name="totalDeliveries" nbTooltip="Total envíos"
                    		[(ngModel)]="_rate.totalDeliveries" nbTooltipPlacement="top" required="true" />
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="amount" class="label">Costo</label>
                    <input type="text" nbInput fullWidth name="amount" nbTooltip="Costo por tarifa"
                            nbTooltipPlacement="top" [(ngModel)]="_rate.amount" required />
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <button nbButton ghost status="success">
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </form>
  </nb-card-body>
</nb-card>
<nb-card class="col-md-8 mx-auto" accent="info">
  <nb-card-header>Listado de tarifas</nb-card-header>
  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="list"></ng2-smart-table>
  </nb-card-body>
</nb-card>
