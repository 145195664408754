export class PersonAddress{
	constructor(
		public id : number,
		public idCustomer : number,
		public address : string,
 		public latitude : string,
		public longitude : string,
		public idCatAddress : number,
		public idStatus : number,
		public idAddressZone: number,
		public idAddressMunicipality: number,
		public condominiumName:string,
		public accessCode:string
	){}
}