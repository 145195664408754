<nb-card class="col-md-5 mx-auto" accent="info">
  <nb-card-header>Mantenimiento de vehiculos</nb-card-header>
  <nb-card-body>
    <form #form="ngForm" (ngSubmit)="onSubmit(form)">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="name" class="label">Nombre vehículo</label>
                    <input type="text" nbInput fullWidth name="name" nbTooltip="Nombre del vehículo" nbTooltipPlacement="top" [(ngModel)]="_vehicle.name" required />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                  <label for="button" class="label label-button">Boton guardar</label>
                    <button nbButton ghost status="success">
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </form>
  </nb-card-body>
</nb-card>
<nb-card class="col-md-5 mx-auto" accent="info">
  <nb-card-header>Listado de vehiculos</nb-card-header>
  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="list"></ng2-smart-table>
  </nb-card-body>
</nb-card>
