<app-loading *ngIf="loading"></app-loading>
<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
  </div>
  <div class="image-contaner">
    <img class="logo-img" [src]="urlLog">
  </div>
  <!-- <div class="header-container">
    <a class="logo" (click)="navigateHome()" style="cursor: pointer;">
      <span style="color:#ff8c21;">m</span><span>Tracking</span>
    </a>
  </div> -->
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value">
          {{ theme.name }}</nb-option>
      </nb-select> -->

</div>


<div class="header-container">
  <a href="tel:+50245977747"><i class="fa fa-phone-square fa-3x" aria-hidden="true" *ngIf="userDB.idRol === 4"></i> </a>
  &nbsp;&nbsp;&nbsp;
  <nb-user [name]="userAvatar" nbTooltip="{{userAvatar}}" nbTooltipStatus="info"
    [picture]="userPicture === userEmail ? 'assets/images/avatar/avatarWomen.png' : userPicture" [showTitle]="true"
    style="padding-right: 10px !important;" onlyPicture showInitials [nbContextMenu]="userMenu"
    nbContextMenuTag="userMenu">
  </nb-user>
  <!-- <nb-icon icon="log-out-outline" style="cursor: pointer;" (click)="logOut()"></nb-icon> -->
</div>