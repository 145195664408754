import { Component, OnInit, TemplateRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { User } from '../../models/user';
import { UserToken } from '../../models/userToken';
import { Task } from '../../models/task';
import { TaskService } from '../../services/task.service';
import { UserService } from '../../services/user.service';
import { Sucursal } from '../../models/sucursal';
import { SucursalService } from '../../services/sucursal.service';
import { Person } from '../../models/person';
import { ClientService } from '../../services/client.service';
import { Address } from '../../models/address';
import { AddressService } from '../../services/address.service';
import { CompanyService } from '../../services/company.service';
import { CompanyVehicleRate } from '../../models/companyVehicleRate';
import { PersonAddress } from '../../models/person-address';
import { PersonAddressService } from '../../services/person-address.service';
import { NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition, NbDialogRef } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, startWith } from 'rxjs/operators';
import * as mapboxgl from 'mapbox-gl';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { MessagingService } from '../../services/messaging.service';
import { TrackingStatusService } from 'src/app/services/trackingStatus.service';

interface MarcadorColor {
  color: string;
  marker?: mapboxgl.Marker;
  centro?: [number, number];
}

@Component({
  selector: 'app-task-entry',
  templateUrl: './task-entry.component.html',
  styleUrls: ['./task-entry.component.scss'],
  providers: [TaskService, CompanyService,
    SucursalService, ClientService, PersonAddressService, AddressService,TrackingStatusService]
})
export class TaskEntryComponentClient implements AfterViewInit, OnInit {

  public task: Task;
  public tasks: Task[];
  public userToken: UserToken[];
  public person: Person;
  public person2: Person;
  public personAddress: any[];
  public personAddress2: any[];
  public _personAddress: any;
  public _personAddress2: any;
  public tasksList = new LocalDataSource();
  public sucursales: Sucursal[];
  public clients: any[];
  public clients2: any[];
  public companyVehicle: CompanyVehicleRate;
  public role: number;
  public _idSucursal;
  public _namePetitioner;
  public _nameReceiver;

  public mPerson: Person;
  public mAddress: Address;
  public mPersonAddress: PersonAddress;
  public _mAddress: Address[];

  public mDperson: Person;
  public mDaddress: Address;
  public mDpersonAddress: PersonAddress;
  public _mDaddress: Address[];
  public user1=new Array();
  public user = {} as User;
  public userDB = {} as User;
  public flgInfo;
  public flgMapa;
  public flgCliente;
  public flgDireccion;
  public tiempo;

  /* Autocomplete */
  //options: any[];
  filteredControlOptions$: Observable<string[]>;
  filteredNgModelOptions$: Observable<string[]>;
  inputFormControl: FormControl;

  filteredControlOptions2$: Observable<string[]>;
  filteredNgModelOptions2$: Observable<string[]>;
  inputFormControl2: FormControl;

  /* Integracion mapboxgl */
  public _latitude;
  public _longitude;

  @ViewChild('map', { static: false }) divMap!: ElementRef;
  map!: mapboxgl.Map;
  zoomLevel: number = 10;
  center: [number, number] = [-90.49322418747106, 14.580160123137151];

  // Arreglo de marcadores
  marcadores: MarcadorColor[] = [];

  constructor(
    private _taskService: TaskService,
    private _userService: UserService,
    private _sucursalService: SucursalService,
    private _clientService: ClientService,
    private _companyService: CompanyService,
    private _personAddressService: PersonAddressService,
    private toastrService: NbToastrService,
    private router: Router,
    private _route: ActivatedRoute,
    private dialogService: NbDialogService,
    private _addressService: AddressService,
    private _router: Router,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private messagingService: MessagingService,
    
    private _trackingStatusService: TrackingStatusService,
  ) {
    this.task = new Task(0, null, null, null, null, null, null, null,null, null, null, null, null, null, null, null, 1,
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,null,null,null,null,null,null,null,null,null,null,0);

    this.person = new Person(0, null, null, null, null, 1,0);
    this.person2 = new Person(0, null, null, null, null, 1,0);
    this._personAddress = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");
    this._personAddress2 = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");

    this.mPerson = new Person(0, null, null, null, null, 1,0);
    this.mAddress = new Address(0, null, 1);
    this.mPersonAddress = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");

    this.mDperson = new Person(0, null, null, null, null, 1,0);
    this.mDaddress = new Address(0, null, 1);
    this.mDpersonAddress = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");

    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    this.role = user.idRol ? parseInt(user.idRol.toString()) : 0;
    this._idSucursal = user.idSucursal ? parseInt(user.idSucursal.toString()) : 0;
  }

  ngAfterViewInit() {

    this.map = new mapboxgl.Map({
      container: this.divMap.nativeElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: 15,
    });

    //console.log(this.divMap.nativeElement);

  }
  addressType = [
    {
      value: 1,
      name: 'Casa',
    },
    {
      value: 2,
      name: 'Oficina',
    },
  ];
  async ngOnInit() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    console.log(userLogged);
    // localStorage.setItem('user', JSON.stringify(userLogged));
    await this.getUserList();
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:none;');

    this._route.paramMap.subscribe(async (params: any) => {
      const idSucursal = this._idSucursal;

      if (idSucursal !== undefined) {

        this.getAllTasks(idSucursal);
      }
    });

    this.getAllSucursalesByCompany(this._idSucursal);
    // this.getAllVehicles();

    /* Autocomplete Remitente */
    this.clients = [];
    this.filteredControlOptions$ = of(this.clients);
    this.filteredNgModelOptions$ = of(this.clients);

    this.inputFormControl = new FormControl();
    this.filteredControlOptions$ = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );

    /* Autocomplete Destinatario */
    this.clients2 = [];
    this.filteredControlOptions2$ = of(this.clients2);
    this.filteredNgModelOptions2$ = of(this.clients2);

    this.inputFormControl2 = new FormControl();
    this.filteredControlOptions2$ = this.inputFormControl2.valueChanges
      .pipe(
        startWith(''),
        map(_filterString => this._filter(_filterString)),
      );

    this.getAddress();
    this.getAddress2();
  }
  async getUserList(){
    
    // console.log(user12);
    // var user=user12.user;
    // let user1 = new Array();
    this.userService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.user1 = response.records[0];
          // console.log(this.user1);
          this.getUser();
          
        }
      },
      error => {
        console.log(error);
      }
    );
    
  }
  async getUser(){
    let user12 = {} as User;
    if (localStorage.getItem('user')) {
      user12 = JSON.parse(localStorage.getItem('user'));
    }
    var user=user12.user;
    this.user1.forEach(element => {
      if (element.user == user) {
        this.userDB = element;
        
        
      }

    });
    
    

  }
  private filter(value: string): string[] {
    if (value.length < 3) {
      return [];
    }
    const filterValue = value.toLowerCase();
    this._clientService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.clients = response.records[0];
          //console.log(this.options);
        }
      },
      error => {
        console.log();
      }
    );
    return this.clients.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));
  }

  private _filter(value: string): string[] {
    if (value.length < 3) {
      return [];
    }
    const filterValue = value.toLowerCase();
    this._clientService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.clients2 = response.records[0];
          //console.log(this.options);
        }
      },
      error => {
        console.log();
      }
    );
    return this.clients2.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));
  }

  evento(event) {
    //console.log(event);
    const selectedValue = this.clients.find(x => x.name === event);
    const _idAddressReceiver = selectedValue.id;
    this.person.id = _idAddressReceiver;
    this.task.idReceiver = _idAddressReceiver;
    this.getInfoClient(_idAddressReceiver);
    this.getAllAddressByClient(_idAddressReceiver);
    //console.log(this.task.idAddressReceiver);
  }

  evento2(event) {
    console.log(event);
    const selectedValue = this.clients2.find(x => x.name === event);
    const _idAddressPetitioner = selectedValue.id;
    this.person2.id = _idAddressPetitioner;
    this.task.idPetitioner = _idAddressPetitioner;
    this.getInfoClient2(_idAddressPetitioner);
    this.getAllAddressByClient2(_idAddressPetitioner);
    //console.log(this.person2.id);
  }

  sucursalEvento(event) {
    //console.log(event);

    this.getAllVehicles(event);
  }

  getAllSucursalesByCompany(idSucursal) {
    this._sucursalService.getAllByIdEmpresa(idSucursal).subscribe(
      response => {
        if (response.result == true) {
          this.sucursales = response.records[1];
          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllAddressByClient(_idAddressReceiver) {
    this._personAddressService.getAddressByPersonId(_idAddressReceiver).subscribe(
      response => {
        if (response.result == true) {
          this.personAddress = response.records[1];
          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllAddressByClient2(_idAddressReceiver) {
    this._personAddressService.getAddressByPersonId(_idAddressReceiver).subscribe(
      response => {
        if (response.result == true) {
          this.personAddress2 = response.records[1];
          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getInfoClient(_idPerson) {
    this._clientService.getAllByPersonId(_idPerson).subscribe(
      response => {
        if (response.result == true) {
          this.person = response.records[1][0];
        }
      },
      error => {
        console.log();
      }
    );
  }

  getInfoClient2(_idPerson) {
    this._clientService.getAllByPersonId(_idPerson).subscribe(
      response => {
        if (response.result == true) {
          this.person2 = response.records[1][0];
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllVehicles(idSucursal) {
    this._companyService.getCompanyVehicleRate(idSucursal).subscribe(
      response => {
        if (response.result == true) {
          this.companyVehicle = response.records[1];
          console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllTasks(idSucursal) {
    this._taskService.getAllBySucusal(idSucursal).subscribe(
      response => {
        if (response.result == true) {
          this.tasks = response.records[1];
          this.tasksList = new LocalDataSource(this.tasks);
          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  addClientAddress() {
    this.router.navigate(
      ['/addAddress/' + this.person.id]
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: false,
      delete: true,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    delete: {
      deleteButtonContent: "<i class='fa fa-minus-square-o' title='Eliminar'></i>"
    },
    columns: {
      codeTask: {
        title: 'Tarea #',
        editable: false
      },
      nameClient: {
        title: 'Destinatario',
        editable: false
      },
      totalPackages: {
        title: 'Paquetes',
        editable: false
      },
      dateShipping: {
        title: 'Fecha envío',
        editable: false
      },
      estadoTracking: {
        title: 'Estado',
        editable: false
      }
    }
  };

  verDireccion2(e) {
    this._personAddressService.getOneAddressByPersonId(e).subscribe(
      response => {
        if (response.result == true) {
          this._personAddress2 = response.records[1][0];
          console.log(this._personAddress2);
        }
      },
      error => {
        console.log();
      }
    );
  }

  verDireccion(e) {
    this._personAddressService.getOneAddressByPersonId(e).subscribe(
      response => {
        if (response.result == true) {
          this._personAddress = response.records[1][0];
          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  confirmSave(dialog: TemplateRef<any>){
    //console.log("Confirmar almacenado de tarea!");
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });

  }

  save() {

    // console.log(this.tiempo);
    let idSucursal = this.task.idSucursal;

    this._taskService.save(this.task).subscribe(
      response => {
        if (response.result == true) {

          window.location.reload();

          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.getUserAdminToken();
          this.clean();
          this.getAllTasks(idSucursal);
          this.getUserAdminToken();

        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )

  }

  clean() {
    this.task.idPetitioner = null;
    this.task.idReceiver = null;
    this.task.idAddressReceiver = null;
    this.task.idAddressPetitioner = null;
    this.task.totalPackages = null;
    this.task.amount = null;
    this.task.dateShipping = null;
    this.task.idVehicle = null;
    this.task.comentario = null;
    this.person.mail = null;
    this.person.phone = null;
    this.person2.mail = null;
    this.person2.phone = null;
    this._personAddress.address = null;
    this._personAddress2.address = null;
  }



  delete(task) {
   console.log(task);
    
    task.idStatus = 8;
    
    this._trackingStatusService.updateTaskTracking(task).subscribe(
      response => {
        if (response.result == true) {
          // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          // const status: NbComponentStatus = 'success';
          // this.toastrService.show(status || 'Success', "Paso Cerrado Exitosamente", { position, status });
          // //form.reset();
          // this.getUserAdminToken();
          // // this.goToTaskTracking();
         
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', "Tarea Eliminada exitosamente", { position, status });
          //form.reset();
            this.getUserAdminToken();
            
           this._router.navigate(
            ['/taskProgress']
          );
          
         
          
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  deleteTask(event) {
    //console.log(event);
    

    this.task.id = 1;
    this.task.comentario = event.data.codeTask;

    let idSucursal = this._idSucursal;
    this._taskService.save(this.task).subscribe(
      response => {
        if (response.result == true) {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.clean();
          this.getAllTasks(idSucursal);
          this.task = new Task(0, null, null, null, null, null, null, null,null, null, null, null, null, null, null, null, 1,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,null,null,null,null,null,null,null,null,null,null,0);

        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )

  }

  getAddress() {
    this._addressService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this._mAddress = response.records[0];
          //console.log(response);
        }
      }, error => {
        console.log();
      }
    )
  }

  onSubmit() {

    /* Obtenemos la longitud y latitud del marcador */
    const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);
    this.mPersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    this.mPersonAddress.latitude = lngLatArr[0]['centro'][1].toString();

    // console.log(this.mPerson);
    // console.log(this.mPersonAddress);

    this._clientService.save(this.mPerson).subscribe(
      response => {
        if (response.result == true) {
          this.mPersonAddress.idCustomer = response.records[0].idClient;

          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          //console.log(response.records[0].idClient);

          // this._personAddressService.save(this.mPersonAddress).subscribe(
          //   response => {
          //     if (response.result == true) {
          //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          //       const status: NbComponentStatus = 'success';
          //       this.toastrService.show(status || 'Success', response.message, { position, status });
          //       form.reset();
          //       this.getDivInfo();
          //       this.borrarMarcador(0);
          //     } else {
          //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          //       const status: NbComponentStatus = 'warning';
          //       this.toastrService.show(status || 'Warning', response.message, { position, status });
          //     }
          //   },
          //   error => {
          //     const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          //     const status: NbComponentStatus = 'danger';
          //     this.toastrService.show(status || 'Danger', <any>error, { position, status });
          //   }
          // )
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  open2(dialog2: TemplateRef<any>, idPersona_) {
    this.mDpersonAddress.idCustomer = idPersona_;
    this.dialogService.open(dialog2, { context: 'this is some additional data passed to dialog' });
  }

  getAddress2() {
    this._addressService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this._mDaddress = response.records[0];
          //console.log(response);
        }
      }, error => {
        console.log();
      }
    )
  }

  save2() {
    //console.log(this.personAddress);

    /* Obtenemos la longitud y latitud del marcador */
    const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);
    this.mDpersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    this.mDpersonAddress.latitude = lngLatArr[0]['centro'][1].toString();

    this.mDpersonAddress.idStatus = 1;
    console.log(this.mDpersonAddress);
    this._personAddressService.save(this.mDpersonAddress).subscribe(
      response => {
        if (response.result == true) {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.getDivInfo();
          this.borrarMarcador(0);
        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  /* Metodos para poder utilizar mapa */
  getDivCliente() {
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
  }

  getDivDireccion(idPersona_, opcion) {
    this.mDpersonAddress.idCustomer = idPersona_;

    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
  }

  addMarker() {
    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));

    if (this.marcadores.length < 1) {
      const nuevoMarcador = new mapboxgl.Marker({
        draggable: true,
        color
      })
        .setLngLat(this.center)
        .addTo(this.map);

      this.marcadores.push({
        color,
        marker: nuevoMarcador
      });

      this.guardarLocalStorage();

      nuevoMarcador.on('dragend', () => {
        this.guardarLocalStorage();
      });

    }
  }

  getMarker(marker: mapboxgl.Marker) {
    this.map.flyTo({
      center: marker.getLngLat()
    });
  }

  guardarLocalStorage() {

    const lngLatArr: MarcadorColor[] = [];

    this.marcadores.forEach(m => {

      const color = m.color;
      const { lng, lat } = m.marker!.getLngLat();

      lngLatArr.push({
        color: color,
        centro: [lng, lat]
      });

    })

    localStorage.setItem('marcadores', JSON.stringify(lngLatArr));

  }

  leerCoordenadas(sucursal) {

    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));
    const centro: [number, number] = [sucursal.longitude, sucursal.latitude];

    const newMarker = new mapboxgl.Marker({
      color: color,
      draggable: true
    })
      .setLngLat(centro)
      .addTo(this.map);

    this.marcadores.push({
      marker: newMarker,
      color: color
    });

    newMarker.on('dragend', () => {
      this.guardarLocalStorage();
    });

  }

  borrarMarcador(i: number) {

    this.marcadores[i].marker.remove();
    this.marcadores.splice(i, 1);
    this.guardarLocalStorage();
  }

  getDivInfo() {
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:none;');
  }

  /* Metodo para poder utilizar mensajes push */
  getUserAdminToken() {
    this._userService.getUserTokenAdminPush().subscribe(
      response => {
        if (response.result == true) {
          this.userToken = response.records[0];

          let title = 'Notificación mTracking';
          let message = "Se ingreso una nueva tarea";

          this.userToken.forEach(m => {
            // Operar los mensajes para el admin de tarea nueva
            const params = {
              "notification": {
                "title": title,
                "body": message
              },
              "to": m.tokenPush
            };

            this.messagingService.sendMessage(params).subscribe(
              response => {
                  console.log(response);
              },
              error => {
                console.log();
              }
            );

          });

          //console.log(this.userToken);
        }
      },
      error => {
        console.log();
      }
    );
  }

}
