import { Component, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { Sucursal } from '../../models/sucursal';
import { SucursalService } from '../../services/sucursal.service';
import {
  NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition,
  NbDialogService
} from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';

interface MarcadorColor {
  color: string;
  marker?: mapboxgl.Marker;
  centro?: [number, number];
}

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.scss'],
  providers: [CompanyService, SucursalService]
})

export class SucursalesComponent implements AfterViewInit, OnInit {

  public sucursal: Sucursal;
  public company: Company;
  public sucursales: Sucursal[];
  public sucursalesList = new LocalDataSource();

  public flgSucursal;
  public _latitude;
  public _longitude;

  @ViewChild('map', {static: false}) divMap!: ElementRef;
  map!: mapboxgl.Map;
  zoomLevel: number = 10;
  center: [number, number] = [-90.49322418747106, 14.580160123137151];

  // Arreglo de marcadores
  marcadores: MarcadorColor[] = [];


  constructor(
    private _sucursalService: SucursalService,
    private _companyService: CompanyService,
    private toastrService: NbToastrService,
    private _router: Router,
    private _route: ActivatedRoute,
    private dialogService: NbDialogService
  ) {
    this.company = new Company(0, '', '', '', 1);
    this.sucursal = new Sucursal(0, null, null, null, null, null, null, null, null, 1);
  }

  ngAfterViewInit() {

    this.map = new mapboxgl.Map({
      container: this.divMap.nativeElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: 15,
    });

  }

  ngOnInit(){

    this.flgSucursal = false;

    this._route.paramMap.subscribe(async (params: any) => {
      const idCompany = params.params.idCompany;
      const idSucursal = params.params.idSucursal;

      if (idCompany !== undefined) {

        this.sucursal.idCompany = idCompany;
        this.getAllSucursalesById(idCompany);
        this.getCompanyById(idCompany);
      }

      if (idSucursal !== undefined) {

        this.flgSucursal = true;
        this.sucursal.id = idSucursal;
        this.getSucursalById(idSucursal);

      }
    });

  }

  getAllSucursalesById(idCompany) {
    //console.log(idCompany);
    this._sucursalService.getAllByIdEmpresa2(idCompany).subscribe(
      response => {
        if (response.result == true) {
          //console.log(response);
          this.sucursales = response.records[1];
          this.sucursalesList = new LocalDataSource(this.sucursales);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getSucursalById(idSucursal) {
    //console.log(idCompany);
    this._sucursalService.getSucursalById(idSucursal).subscribe(
      response => {
        if (response.result == true) {
          console.log(response.records);
          this.sucursal = response.records[1][0];
          this.leerCoordenadas(this.sucursal);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getCompanyById(idCompany) {
    this._companyService.getById(idCompany).subscribe(
      response => {
        if (response.result == true) {
          console.log(response);
          this.company = response.records[1][0];
          console.log(this.company);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      sucursalName: {
        title: 'Nombre Sucursal',
        editable: false
      },
      contactName: {
        title: 'Nombre Contacto',
        editable: false
      },
      contactMail: {
        title: 'Correo electrónico',
        editable: false
      },
      contactPhone: {
        title: 'Teléfono',
        editable: false
      }
    }
  };

  onSubmit(form) {
    /* Asignación de id de compañía */
    let idCompany = this.sucursal.idCompany;

    /* Obtener la longitud y latitud del mapa para guardar */
    const lngLatArr : MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);
    this.sucursal.longitude = lngLatArr[0]['centro'][0].toString();
    this.sucursal.latitude = lngLatArr[0]['centro'][1].toString();

    //console.log(this.sucursal);
    this._sucursalService.save(this.sucursal).subscribe(
      response => {
        if (response.result == true) {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          form.reset();
          this.getAllSucursalesById(idCompany);
          this.getCompanyById(idCompany);
          this.goToSucursalEmpresa();
          this.borrarMarcador(0);
        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  editSucursal(event) {
    this._router.navigate(
      ['/sucursales-empresa/' + this.sucursal.idCompany + '/' + event.data.id]
    );
  }

  goToSucursalEmpresa() {
    this._router.navigate(
      ['/sucursales-empresa/' + this.sucursal.idCompany]
    );
  }

  /* Aquí esta el código para los mapas */

  addMarker() {

    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));

    if (this.marcadores.length < 1) {
      const nuevoMarcador = new mapboxgl.Marker({
        draggable: true,
        color
      })
        .setLngLat(this.center)
        .addTo(this.map);

      this.marcadores.push({
        color,
        marker: nuevoMarcador
      });

      this.guardarLocalStorage();

      nuevoMarcador.on('dragend', () => {
        this.guardarLocalStorage();
      });

    }

  }

  getMarker(marker: mapboxgl.Marker) {
    this.map.flyTo({
      center: marker.getLngLat()
    });
  }

  guardarLocalStorage() {

    const lngLatArr: MarcadorColor[] = [];

    this.marcadores.forEach(m => {

      const color = m.color;
      const { lng, lat } = m.marker!.getLngLat();

      lngLatArr.push({
        color: color,
        centro: [lng, lat]
      });

    })

    localStorage.setItem('marcadores', JSON.stringify(lngLatArr));

  }

  leerCoordenadas(sucursal) {

    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16)); 
    const centro: [number, number] = [sucursal.longitude, sucursal.latitude];

    const newMarker = new mapboxgl.Marker({
      color: color,
      draggable: true
    })
      .setLngLat(centro)
      .addTo(this.map);

    this.marcadores.push({
      marker: newMarker,
      color: color
    });

    newMarker.on('dragend', () => {
      this.guardarLocalStorage();
    });

  }

  borrarMarcador(i: number) {

    this.marcadores[i].marker.remove();
    this.marcadores.splice(i, 1);
    this.guardarLocalStorage();
  }

}
