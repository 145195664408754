import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Person } from '../models/person';
import { Global } from './global';

@Injectable()
export class ClientService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	save(person: Person): Observable<any>{
		let params = JSON.stringify(person);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-client',params,{ headers: headers });

	}

	getAll(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-client',{headers: headers});
	}

	getAllByPersonId(id): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-client/one/'+id,{headers: headers});
	}
}