import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TrackingStatus } from '../models/trackingStatus';
import { TaskTracking } from '../models/taskTracking';
import { Global } from './global';
import { environment } from 'src/environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
@Injectable()
export class TrackingStatusService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	getAll(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-status',{headers: headers});
	}

    getNext(idStatus): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-next-status',idStatus,{ headers: headers });

	}
	// uploadFileS3(file, contractUUID): Promise<any> {
	//  // this.companyProgress.next(0);
	// 	 const contentType = file.type;
	// 	 const bucket = new S3(   
	// 	   {
			 
	// 		 accessKeyId: 'AKIAV42YBFE62QE2WJP5',
	// 		 secretAccessKey: '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',
	// 		 region: environment.region
	// 	   }
	// 	 );
	// 	 const params = {
	// 	    Bucket: 'mtracking-dev-content',
	// 	  Key: 'mtracking' + '/email/image/' + contractUUID + '/' + file.name,
	// 	  Body: file,
	// 	  ACL: 'public-read',
	// 	  ContentType: contentType
	// 	 };
	// 	 console.log(params)
	// 	 return new Promise((resolve, reject) => {
	// 	   bucket.upload(params).on('httpUploadProgress', (evt) => {
	// 		 const percent = (evt.loaded / evt.total) * 100;
	// 		 console.log(evt)
	// 		 // this.companyProgress.next(percent);
	// 	   }).send((err, data) => {
	// 		 if (err) {
	// 		   return reject(err);
	// 		 }
	// 		 // data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
	// 		 return resolve(data);
	// 	   });
	// 	 });
	//   }


	  uploadFileS3(file, employeeCode): Promise<any> {
		// this.companyProgress.next(0);
		const contentType = file.type;
		const bucket = new S3(   
		  {
			
			accessKeyId: 'AKIAV42YBFE62QE2WJP5',
			secretAccessKey: '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',
			region: environment.region
		  }
		);
		const params = {
		  ACL: 'public-read',
		  Bucket: 'mtracking-dev-content',
		  Key: 'images/'+employeeCode+ '/' + file.name,
		  Body: file,
		  ContentType: contentType
		};
		return new Promise((resolve, reject) => {
		  bucket.upload(params).on('httpUploadProgress', (evt) => {
			const percent = (evt.loaded / evt.total) * 100;
			// this.companyProgress.next(percent);
		  }).send((err, data) => {
			if (err) {
			  return reject(err);
			}
			// data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
			return resolve(data);
		  });
		});
	  }
	updateTaskTracking(_taskTracking: TaskTracking): Observable<any>{
		let params = JSON.stringify(_taskTracking);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-task-tracking',params,{ headers: headers });
	}
}

