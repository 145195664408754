<div class="row" [style]="flgInfo">
    <div class="col-12">
        <nb-card accent="info">
            <nb-card-header>Ingresar tarea en tres sencillos pasos</nb-card-header>
            <nb-card-body>

                <nb-stepper #stepper>

                    <nb-step label="Envia">
                        <div class="row step-container">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="idPetitioner" class="label">Solicitante: </label>
                                    <nb-select fullWidth id="idSucursal" name="idSucursal" [(ngModel)]="task.idSucursal" placeholder="Seleccione..." (selectedChange)="sucursalEvento($event)" nbTooltipPlacement="top" nbTooltip="Sucursal solicitante" required="true">
                                        <nb-option [value]="sucursal.id" *ngFor="let sucursal of sucursales">
                                            {{sucursal.sucursalName}}</nb-option>
                                    </nb-select>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="idVehicle" class="label">Tipo de Envio: </label>
                                    <nb-select fullWidth name="idVehicle" [(ngModel)]="task.idVehicle" placeholder="Seleccione..." nbTooltipPlacement="top" nbTooltip="Vehículo">
                                        <nb-option [value]="vehicle.idVehicle" *ngFor="let vehicle of companyVehicle">
                                            {{vehicle.nameVehicle}}</nb-option>
                                    </nb-select>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="dateShipping" class="label">Fecha envío:</label>
                                    <input [nbDatepicker]="datepicker" type="text" nbInput fullWidth required="true" name="dateShipping" [(ngModel)]="task.dateShipping" nbTooltip="Fecha de envío" nbTooltipPlacement="top">
                                    <nb-datepicker #datepicker format="yyyy/MM/dd"></nb-datepicker>
                                </div>
                            </div>
                            <div class="col-sm-3">
                              <div class="form-group">
                                  <label for="timeShipping" class="label">Hora de envío:</label>
                                  <input nbInput fullWidth required="true" name="timeShipping" [ngxTimepicker]="picker" [(ngModel)]="task.hourShipping" nbTooltip="Hora de envío" nbTooltipPlacement="top">
                                  <ngx-material-timepicker #picker ></ngx-material-timepicker>
                              </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="idAddressPetitioner" class="label">Destinatario: </label>
                                    <input [formControl]="inputFormControl2" nbInput type="text" fullWidth placeholder="Seleccione..." [nbAutocomplete]="autoControl2" nbTooltipPlacement="top" nbTooltip="Remitente de tarea" [(ngModel)]="userDB.name" readonly/>
                                    <nb-autocomplete #autoControl2 (selectedChange)="evento2($event)">
                                        <nb-option *ngFor="let _client of filteredControlOptions2$ | async" [value]="_client.name">
                                            {{ _client.name }}
                                        </nb-option>
                                    </nb-autocomplete>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="form-group">
                                    <label for="button" class="label label-button">boton</label>
                                    <!-- <a (click)="open(dialog)" nbButton ghost status="basic"> -->
                                    <a (click)="getDivCliente()" nbButton ghost status="basic">
                                        <nb-icon icon="plus-outline"></nb-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="totalPackages" class="label">Correo:</label>
                                    <input type="text" nbInput fullWidth name="correoRemitente" [(ngModel)]="person2.mail" nbTooltip="Correo de remitente" nbTooltipPlacement="top" readonly />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="totalPackages" class="label">Teléfono:</label>
                                    <input type="text" nbInput fullWidth name="telefonoRemitente" [(ngModel)]="person2.phone" nbTooltip="Teléfono de remitente" nbTooltipPlacement="top" readonly />
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="idAddressPetitioner" class="label">Tipo de Dirección: </label>
                                    <nb-select fullWidth id="idAddressPetitioner" name="idAddressPetitioner" placeholder="Seleccione..." [(ngModel)]="task.idAddressPetitioner" nbTooltipPlacement="top" nbTooltip="Dirección remitente">
                                        <nb-option [value]="p2Address.value" *ngFor="let p2Address of addressType">
                                            {{p2Address.name}}</nb-option>
                                    </nb-select>
                                    <span style="color:red;" *ngIf="task.idAddressPetitioner === 2"> La empresa no se hace cargo de costos por parqueo</span>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="form-group">
                                    <label for="button" class="label label-button">boton</label>
                                    <!-- <button nbButton ghost status="basic" (click)="open2(dialog2, person2.id)"> -->
                                    <button nbButton ghost status="basic" (click)="getDivDireccion(person2.id, 2)">
                                        <nb-icon icon="plus-outline"></nb-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="form-group">
                                    <label for="totalPackages" class="label">Dirección:</label>
                                    <input type="text" nbInput fullWidth name="correoRemitente" [(ngModel)]="_personAddress2.address" nbTooltip="Dirección de remitente" nbTooltipPlacement="top" readonly />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <button nbButton ghost nbStepperNext>Siguiente</button>
                                </div>
                            </div>
                        </div>
                    </nb-step>

                    <nb-step label="Paquetes">
                        <div class="row step-container">
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="totalPackages" class="label">Paquetes:</label>
                                    <input type="number" nbInput fullWidth name="totalPackages" [(ngModel)]="task.totalPackages" nbTooltip="Cantidad de paquetes" nbTooltipPlacement="top" required="true" />
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label for="amount" class="label">Monto a Cobrar:</label>
                                    <input type="text" nbInput fullWidth name="amount" [(ngModel)]="task.amount" nbTooltip="¿Desea cobrar algo por su paquete?" nbTooltipPlacement="top" required="true" />
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="form-group">
                                    <label for="amount" class="label">Observaciones:</label>
                                    <input type="text" nbInput fullWidth name="comentario" [(ngModel)]="task.comentario" nbTooltip="Observaciones" nbTooltipPlacement="Observaciones" />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <button nbButton ghost nbStepperNext>Siguiente</button>
                                </div>
                            </div>
                        </div>
                    </nb-step>

                    <nb-step label="Recibe">
                        <div class="row step-container">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="idReceiver" class="label">Destinatario: </label>
                                    <input [formControl]="inputFormControl" nbInput type="text" fullWidth placeholder="Seleccione..." [nbAutocomplete]="autoControl" nbTooltipPlacement="top" nbTooltip="Destinatario de tarea" />
                                    <nb-autocomplete #autoControl (selectedChange)="evento($event)">
                                        <nb-option *ngFor="let client of filteredControlOptions$ | async" [value]="client.name">
                                            {{ client.name }}
                                        </nb-option>
                                    </nb-autocomplete>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="form-group">
                                    <label for="button" class="label label-button">boton</label>
                                    <!-- <a (click)="open(dialog)" nbButton ghost status="basic"> -->
                                    <a (click)="getDivCliente()" nbButton ghost status="basic">
                                        <nb-icon icon="plus-outline"></nb-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="totalPackages" class="label">Correo:</label>
                                    <input type="text" nbInput fullWidth name="totalPackages" [(ngModel)]="person.mail" nbTooltip="Correo de destinatario" nbTooltipPlacement="top" readonly value="{{person.mail}}" />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label for="totalPackages" class="label">Teléfono:</label>
                                    <input type="text" nbInput fullWidth name="totalPackages" [(ngModel)]="person.phone" nbTooltip="Teléfono de destinatario" nbTooltipPlacement="top" readonly />
                                </div>
                            </div>
                            <!-- <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="idAddressReceiver" class="label">Tipo de Dirección: </label>
                                    <nb-select fullWidth id="idAddressReceiver" name="idAddressReceiver" placeholder="Seleccione..." [(ngModel)]="task.idAddressReceiver" (selectedChange)="verDireccion($event)" nbTooltipPlacement="top" nbTooltip="Dirección de destinatario">
                                        <nb-option [value]="pAddress.id" *ngFor="let pAddress of personAddress">
                                            {{pAddress.name}}</nb-option>
                                    </nb-select>
                                </div>
                            </div> -->
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label for="idAddressPetitioner" class="label">Tipo de Dirección: </label>
                                    <nb-select fullWidth id="idAddressPetitioner" name="idAddressPetitioner" placeholder="Seleccione..." [(ngModel)]="task.idAddressReceiver" nbTooltipPlacement="top" nbTooltip="Dirección remitente">
                                        <nb-option [value]="p2Address.value" *ngFor="let p2Address of addressType">
                                            {{p2Address.name}}</nb-option>
                                    </nb-select>
                                    <span style="color:red;" *ngIf="task.idAddressPetitioner === 2"> La empresa no se hace cargo de costos por parqueo</span>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <div class="form-group">
                                    <label for="button" class="label label-button">boton</label>
                                    <!-- <button nbButton ghost status="basic" (click)="open2(dialog2, person.id)"> -->
                                    <button nbButton ghost status="basic" (click)="getDivDireccion(person.id, 1)">
                                        <nb-icon icon="plus-outline"></nb-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="form-group">
                                    <label for="totalPackages" class="label">Dirección:</label>
                                    <input type="text" nbInput fullWidth name="correoRemitente" [(ngModel)]="_personAddress.address" nbTooltip="Dirección de remitente" nbTooltipPlacement="top" readonly />
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <button nbButton ghost status="primary" (click)="confirmSave(dialog)">
                                        Verificar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nb-step>

                    <nb-step [hidden]="true">
                        <div class="step-container">
                            <h3>Ingresar nueva tarea?</h3>
                            <br />
                            <button nbButton ghost status="info" (click)="stepper.reset()">Si!</button>
                        </div>
                    </nb-step>

                </nb-stepper>

            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <div class="col-6" [style]="flgCliente">
        <nb-card accent="info">
            <nb-card-header>Ingresar información</nb-card-header>
            <nb-card-body>
                <form #companyForm="ngForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="nit" class="label">NIT</label>
                                <input type="text" nbInput fullWidth name="nit" [(ngModel)]="mPerson.nit" required="true" autocomplete="false" nbTooltip="NIT" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="name" class="label">Nombre</label>
                                <input type="text" nbInput fullWidth name="name" [(ngModel)]="mPerson.name" required="true" nbTooltip="Nombre de cliente" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="phone" class="label">Teléfono</label>
                                <input type="text" nbInput fullWidth name="phone" [(ngModel)]="mPerson.phone" required="true" maxlength="8" autocomplete="false" nbTooltip="Número de teléfono" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div class="form-group">
                                <label for="mail" class="label">Correo Electrónico</label>
                                <input type="email" nbInput fullWidth name="mail" [(ngModel)]="mPerson.mail" required="true" nbTooltip="Correo electrónico de cliente" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <!-- <div class="col-sm-5">
                            <div class="form-group">
                                <label for="idCatAddress" class="label">Tipo Dirección: </label>
                                <nb-select fullWidth id="idCatAddress" name="idCatAddress" [(ngModel)]="mPersonAddress.idCatAddress" placeholder="Tipo Dirección">
                                    <nb-option [value]="address.id" *ngFor="let address of _mAddress">
                                        {{address.name}}</nb-option>
                                </nb-select>
                            </div>
                        </div> -->
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label for="idAddressPetitioner" class="label">Tipo de Dirección: </label>
                                <nb-select fullWidth id="idCatAddress" name="idCatAddress" placeholder="Seleccione..." [(ngModel)]="mPersonAddress.idCatAddress" nbTooltipPlacement="top" nbTooltip="Dirección remitente">
                                    <nb-option [value]="p2Address.value" *ngFor="let p2Address of addressType">
                                        {{p2Address.name}}</nb-option>
                                </nb-select>
                                <span style="color:red;" *ngIf="task.idAddressPetitioner === 2"> La empresa no se hace cargo de costos por parqueo</span>
                            </div>
                        </div>
                        <div class="col-sm-10">
                            <div class="form-group">
                                <label for="address" class="label">Dirección</label>
                                <input type="text" nbInput fullWidth name="address" [(ngModel)]="mPersonAddress.address" required="true" nbTooltip="Dirección de cliente" nbTooltipPlacement="top" />
                            </div>
                        </div> -->
                        <div class="col-sm-3">
                            <div class="form-group">
                                <button nbButton ghost status="success">
                                    <nb-icon icon="save-outline"></nb-icon>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </nb-card-body>
            <nb-card-footer class="text-right">
                <button nbButton ghost status="secondary" (click)="getDivInfo()">Cerrar</button>
            </nb-card-footer>
        </nb-card>
    </div>
    <div class="col-6" [style]="flgDireccion">
        <nb-card accent="info">
            <nb-card-header>Ingresar dirección</nb-card-header>
            <nb-card-body>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="address" class="label">Dirección</label>
                            <input type="text" nbInput fullWidth name="address" [(ngModel)]="mDpersonAddress.address" required="true" nbTooltip="Dirección de cliente" nbTooltipPlacement="top" />
                        </div>
                    </div>
                    <!-- <div class="col-sm-12">
                        <div class="form-group">
                            <label for="idCatAddress" class="label">Tipo Dirección: </label>
                            <nb-select fullWidth id="idCatAddress" name="idCatAddress" [(ngModel)]="mDpersonAddress.idCatAddress" placeholder="Tipo Dirección">
                                <nb-option [value]="address.id" *ngFor="let address of _mDaddress">
                                    {{address.name}}</nb-option>
                            </nb-select>
                        </div>
                    </div> -->
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="idAddressPetitioner" class="label">Tipo de Dirección: </label>
                            <nb-select fullWidth id="idCatAddress" name="idCatAddress" placeholder="Seleccione..." [(ngModel)]="mDpersonAddress.idCatAddress" nbTooltipPlacement="top" nbTooltip="Dirección remitente">
                                <nb-option [value]="p2Address.value" *ngFor="let p2Address of addressType">
                                    {{p2Address.name}}</nb-option>
                            </nb-select>
                            <span style="color:red;" *ngIf="task.idAddressPetitioner === 2"> La empresa no se hace cargo de costos por parqueo</span>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label for="button" class="label label-button">boton</label>
                            <button nbButton ghost status="success" (click)="save2()">
                                <nb-icon icon="save-outline"></nb-icon>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </nb-card-body>
            <nb-card-footer class="text-right">
                <button nbButton ghost status="secondary" (click)="getDivInfo()">Cerrar</button>
            </nb-card-footer>
        </nb-card>
    </div>
    <div class="col-6" [style]="flgMapa">
        <div #map class="map-container">
            <ul class="list-group">
                <li class="list-group-item list-group-item-secondary" (click)="addMarker()">
                    + Agregar
                </li>

                <li class="list-group-item" (click)="getMarker(marcador.marker)" (dblclick)="borrarMarcador(i)" *ngFor="let marcador of marcadores; let i = index" [ngStyle]="{
                    'background-color': marcador.color
                }">
                    Marcador
                </li>

            </ul>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-12">
        <nb-card accent="info">
            <nb-card-header>Listado de tareas</nb-card-header>
            <nb-card-body>
                <ng2-smart-table (delete)="delete($event)" [settings]="settings" [source]="tasksList">
                </ng2-smart-table>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="col-8 mx-auto" accent="info">
        <nb-card-header>Información de tarea</nb-card-header>
        <nb-card-body>
            <nb-card-body>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Paquetes: </label><br />
                            <span>{{task.totalPackages}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Monto de tarea: </label><br />
                            <span>{{task.amount | currency: 'Q'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="task" class="label">Remitente: </label><br />
                            <span>{{_personAddress2.clientName}}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="task" class="label">Dirección recolección:</label><br />
                            <span>{{_personAddress2.address}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="task" class="label">Destinatario: </label><br />
                            <span>{{_personAddress.clientName}}</span><br />
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="task" class="label">Dirección entrega:</label><br />
                            <span>{{_personAddress.address}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Fecha recolección:</label><br />
                            <span>{{task.dateShipping | date: 'dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Observación entrega:</label><br />
                            <span>{{task.comentario}}</span>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card-body>
        <nb-card-footer class="text-right">
            <button nbButton ghost status="primary" (click)="save() + ref.close()">Aceptar</button>
            <button nbButton ghost status="secondary" (click)="ref.close()">Cerrar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>
