import { Component, OnInit } from '@angular/core';
import { Commission } from '../../models/commission';
import { CommissionService } from '../../services/commission.service';
import {
	NbToastrService, NbGlobalPosition,
	NbComponentStatus, NbGlobalPhysicalPosition, NbDialogService
} from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
	selector: 'app-commission',
	templateUrl: './commission.component.html',
	styleUrls: ['./commission.component.scss'],
	providers: [CommissionService,VehicleService]
})
export class CommissionComponent implements OnInit {

	public _commission: Commission;
	public commissions: Commission[];
	public list = new LocalDataSource();
	vehicles = new Array();

	constructor(
		private _commissionService: CommissionService,
		private toastrService: NbToastrService,
		private router: Router,
		private dialogService: NbDialogService,
		private _vehicleService: VehicleService
	) {
		this._commission = new Commission(0, '', 1, 0, 0);
	}

	ngOnInit() {
		this.getAllCommissions();
		this.getAllVehicles() ;
	}

	getAllCommissions() {
		this._commissionService.getAll().subscribe(
			response => {
				if (response.result == true) {
					this.commissions = response.records[0];
					this.list = new LocalDataSource(this.commissions);
				}
			},
			error => {
				console.log();
			}
		);
	}

	settings = {
		hideSubHeader: false,
		mode: "external",
		actions: {
			edit: true,
			delete: true,
			add: false,
			columnTitle: "",
			position: "left",
			class: "action-colum"
		},
		edit: {
			editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
		},
		delete: {
			deleteButtonContent: "<i class='fa fa-trash-o' title='Eliminar'></i>"
		},
		columns: {
			id: {
				title: '#',
				editable: false
			},
			commission: {
				title: 'Comisión',
				editable: false
			},
			name: {
				title: 'Vehículo',
				editable: false
			}
		}
	};

	onSubmit(form) {
		// //    	console.log(this._commission);

		if (this._commission.id == undefined || this._commission.id == null) {
			this._commission.id = 0;
		}
		// if (this._commission.id > 0) {
		// 	this.dialogService.open(ConfirmWindowComponent,
		// 		{
		// 			context: {
		// 				message: '¿Está seguro de actualizar la comisión?'
		// 			},
		// 			hasBackdrop: false,
		// 			closeOnEsc: false,
		// 			closeOnBackdropClick: false
		// 		}).onClose.subscribe(result => {
		// 			if (result === true) {
		// 				this._commissionService.save(this._commission).subscribe(
		// 					response => {
		// 						if (response.result == true) {
		// 							const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 							const status: NbComponentStatus = 'success';
		// 							this.toastrService.show(status || 'Success', response.message, { position, status });
		// 							form.reset();
		// 							this.getAllCommissions();
		// 						} else {
		// 							const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 							const status: NbComponentStatus = 'warning';
		// 							this.toastrService.show(status || 'Warning', response.message, { position, status });
		// 						}
		// 					},
		// 					error => {
		// 						const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 						const status: NbComponentStatus = 'danger';
		// 						this.toastrService.show(status || 'Danger', <any>error, { position, status });
		// 					}
		// 				)
		// 			}
		// 		});
		// } else {
		// 	this._commissionService.save(this._commission).subscribe(
		// 		response => {
		// 			if (response.result == true) {
		// 				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 				const status: NbComponentStatus = 'success';
		// 				this.toastrService.show(status || 'Success', response.message, { position, status });
		// 				form.reset();
		// 				this.getAllCommissions();
		// 			} else {
		// 				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 				const status: NbComponentStatus = 'warning';
		// 				this.toastrService.show(status || 'Warning', response.message, { position, status });
		// 			}
		// 		},
		// 		error => {
		// 			const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 			const status: NbComponentStatus = 'danger';
		// 			this.toastrService.show(status || 'Danger', <any>error, { position, status });
		// 		}
		// 	)
		// }

		this._commissionService.save(this._commission).subscribe(
			response => {
				if (response.result == true) {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'success';
					this.toastrService.show(status || 'Success', response.message, { position, status });
					form.reset();
					this.getAllCommissions();
				} else {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'warning';
					this.toastrService.show(status || 'Warning', response.message, { position, status });
				}
			},
			error => {
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				this.toastrService.show(status || 'Danger', <any>error, { position, status });
			}
		)

	}




	deleteCommission(event) {

		// this.dialogService.open(ConfirmWindowComponent,
		// 	{
		// 		context: {
		// 			message: '¿Está seguro de Eliminar la comisión?'
		// 		},
		// 		hasBackdrop: false,
		// 		closeOnEsc: false,
		// 		closeOnBackdropClick: false
		// 	}).onClose.subscribe(result => {
		// 		if (result === true) {
		// 			event.data.idDelete = 1;
		// 			//    	console.log(this._commission);
		// 			this._commissionService.save(event.data).subscribe(
		// 				response => {
		// 					if (response.result == true) {
		// 						const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 						const status: NbComponentStatus = 'success';
		// 						this.toastrService.show(status || 'Success', response.message, { position, status });
		// 						setTimeout(() => {
		// 							window.location.reload();
		// 						}, 500);
		// 					} else {
		// 						const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 						const status: NbComponentStatus = 'warning';
		// 						this.toastrService.show(status || 'Warning', response.message, { position, status });
		// 					}
		// 				},
		// 				error => {
		// 					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
		// 					const status: NbComponentStatus = 'danger';
		// 					this.toastrService.show(status || 'Danger', <any>error, { position, status });
		// 				}
		// 			)
		// 		}
		// 	});

		event.data.idDelete = 1;
		//    	console.log(this._commission);
		this._commissionService.save(event.data).subscribe(
			response => {
				if (response.result == true) {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'success';
					this.toastrService.show(status || 'Success', response.message, { position, status });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				} else {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'warning';
					this.toastrService.show(status || 'Warning', response.message, { position, status });
				}
			},
			error => {
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				this.toastrService.show(status || 'Danger', <any>error, { position, status });
			}
		)

	}

	editCommission(event) {
		console.log(event.data);
		if (event.data.idDelete == undefined || event.data.idDelete == null) {
			event.data.idDelete = 0;
		}
		this._commission = event.data;
	}


	getAllVehicles() {
		this._vehicleService.getAll().subscribe(
			response => {
				if (response.result == true) {
					this.vehicles = response.records[0];
				}
			},
			error => {
				console.log();
			}
		);
	}


}
