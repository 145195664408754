import { Component, OnInit } from '@angular/core';
import { Task } from '../../models/task';
import { User } from '../../models/user';
import { UserToken } from '../../models/userToken';
import { TaskService } from '../../services/task.service';
import { UserService } from '../../services/user.service';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from '../../services/messaging.service';

@Component({
  selector: 'app-task-carrier',
  templateUrl: './task-carrier.component.html',
  styleUrls: ['./task-carrier.component.scss'],
  providers: [TaskService, UserService]
})
export class TaskCarrierComponent implements OnInit {

  public task = {} as Task;
  public carriers: User[];
  public userToken: UserToken[];
  public _idOperator: string;

  constructor(
    private toastrService: NbToastrService,
    private _route: ActivatedRoute,
    private _taskService: TaskService,
    private _userService: UserService,
    private _router: Router,
    private messagingService: MessagingService
  ) {

    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    this._idOperator = user.idCognito;

    //console.log(user.idCognito);

  }

  ngOnInit() {
    this._route.paramMap.subscribe(async (params: any) => {
      const idTask = params.params.idTask;

      if (idTask !== undefined) {
        this.task.id = params.params.idTask;
        this.getOneTask(idTask);
      }
    });
    this.getAllCarrier();
  }

  getOneTask(idTask) {
    this._taskService.getOneTaskToAsign(idTask).subscribe(
      response => {
        if (response.result == true) {
          this.task = response.records[1][0];
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllCarrier() {
    this._userService.getCarrier().subscribe(
      response => {
        if (response.result == true) {
          this.carriers = response.records[0];
          // console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  onSubmit(form) {
    this.task.idOperator = this._idOperator;
    this._taskService.addCarrier(this.task).subscribe(
      response => {
        if(response.result == true){
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.getUserTokenPush();
          form.reset();
          this.goToAssigment();
        }else{
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  goToAssigment() {
    this._router.navigate(
      ['/taskRequest']
    );
  }

  /* Metodos para poder utilizar mensajes push */
  getUserTokenPush() {

    this._userService.getUserTokenPush(this.task.idCarrier).subscribe(
      response => {
        if (response.result == true) {
          this.userToken = response.records[1];

          let title = 'Notificación mTracking';
          let message = "Se asigno la tarea: " + this.task.codeTask;

          this.userToken.forEach(m => {
            // Operar los mensajes para el admin de tarea nueva
            const params = {
              "notification": {
                "title": title,
                "body": message
              },
              "to": m.tokenPush
            };

            this.messagingService.sendMessage(params).subscribe(
              response => {
                console.log(response);
              },
              error => {
                console.log();
              }
            );
          });

        }
      },
      error => {
        console.error(error);
      }
    );
  }

}
