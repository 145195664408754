import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Vehicle } from '../models/vehicle';
import { Global } from './global';

@Injectable()
export class VehicleService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	save(vehicle: Vehicle): Observable<any>{
		let params = JSON.stringify(vehicle);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-vehicle', params, { headers: headers });

	}

	getAll(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-vehicle',{headers: headers});
	}

}