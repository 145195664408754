<nb-card style="width: 75vw; height: 80vh;">
  <nb-card-body>
      <p class="font-weight-bold">
        1. Para solicitar el servicio, recomendamos realizarlo con un mínimo de 24 horas de anticipación,
        así poder verificar cupo y reservar su espacio en ruta. Pueden solicitar el servicio el mismo día
        que se llevara a cabo, pero por alta demanda no garantizamos horas de recolección / entrega,
        y si en determinado caso no exista disponibilidad, Rapi2go puede indicar no realizar el envío.
      </p>
      <p class="font-weight-bold">
        2. El Solicitante del envío debe de proporcionar toda la información solicitada por Rapi2go para
        realizar el servicio, de faltar algún dato, no se procederá a la reservación de espacio ni
        realización del mismo.
      </p>
      <p class="font-weight-bold">
        3. Todos nuestros pilotos tienen indicaciones de no ingresar a parqueos, si el cliente por motivos
        de fuerza mayor, solicita y autoriza hacerlo, debe comunicarlo previamente a administración y
        asumir el costo del mismo.
      </p>
      <p class="font-weight-bold">
        4. Por ningún motivo el remitente ni el destinatario del servicio deben de brindar información
        relevante a los pilotos sin haberla comunicado al área administrativa previamente. Esto para
        evitar cualquier confusión o mal entendido que se pueda generar.
      </p>
      <span class="font-weight-bold">
        5. Rapi2go no se hace responsable de ninguna perdida, daño o entrega errónea causada por:
        <p style="padding-left: 2.5vw;">
          a. Acto, falta u omisión del remitente o destinatario. (Ej. Información incompleta,
           equivocada).
        </p>
        <p style="padding-left: 2.5vw;">
          b. Caso fortuito o fuerza mayor.
        </p>
        <p style="padding-left: 2.5vw;">
          c. Daños ocasionados en productos que no cumplen las condiciones adecuadas de
          empaque.
        </p>
      </span>
      <p class="font-weight-bold">
        6. Cualquier reclamo sobre productos entregados incompletos, dañados o que presenten
        alguna alteración, debe ser informado previo a que el piloto se retire del lugar de entrega.
        Esto es para poder darle un seguimiento claro al caso y evaluar con el piloto a cargo lo
        sucedido. Cualquier reclamo realizado posterior a la entrega, debe ser enviado por escrito a
        rapi2go vía correo electrónico en un tiempo no mayor a 72 horas contadas a partir de la
        fecha de realización del envío, y se procederá a evaluar el caso.
      </p>
      <p class="font-weight-bold">
        7. El tiempo máximo de espera en punto de recolección o entrega es de 15 minutos.
        Transcurrido dicho tiempo, se puede esperar un máximo de 30 minutos adicionales por un
        cargo de Q10.00 a la tarifa establecida previa al atraso.
      </p>
      <p class="font-weight-bold">
        8. La entrega de los productos se hará únicamente en la dirección estipulada por el destinatario.
        Cualquier cambio realizado a última hora (entiéndase 1 hora o menos de lo programado de su
        envío) en el horario, direcciones o información relevante del servicio, se solicitará previa
        autorización por el área de logística para evaluar si se puede realizar el envío con los cambios
        indicados, si estos impiden realizar el envío, Rapi2go puede decidir cancelar el servicio por
        alterar la programación establecida.
      </p>
      <span class="font-weight-bold">
        9. Rapi2go no acepta por ningún motivo envíos cuyos contenidos sean:
        <p style="padding-left: 2.5vw;"> a. Materiales peligrosos, combustibles y explosivos.</p>
        <p style="padding-left: 2.5vw;"> b. Animales.</p>
        <p style="padding-left: 2.5vw;"> c. Productos o artículos restringidos por la ley.</p>
      </span>
      <p class="font-weight-bold">
        10. Rapi2go asume que el remitente está enviando la mercadería declarada. Si existiera
        alguna duda sobre el contenido, Rapi2go se reserva el derecho a no transportarlo o verificarlo
        previo a su transportación.
      </p>
      <p class="font-weight-bold">
        11. El remitente responderá por los vicios ocultos no declarados y los gastos que se
        ocasionen a consecuencia de los mismos, tal y como lo estipulan las leyes civiles y
        mercantiles del país.
      </p>
      <span class="font-weight-bold">
        12. Los pagos de dicho servicio deben ser acordados previamente de realizar el envío, según
        nuestras diversas opciones:
        <p style="padding-left: 2.5vw;"> a. Pago en efectivo al momento de la recolección.</p>
        <p style="padding-left: 2.5vw;"> b. Pago en efectivo al momento de la entrega.</p>
        <p style="padding-left: 2.5vw;"> c. Transferencia a cuenta bancaria (realizar dicho trámite antes de que el envío sea
            concluido y enviar comprobante).</p>
        <p style="padding-left: 2.5vw;"> d. Pago mensual. (Para tener acceso a esta opción, solicitar formulario por uno de nuestros
            asesores).</p>
      </span>1
      <button nbButton style="float: right; margin-bottom: 2rem;" (click)="close()" status="success">Aceptar</button>
  </nb-card-body>
</nb-card>
