// import { AuthResenValidComponent } from './../auth-resen-valid/auth-resen-valid.component';
// import { UserResetPasswordComponent } from './../user-reset-password/user-reset-password.component';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
// import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';

@Component({
  selector: 'app-signIn',
  templateUrl: './signIn.component.html',
  styleUrls: ['./signIn.component.scss']
})
export class signInComponent implements OnInit {
  hide = true;
  loading = false;
  urlLog = 'assets/images/mLogo.png';
  urlAlt;
  user = {} as User;
  public users: User[];
  resetPassword = false;
  resendVCode = false;
  gmailLogin = false;
  azureLogin = false;
  constructor(
    private _userService: UserService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private router: Router
  ) { }

  ngOnInit() {
    const companyLogo = window.location.hostname;
    this.urlAlt = companyLogo.split('.')[0];
  }

  async saveUser() {
    this.loading = true;
    this.user.id = 0;
    this.user.password = this.user.password;
    this.user.idRol = 2;
    this.user.idSucursal = 2;
    this.user.acceptPolicy = 0;
    this.user.idStatus = 1;
    this.user.userAvatar = 0;
    console.log(this.user);

    const UserSub: any = await this._userService.insertUserCognito(this.user);

    this.user.idCognito = UserSub.UserSub;

    this._userService.save1(this.user).then(
      response => {
        console.log(response);
        if (response.result == true) {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message + ', Se le ha enviado correo de verificación', { position, status });

          setTimeout(() => {
            this._userService.logout();
            window.location.reload();
          }, 2000);

        } else {
          this.loading = false;
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.errorMessage, { position, status });
          console.log(response.errorMessage);
        }
      },
      error => {
        this.loading = false;
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  regresar() {
    this.router.navigate(['/login']);
  }

  // async signInWithGoogle() {
  //   this.loading = true;
  //   await this.authService.socialSignIn(AuthService.GOOGLE).then(
  //     (auth) => {
  //       this.loading = false;
  //     }
  //   ).catch(
  //     (err) => {
  //       this.loading = false;
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  // async signInWithAzureAD() {
  //   window.location.assign(
  //     'https://' +
  //     environment.cognitoDomain +
  //     '/oauth2/authorize?' +
  //     'identity_provider=office365&redirect_uri=' +
  //     environment.cognitoRedirectSignIn +
  //     '&response_type=code&client_id=' +
  //     environment.clientId +
  //     '&scope=aws.cognito.signin.user.admin+email+openid+phone+profile'
  //   );
  // }

  // onClickValidateUser() {
  //   this.dialogService.open(UserConfirmationComponent,
  //     {
  //       hasBackdrop: true,
  //       closeOnEsc: false,
  //       closeOnBackdropClick: false
  //     });
  // }

  // onResendValid() {
  //   this.dialogService.open(AuthResenValidComponent,
  //     {
  //       hasBackdrop: true,
  //       closeOnEsc: false,
  //       closeOnBackdropClick: false
  //     });
  // }

  // onClickResetPassword() {
  //   this.dialogService.open(UserResetPasswordComponent,
  //     {
  //       hasBackdrop: true,
  //       closeOnEsc: true,
  //       closeOnBackdropClick: false
  //     });
  // }

}
