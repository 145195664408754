import { Component, OnInit, Inject } from '@angular/core';
import {
  NbSidebarService,
  NbThemeService, NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition, NbMenuService, NB_WINDOW,
} from '@nebular/theme';
import { LayoutService } from '../utils/layout.service';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { User } from '../../models/user';
import { LocationService } from '../../services/location.service';
import { MessagingService } from '../../services/messaging.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers:[LocationService]
})
export class HeaderComponent implements OnInit {
  userAvatar: any;
  userEmail;
  userPicture: any;
  loading: boolean;
  urlAlt = 'Myappsoftware';
  urlLog = 'assets/images/mLogoHeader.png';

  public longitude;
  public latitude;

  public title;
  public message;
  public user1=new Array();
  public user = {} as User;
  public userDB = {} as User;
  public _cognitoId;
  public _name;

  userMenu = [
    { title: 'Mi Perfil', icon: 'person-outline', },
    { title: 'Salir', icon: 'log-out-outline' }
    
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private router: Router,
    private nbMenuService: NbMenuService,
    private userService: UserService,
    private locationService: LocationService,
    private messagingService: MessagingService,
    @Inject(NB_WINDOW) private window
  ) { }

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  async ngOnInit() {
    await this.startValidation();
    await this.getUserList();
   
    // await this.setBackground();
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'userMenu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        switch (title) {
          case 'Salir':
            this.logOut();
            break;
          case 'Mi Perfil':
            this.navigateUserProfile();
            break;
          default:
            break;
        }
      });
      await this.getLocation();
      // let params = {
      //   userName: "milton.rodriguez@myappsoftware.com",
      //   userPassword: "password",
      //   userEmail: "milton.rodriguez@myappsoftware.com",
      //   companyURL: "environment.companyURL",
      // }
      // console.log("parametros email: " + params);
      // console.log(params);
      // this.userService.sendEmail(params);
      
  }
  async getUser(){
    let user12 = {} as User;
    if (localStorage.getItem('user')) {
      user12 = JSON.parse(localStorage.getItem('user'));
    }
    var user=user12.user;
    this.user1.forEach(element => {
      if (element.user == user) {
        this.userDB = element;
        this.userDB.userAvatar
        this.userPicture = "assets/images/avatar/"+this.userDB.userAvatar+".png";
        
      }

    });
    
    

  }
  async getUserList(){
   
    // console.log(user12);
    // var user=user12.user;
    // let user1 = new Array();

    this.userService.getAll().subscribe(
      response => {
        if (response.result == true) {
          //console.log(response);
          this.user1 = response.records[0];
          //console.log(this.user1);
          // console.log(this.user1);
          this.getUser();
          
        }
      },
      error => {
        console.log(error);
      }
    );
    
  }
  async startValidation() {
    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    this._cognitoId = user.idCognito ? user.idCognito : null;
    this._name = user.name ? user.name : null;

   

    await this.getLocation();
    // console.log(this._cognitoId + ' - ' + this._name);
    // this.messagingService.requestPermission(this.longitude, this.latitude);
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    
    const _this = this;

    setInterval(async function(){
      await _this.getLocation();
      // console.log("Lon: " + _this.longitude + " Lat: " + _this.latitude);
      _this.messagingService.requestPermission(_this.longitude, _this.latitude);
    }, 45000);

  //   let user = {} as User;
  //   if (localStorage.getItem('user') !== null) {
  //     user = JSON.parse(localStorage.getItem('user'));
  //   } else {
  //     this.logOut();
  //   }
  //   if (user) {
  //     this.userAvatar = user.name;
  //     this.userPicture = user.picture;
  //     this.userEmail = user.email;
  //     this.currentTheme = user.theme ? user.theme : 'default';
  //     this.changeTheme(this.currentTheme);
  //     if (user !== undefined) {
  //       this.userService.oneUserExamBackend(user.userId)
  //         .then(
  //           (result) => {
  //             const userExam = result.body;
  //             localStorage.setItem('userExam', JSON.stringify(userExam));
  //             this.loading = false;
  //           }
  //         ).catch(
  //           (err) => {
  //             const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //             const status: NbComponentStatus = 'danger';
  //             this.toastrService.show(
  //               '',
  //               err.message,
  //               { position, status });
  //             this.loading = false;
  //           }
  //         );
  //     }
  //     this.authService.userProfileChanged.subscribe(change => {
  //       if (localStorage.getItem('user') !== null) {
  //         user = JSON.parse(localStorage.getItem('user'));
  //       }
  //       if (user) {
  //         this.userAvatar = user.name;
  //         this.userPicture = user.picture;
  //       }
  //     });
  //   }
  }

  getLocation() {

    return this.locationService.getLocation().then(pos =>{
      
      this.latitude = pos.latitude;
      this.longitude = pos.longitude;
      if(this.latitude != undefined && this.longitude != undefined){
        this.messagingService.requestPermission(this.longitude, this.latitude);
        
      }

    })
    // return this.locationService.getPosition().then(pos => {
    //   console.log(this.locationService.getLocation());
      
    //   this.latitude = pos.lat;
    //   this.longitude = pos.lng;
    //   if(this.latitude != undefined && this.longitude != undefined){
    //     this.messagingService.requestPermission(this.longitude, this.latitude);
        
    //   }
    // });
  }
  userTokenInsert(user) {
    return this.userService.saveUserTokenPush(user);
  }


  navigateHome() {
    this.router.navigate(['']);
  }

  navigateUserProfile() {
    this.router.navigate(['userProfile']);
  }

  logOut() {
    this.userService.logout();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'left');
    this.layoutService.changeLayoutSize();

    return false;
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

}
