import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public title;
  public message;

  
  constructor() { }

  ngOnInit() {
   
    
  }
  
  

  // mensaje(){
  //   this.title = 'Notificación mTracking';
  //   this.message = "Se envio un mensaje a traves del botón";

  //   const params = {
  //     "notification": {
  //       "title" : this.title,
  //       "body": this.message
  //     },
  //     "to": "ctUTNdZ9DoZ1DfzNtPHJUa:APA91bEER0C8pbDm0jCcsFT8UD9JRQZ-qpkit0VbeE-X65vlutwOszR3lFVm1GSPkCNTJKBASHS8lIcarvtlXi6f7G1UH1SbpMOEWbXaSLep-XJEtwBfW6z6FymCrA_HIBzPtJ6Ea37F"
  //   };

  //   //console.log(params);

  //   this.messagingService.sendMessage(params).subscribe(
  //     response => {
  //         console.log(response);
  //     },
  //     error => {
  //       console.log();
  //     }
  //   );
  // }


}