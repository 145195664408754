import { Component, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { Vehicle } from '../../models/vehicle';
import { VehicleService } from '../../services/vehicle.service';
import { CompanyVehicleRate } from '../../models/companyVehicleRate';
import { Rate } from '../../models/rate';
import { RateService } from '../../services/rate.service';
import {
	NbToastrService, NbGlobalPosition, NbComponentStatus,
	NbGlobalPhysicalPosition
} from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-company-vehicle-rate',
	templateUrl: './company-vehicle-rate.component.html',
	styleUrls: ['./company-vehicle-rate.component.scss'],
	providers: [CompanyService, VehicleService, RateService]
})
export class CompanyVehicleRateComponent implements OnInit {

	public _companyVehicleRate: CompanyVehicleRate[];
	public companyVehicleRate: CompanyVehicleRate;
	public list = new LocalDataSource();
	public _vehicles: Vehicle[];
	public _rates: Rate[];

	constructor(
		private _route: ActivatedRoute,
		private _vehicleService: VehicleService,
		private _companyService: CompanyService,
		private _rateService: RateService,
		private toastrService: NbToastrService
	) {
		this.companyVehicleRate = new CompanyVehicleRate(0, null, null, null, 1, null);
	}

	ngOnInit() {
		this._route.paramMap.subscribe(async (params: any) => {
			const _idCompany = params.params.idCompany;

			if (_idCompany !== undefined) {

				this.getVehicleRateByIdCompany(_idCompany);
				this.companyVehicleRate.idCompany = _idCompany;
			}
		});
		this.getAllVehicles();
		this.getAllRates();
	}

	getVehicleRateByIdCompany(idCompany) {
		this._companyService.getCompanyVehicleRateParam(idCompany).subscribe(
			response => {
				if (response.result == true) {
					this._companyVehicleRate = response.records[1];
					this.list = new LocalDataSource(this._companyVehicleRate);
					//console.log(response);
				}
			},
			error => {
				console.log();
			}
		);
	}

	getAllVehicles() {
		this._vehicleService.getAll().subscribe(
			response => {
				if (response.result == true) {
					this._vehicles = response.records[0];
					//					console.log(response);
				}
			},
			error => {
				console.log();
			}
		);
	}

	getAllRates() {
		this._rateService.getAll().subscribe(
			response => {
				if (response.result == true) {
					this._rates = response.records[0];
					//					console.log(response);
				}
			},
			error => {
				console.log();
			}
		);
	}

	settings = {
		hideSubHeader: false,
		mode: "external",
		actions: {
			edit: false,
			delete: true,
			add: false,
			columnTitle: "",
			position: "left",
			class: "action-colum"
		},
		delete: {
			deleteButtonContent: "<i class='fa fa-minus-square-o' title='Eliminar'></i>"
		},
		columns: {
			nameVehicle: {
				title: 'Vehículo',
				editable: false
			},
			rate: {
				title: 'Tarifa',
				editable: false
			},
			amount: {
				title: 'Costo',
				editable: false
			}
		}
	};

	onSubmit(form) {
		//console.log(this.companyVehicleRate);
		let idCompany = this.companyVehicleRate.idCompany;
		this._companyService.saveCompanyVehicleRate(this.companyVehicleRate).subscribe(
			response => {
				if (response.result == true) {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'success';
					this.toastrService.show(status || 'Success', response.message, { position, status });
					form.reset();
					this.getAllVehicles();
					this.getAllRates();
					this.getVehicleRateByIdCompany(idCompany);
				} else {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'warning';
					this.toastrService.show(status || 'Warning', response.message, { position, status });
				}
			},
			error => {
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				this.toastrService.show(status || 'Danger', <any>error, { position, status });
			}
		)
	}

	deleteVehicleRate(event) {

		//console.log(event);

		this.companyVehicleRate.id = 1;
		this.companyVehicleRate.idVehicle = event.data.idVehicle;
		this.companyVehicleRate.amount = event.data.amount;
		this.companyVehicleRate.idStatus = 2;

		let idCompany = this.companyVehicleRate.idCompany;
		this._companyService.saveCompanyVehicleRate(this.companyVehicleRate).subscribe(
			response => {
				if (response.result == true) {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'success';
					this.toastrService.show(status || 'Success', response.message, { position, status });
					this.getAllVehicles();
					this.getAllRates();
					this.getVehicleRateByIdCompany(idCompany);
					this.companyVehicleRate = new CompanyVehicleRate(0, null, null, null, 1, null);
				} else {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'warning';
					this.toastrService.show(status || 'Warning', response.message, { position, status });
				}
			},
			error => {
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				this.toastrService.show(status || 'Danger', <any>error, { position, status });
			}
		)



	}

}
