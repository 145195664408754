import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Report } from '../models/report';
import { Global } from './global';

@Injectable()
export class ReportService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	getAdminReport(report: Report): Observable<any>{
		let params = JSON.stringify(report);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-report-admin-all',params,{ headers: headers });
    }

	getClientReport(report: Report): Observable<any>{
		let params = JSON.stringify(report);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-report-client-all',params,{ headers: headers });
    }

	getCarrierReport(report: Report): Observable<any>{
		let params = JSON.stringify(report);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-report-carrier-all',params,{ headers: headers });
    }

}