import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user';
import { UserToken } from '../models/userToken';
import { Global } from './global';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

const POOL_DATA = {
	UserPoolId: environment.userPoolId,
	ClientId: environment.clientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable()
export class UserService {
	public url: string;
	private succedded = false;
	public authStatusChanged = new Subject<boolean>();
	public loggedIn: boolean;
	private AWS = require('aws-sdk');
  public userId = new Subject<any>();

	constructor(
		private _http: HttpClient,
		private toastrService: NbToastrService,
		private router: Router,
	) {
		this.url = Global.url;
	}

	save(user: User): Promise<any> {
		let params = JSON.stringify(user);
    console.log("Servicio" + params);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url + '/mtracking-user', params, { headers }).toPromise();

	}

	save1(user): Promise<any> {
		let params = JSON.stringify(user);
    	console.log(params);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url + '/mtracking-user', params, { headers }).toPromise();

	}

	getAll(): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url + '/mtracking-user', { headers: headers });
	}

	getCarrier(): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url + '/mtracking-userCarrier', { headers: headers });
	}

	initAuth() {
		this.isAuthenticated().subscribe(auth => this.authStatusChanged.next(auth));
	}

	isAuthenticated(): Observable<boolean> {
		const user = this.getAuthenticatedUser();
		const obs = new Observable<boolean>(observer => {
			if (!user) {
				observer.next(false);
			} else {
				user.getSession((err, session) => {
					if (err) {
						observer.next(false);
					}
					if (session.isValid()) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
			observer.complete();
		});
		return obs;
	}

	getAuthenticatedUser() {
		return userPool.getCurrentUser();
	}

	signIn(username: string, password: string): any {
		const authData = {
			Username: username,
			Password: password
		};
		const authDetails = new AuthenticationDetails(authData);
		const userData = {
			Username: username,
			Pool: userPool
		};
		const cognitoUser = new CognitoUser(userData);
		const that = this;
		this.succedded = false;
		cognitoUser.authenticateUser(authDetails, {
			onSuccess(result: any) {
				that.succedded = true;
				that.authStatusChanged.next(true);
        that.userId.next(result.idToken.payload['cognito:username']);
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'success';
				that.toastrService.show('', 'Bienvenido ' + result.idToken.payload.name,
					{ position, status });
				that.router.navigate(['']);
				//console.log(result);
				const userLogged = {
					user: result.idToken.payload.email,
					idCognito: result.idToken.payload['cognito:username'],
					name: result.idToken.payload.name,
					idRol: result.idToken.payload['custom:role'],
					password: undefined,
					idSucursal: result.idToken.payload['custom:company'],
					idStatus: undefined
				} as User;
				localStorage.setItem('user', JSON.stringify(userLogged));
			},
			onFailure(err: any) {
				that.authStatusChanged.next(false);
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				that.toastrService.show(
					status || '',
					err.message,
					{ position, status });
			}
		});

		this.authStatusChanged.next(this.succedded);
	}

	async logout() {
		await this.signOut();
		// this.getAuthenticatedUser().signOut();
		this.authStatusChanged.next(false);
		localStorage.clear();
	}

	signOut(): Promise<any> {
		return Auth.signOut()
			.then(() => this.loggedIn = false);
	}

	insertUserCognito(user) {
		console.log(user);
		const attrList: CognitoUserAttribute[] = [];
		const userRoleAttribute = {
			Name: 'custom:role',
			Value: user.idRol.toString()
		};
		const userEmailAttribute = {
			Name: 'email',
			Value: user.user
		};
		const userThemeAttribute = {
			Name: 'custom:theme',
			Value: user.userTheme === undefined ? 'default' : user.userTheme
		};
		const userPictureAttribute = {
			Name: 'picture',
			Value: user.user
		};
		const userCompanyAttribute = {
			Name: 'custom:company',
			Value: user.idSucursal.toString()
		};
		const userNameAttribute = {
			Name: 'name',
			Value: user.name
		};
		attrList.push(new CognitoUserAttribute(userRoleAttribute));
		attrList.push(new CognitoUserAttribute(userNameAttribute));
		attrList.push(new CognitoUserAttribute(userEmailAttribute));
		attrList.push(new CognitoUserAttribute(userThemeAttribute));
		attrList.push(new CognitoUserAttribute(userPictureAttribute));
		attrList.push(new CognitoUserAttribute(userCompanyAttribute));

		const params = {
			ClientId: environment.clientId,
			Username: user.user,
			UserAttributes: attrList,
			Password: user.password,
		};

		return new Promise((resolve, reject) => {
			this.AWS.config.update({
				region: environment.region,
				accessKeyId: environment.accessKeyId,
				secretAccessKey: environment.secretAccessKey
			});
			const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
			cognitoidentityserviceprovider.signUp(params, (err, data) => {
				if (err) {
					console.log(err)
					reject(err);
				} else {
					console.log(data)
					resolve(data);
				}
			});
		});
	}

  updateUserCognito(user) {
    const attrList: CognitoUserAttribute[] = [];
		const userRoleAttribute = {
			Name: 'custom:role',
			Value: user.rol
		};
		const userNameAttribute = {
			Name: 'name',
			Value: user.userName
		};
		const userThemeAttribute = {
			Name: 'custom:theme',
			Value: user.userTheme
		};
		attrList.push(new CognitoUserAttribute(userRoleAttribute));
		attrList.push(new CognitoUserAttribute(userNameAttribute));
		attrList.push(new CognitoUserAttribute(userThemeAttribute));
    const params = {
      UserPoolId: environment.userPoolId,
      Username: user.user,
      UserAttributes: attrList,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: "AKIAV42YBFE63XEICGHV",
		secretAccessKey: "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",
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

	// updateUserCognito(user) {
  //   console.log(user);
	// 	const attrList: CognitoUserAttribute[] = [];
	// 	const userRoleAttribute = {
	// 		Name: 'custom:role',
	// 		Value: user.rol
	// 	};
	// 	const userNameAttribute = {
	// 		Name: 'name',
	// 		Value: user.userName
	// 	};
	// 	const userThemeAttribute = {
	// 		Name: 'custom:theme',
	// 		Value: user.userTheme
	// 	};
	// 	attrList.push(new CognitoUserAttribute(userRoleAttribute));
	// 	attrList.push(new CognitoUserAttribute(userNameAttribute));
	// 	attrList.push(new CognitoUserAttribute(userThemeAttribute));
	// 	const params = {
	// 		UserPoolId: environment.userPoolId,
	// 		Username: user.idCognito,
	// 		UserAttributes: attrList,
	// 	};

	// 	return new Promise((resolve, reject) => {
	// 		this.AWS.config.update({
	// 			region: environment.region,
	// 			accessKeyId: environment.accessKeyId,
	// 			secretAccessKey: environment.secretAccessKey
	// 		});
	// 		const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
	// 		cognitoidentityserviceprovider.adminUpdateUserAttributes(params, (err, data) => {
	// 			if (err) {
	// 				reject(err);
	// 			} else {
	// 				resolve(data);
	// 			}
	// 		});
	// 	});
	// }
	updateUserBackend(user){
		// const openedSession = this.getAuthenticatedUserSession();
		// const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
		// return this.http.put(environment.apiUrl + this.userApi, user, { headers }).toPromise();
	}
	saveUserTokenPush(user: UserToken){
		let params = JSON.stringify(user);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url + '/mtracking-userToken', params, { headers: headers });
	}

	getUserTokenPush(idCognito): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-userToken/one/'+idCognito, {headers: headers});
	}

	getUserTokenAdminPush(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-user-token-admin', {headers: headers});
	}

  userGetOne(idCognito){
    const filter = idCognito;
    return this._http.get(this.url + '/mtracking-user' + '/one/' + filter).toPromise();
  }

  confirmUser(username: string, code: string) {
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  sendEmail(funcionArgs:any) {
    console.log(funcionArgs);
	this.AWS.config.update({
		region: environment.region,
		accessKeyId: "AKIAV42YBFE63XEICGHV",
		secretAccessKey: "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",
		apiKey: "AIzaSyCSlM0aY4ovmR3y_WcTjyv6z7oa3sDLhqI",
	});
    // this.AWS.config.update({
    //   accessKeyId: environment.accessKeyId,
    //   secretAccessKey: environment.secretAccessKey,
    //   region: environment.regionSES
    // });
	console.log("envio de credenciales");
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});
	
    var params = {
      FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:mtrackingbackend-dev-userSendCredentials',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
     };
	 
	 
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
			
          reject(err);
          return;
        }
        resolve(data);
      });
    });

// 	let params = JSON.stringify(funcionArgs);
//     console.log("Servicio" + params);
// 		let headers = new HttpHeaders().set('Content-Type', 'application/json');
// console.log(this.url + '/mtracking-user'+ '/notify/credencials/');
// 		return this._http.post(environment.apiUrl + '/mtracking-user'+ '/notify/credencials/', params, { headers }).toPromise();
	// return this._http.post(environment.apiUrl + '/mtracking-user'  + '/notify/credencials/', funcionArgs).toPromise();
  }

  async getUsers() {
    let paginationListUser = { Users: new Array() };
    let params = {
      UserPoolId: environment.userPoolId,
      PaginationToken: undefined,
    };

    return new Promise(async (resolve, reject) => {
      const firstPaging: any = await this.cognitoPagination(params);
      if (firstPaging.PaginationToken === undefined) {
        resolve(firstPaging);
      } else {
        firstPaging.Users.forEach(user => {
          paginationListUser.Users.push(user);
        });
        params.PaginationToken = firstPaging.PaginationToken;
        while (params.PaginationToken !== undefined) {
          const firstPaging2: any = await this.cognitoPagination(params);
          if (firstPaging2.PaginationToken === undefined) {
            params.PaginationToken = undefined;
            firstPaging2.Users.forEach(user => {
              paginationListUser.Users.push(user);
            });
            resolve(paginationListUser);
          } else {
            params.PaginationToken = firstPaging2.PaginationToken;
            firstPaging2.Users.forEach(user => {
              paginationListUser.Users.push(user);
            });
          }
        }
      }
    });
  }

  cognitoPagination(params) {
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.listUsers(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

  }

  oneUserBackend(userEmail){
	let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const filter:string = userEmail;
	console.log(filter);
	console.log(environment.apiUrl + '/mtracking-userNoLog' + '/one/' + filter);
    return this._http.get(environment.apiUrl + '/mtracking-userNoLog' + '/one/' + filter,{headers: headers}).toPromise();
  }

  acceptTerms(idCognito): Promise<any>{
    let params = JSON.stringify(idCognito);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		console.log(idCognito);
		return this._http.put(this.url + '/mtracking-userPolicy/',idCognito, { headers }).toPromise();
  }

  getAccessToken(username, password) {
	const POOL_DATA = {
		UserPoolId: environment.userPoolId,
		ClientId: environment.clientId
	};
	
	const userPool = new CognitoUserPool(POOL_DATA);
	return new Promise((resolve, reject) => {
		userPool.getCurrentUser().getSession(
			(err, session) => {
				if (err) {
					reject(err);
				} else {
					resolve(session);
				}

			}
		);
	});
}
  async changePasswordCognito(username, previousPassword, proposedPassword): Promise<any> {
	const accessToken: any = await this.getAccessToken(username, previousPassword);
	const params = {
		AccessToken: accessToken.accessToken.jwtToken,
		PreviousPassword: previousPassword,
		ProposedPassword: proposedPassword
	};

	return new Promise((resolve, reject) => {
		this.AWS.config.update({
			region: environment.region,
			accessKeyId: environment.accessKeyId,
			secretAccessKey: environment.secretAccessKey
		});
		const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
		cognitoidentityserviceprovider.changePassword(params, (err, data) => {
			if (err) {
				reject(err);
			} else {
				resolve(data);
			}
		});
	});
}

}
