import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
    isLogged: boolean;

    constructor(
        private userService: UserService,
        private router: Router,) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        let result;
        this.userService.isAuthenticated().
            subscribe((res) => {
                if (res) {
                    if (state.url === '/login') {
                        result = false;
                    } else {
                        result = true;
                    }
                } else {
                    if (state.url === '/login') {
                        result = true;
                    } else if (state.url === '/signIn') {
                        result = true;
                    }else {
                        result = false;
                    }
                }
            },
                (err) => {
                    console.error(err);
                }
            );
        if (result === undefined) {
            this.takeOutApp();
        }
        const user: User = JSON.parse(localStorage.getItem('user'));
        if (user === undefined) {
            this.takeOutApp();
        }
        return result;
    }

    takeOutApp() {
        this.userService.logout();
        this.router.navigate(['/login']);
    }
}
