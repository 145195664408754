import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Company } from '../models/company';
import { CompanyCommission } from '../models/companyCommission';
import { CompanyVehicleRate } from '../models/companyVehicleRate';
import { Global } from './global';

@Injectable()
export class CompanyService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	save(company: Company): Observable<any>{
		let params = JSON.stringify(company);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-company',params,{ headers: headers });

	}

	saveCommission(companyCommission: CompanyCommission): Observable<any>{
		let params = JSON.stringify(companyCommission);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-companyCommission',params,{ headers: headers });
	}

	saveCompanyVehicleRate(companyVehicleRate: CompanyVehicleRate): Observable<any>{
		let params = JSON.stringify(companyVehicleRate);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-companyVehicleRate',params,{ headers: headers });
	
	}
	
	getAll(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-company',{headers: headers});
	}

	getById(idCompany): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-company/one/'+idCompany, {headers: headers});
	}

	getCommission(idCompany): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-companyCommission/'+idCompany, {headers: headers});
	}

	getCompanyVehicleRate(idCompany): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-companyVehicleRate/'+idCompany, {headers: headers});
	}

	getCompanyVehicleRateParam(idCompany): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-companyVehicleRateParam/'+idCompany, {headers: headers});
	}
}