import { Component, OnInit } from '@angular/core';
import { Person } from '../../models/person';
import { PersonAddress } from '../../models/person-address';
import { PersonAddressService } from '../../services/person-address.service';
import { ClientService } from '../../services/client.service';
import { NbToastrService, NbGlobalPosition, 
		     NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-person-address',
  templateUrl: './person-address.component.html',
  styleUrls: ['./person-address.component.scss'],
  providers: [PersonAddressService, ClientService]
})
export class PersonAddressComponent implements OnInit {

  public personAddress = {} as PersonAddress;
  public person = {} as Person;
  public personsAddress : PersonAddress[];
  public personAddressList = new LocalDataSource();

  constructor(
  	private toastrService: NbToastrService,
  	private _personAddressService: PersonAddressService,
  	private _clientService: ClientService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {  }

  ngOnInit() {

  }

  getPersonById(id){
    this._clientService.getAllByPersonId(id).subscribe(
      response => {
        if(response.result == true){
          this.person = response.records;
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAddressByPersonId(id){
    this._personAddressService.getAllByPersonId(id).subscribe(
      response => {
        if(response.result == true){
          this.personsAddress = response.records;
          this.personAddressList = new LocalDataSource(this.personsAddress);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: false,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      countryId: {
        title: 'Pais',
        editable: false
      },
      departamentoId: {
        title: 'Departamento',
        editable: false
      },
      address: {
        title: 'Dirección',
        editable: false
      }
    }
  };

  onSubmit(form){
    //console.log(this.clientAddress);
  }

}
