<nb-card class="col-md-5 mx-auto" accent="info">
    <nb-card-header>Asignación de repartidor</nb-card-header>
    <nb-card-body>
        <form #assignCarrierForm="ngForm" (ngSubmit)="onSubmit(assignCarrierForm)">
            <div class="row">
                <div class="col-sm-8">
                    <label for="repartidor" class="label">Seleccione repartidor</label>
                    <nb-select fullWidth id="idCarrier" name="idCarrier" [(ngModel)]="task.idCarrier" placeholder="Repartidores">
                        <nb-option [value]="carrier.id" *ngFor="let carrier of carriers">
                            {{carrier.name}}</nb-option>
                    </nb-select>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label for="button" class="label label-button">boton</label>
                        <button nbButton ghost status="success">
                            <nb-icon icon="save-outline"></nb-icon>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="task" class="label">Sucursal: </label><br/>
                        <span>{{task.sucursalName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="task" class="label">Tarea: </label><br/>
                        <span>{{task.codeTask}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="task" class="label">Transporte: </label><br/>
                        <span>{{task.nameVehicle}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="task" class="label">Remitente: </label><br/>
                        <span>{{task.petitionerName}}</span><br/>
                        <span>{{task.petitionerPhone}}</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="task" class="label">Dirección recolección:</label><br/>
                        <span>{{task.addressPetitioner}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="task" class="label">Destinatario: </label><br/>
                        <span>{{task.customerName}}</span><br/>
                        <span>{{task.customerPhone}}</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="task" class="label">Dirección entrega:</label><br/>
                        <span>{{task.addressCustomer}}</span>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="task" class="label">Observación: &nbsp;</label>
                        <span>{{task.comentario}}</span>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>