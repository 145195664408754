import { Component, OnInit } from '@angular/core';
import { TemplateRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from '../../models/task';
import { TaskService } from '../../services/task.service';
import { ClientService } from '../../services/client.service';
import { PersonAddressService } from '../../services/person-address.service'
import { PersonAddress } from '../../models/person-address';
import { NbDialogService } from '@nebular/theme';
import { NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition, NbDialogRef } from '@nebular/theme';
import { Person } from '../../models/person';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import * as mapboxgl from 'mapbox-gl';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { UserService } from 'src/app/services/user.service';
interface MarcadorColor {
  color: string;
  marker?: mapboxgl.Marker;
  centro?: [number, number];
}

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
  providers: [TaskService,ClientService,PersonAddressService]
})
export class clienteComponent implements OnInit {

  public task: Task;
  public tasks: Task[];
  public tasksList = new LocalDataSource();
  public tasksList2 = new LocalDataSource();
  public mDpersonAddress: PersonAddress;
  public mPerson: Person;
  public person2: Person;
  clients;
  mDpersonAddress3;
  public _personAddress2: any;
  personAddress2;
  clienteName = "";
  idCliente = "";
  personAddress = "";
  menuEdit = false;
  public flgInfo;
  public flgMapa;
  public flgCliente;
  public flgDireccion;
  public userDB = {} as User;

  
/* Integracion mapboxgl */
public _latitude;
public _longitude;

@ViewChild('map', { static: false }) divMap!: ElementRef;
  map!: mapboxgl.Map;
  zoomLevel: number = 10;
  center: [number, number] = [-90.49322418747106, 14.580160123137151];

  // Arreglo de marcadores
  marcadores: MarcadorColor[] = [];

  constructor(
    private _taskService: TaskService,
    private _router: Router,
    private _personAddressService: PersonAddressService,
    private _clientService: ClientService,
    private _route: ActivatedRoute,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {  this.mDpersonAddress = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");
    this.person2 = new Person(0, null, null, null, null, 1,0);
    this._personAddress2 = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");
    this.mPerson = new Person(0, null, null, null, null, 1,0);
  }
  
  async ngOnInit() {

    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    console.log(user);
    this.userService.userGetOne(user.idCognito).then(
      (result : any)=>{
      // console.log(result)
      if (result.result == true) {
        this.userDB = result.records[1][0];
        
        console.log(this.userDB);
      }
    },
      error => {
        console.log();
      })

    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:none;');

    await this.filter();
    
  }
  ngAfterViewInit() {
   
    // this.map = new mapboxgl.Map({
    //   container: this.divMap.nativeElement,
    //   style: 'mapbox://styles/mapbox/streets-v11',
    //   center: this.center,
    //   zoom: 15,
    // });

    //console.log(this.divMap.nativeElement);

  }
  // getAllTasks() {
  //   this._taskService.getAllTaskToAsign().subscribe(
  //     response => {
  //       if (response.result == true) {
  //         this.tasks = response.records[0];
  //         this.tasksList = new LocalDataSource(this.tasks);
  //         //console.log(response);
  //       }
  //     },
  //     error => {
  //       console.log();
  //     }
  //   );
  // }
  async filter(){
    
    this._clientService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.clients = response.records[0];
          console.log(response);
          this.clients.forEach(element => {
            
            element.address="<i class='fa fa-pencil-square-o' title='Editar'></i>";
          });
          
          this.tasksList = new LocalDataSource(this.clients);
          //console.log(this.options);
        }
        
        
      },
      error => {
        console.log();
      }
    );
    
    this.tasksList = new LocalDataSource(this.clients);
  }

  editClient(event){
    console.log(event);
    this.mPerson = event.data;
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    // this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    document.location.href = "cliente#crearCliente";
  }

  

  async getAllAddressByClient2(_idAddressReceiver) {
    const selectedValue = this.clients.find(x => x.id === _idAddressReceiver);
    this._personAddressService.getAddressByPersonId(_idAddressReceiver).subscribe(
      response => {
        if (response.result == true) {
          this.personAddress2 = response.records[1];
          console.log(this.personAddress2);
          this.tasksList2 = new LocalDataSource(this.personAddress2);
          
        }
      },
      error => {
        console.log();
      }
    );
    
  }

  settingsAddress = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      address: {
        title: 'Dirección Completa',
        editable: false
      },
      nameMunicipality: {
        title: 'Municipio',
        editable: false
      },
      zone: {
        title: 'Zona',
        editable: false
      },
      
      latitude: {
        title: 'Latitud',
        editable: false
      },
      longitude: {
        title: 'Longitud',
        editable: false
      },
      name: {
        title: 'Tipo de dirección',
        editable: false
      }
    }
  };

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: true,
      add: false,
      columnTitle: "Opciones",
      position: "left",
      class: "action-colum"
    },
    edit: {
      title:"Ver Dirección",
      editButtonContent: "<i class='fa fa-eye' title='Ver Dirección'></i>"
    },
    delete: {
      title:"Editar",
      deleteButtonContent: "<i class='fa fa-edit' title='Editar'></i>"
    },
    columns: {
      
      name: {
        title: 'Nombre',
        editable: true
      },
      phone: {
        title: 'Telefono',
        editable: false 
      },
      mail: {
        title: 'Correo',
        editable: false
      }
    },
    
  };
  addressType = [
    {
      value: 1,
      name: 'Casa',
    },
    {
      value: 2,
      name: 'Oficina',
    },
  ];
  packagesType = [
    {
      value: 1,
      name: 'Sobres',
    },
    {
      value: 2,
      name: 'Ropa',
    },
  ];
  municipality = [
    {
      value: 1,
      name: 'Ciudad capital ',
    },
    {
      value: 2,
      name: 'Mixco',
    },
    {
      value: 3,
      name: 'Villa nueva',
    },
    {
      value: 4,
      name: 'San José Pinula',
    },
    {
      value: 5,
      name: 'Fraijanes',
    },
    {
      value: 6,
      name: 'San Miguel Petapa',
    },
    {
      value: 7,
      name: 'Boca del monte',
    },
    {
      value: 8,
      name: 'Villa canales',
    },
    {
      value: 9,
      name: 'Chinautla',
    },
  ];
  zones = [
    {
      value: 100,
      name: 'No Aplica',
    },
    {
      value: 1,
      name: 'Zona 1',
    },
    {
      value: 2,
      name: 'Zona 2',
    },
    {
      value: 3,
      name: 'Zona 3',
    },
    {
      value: 4,
      name: 'Zona 4',
    },
    {
      value: 5,
      name: 'Zona 5',
    },
    {
      value: 6,
      name: 'Zona 6',
    },
    {
      value: 7,
      name: 'Zona 7',
    },
    {
      value: 8,
      name: 'Zona 8',
    },
    {
      value: 9,
      name: 'Zona 9',
    },
    {
      value: 10,
      name: 'Zona 10',
    },
    {
      value: 11,
      name: 'Zona 11',
    },
    {
      value: 12,
      name: 'Zona 12',
    },
    {
      value: 13,
      name: 'Zona 13',
    },
    {
      value: 14,
      name: 'Zona 14',
    },
    {
      value: 15,
      name: 'Zona 15',
    },
    {
      value: 16,
      name: 'Zona 16',
    },
    {
      value: 17,
      name: 'Zona 17',
    },
    {
      value: 18,
      name: 'Zona 18',
    },
    {
      value: 19,
      name: 'Zona 10',
    },
    {
      value: 21,
      name: 'Zona 21',
    },
    {
      value: 24,
      name: 'Zona 24',
    },
    {
      value: 25,
      name: 'Zona 25',
    },
  ];
  assignTask(event) {
    // this._router.navigate(
    //   ['/task-carrier-assign/' + event.data.id]
    // );
    this.clienteName = event.data.name;
    this.idCliente = event.data.id;
    this.getAllAddressByClient2(event.data.id);
  }

  open2(dialog2: TemplateRef<any>, idPersona_) {
    this.mDpersonAddress.idCustomer = idPersona_;
    this.dialogService.open(dialog2, { context: 'this is some additional data passed to dialog' });
  }

  save2() {
    //console.log(this.personAddress);

    /* Obtenemos la longitud y latitud del marcador */
    // const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);
    // this.mDpersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    // this.mDpersonAddress.latitude = lngLatArr[0]['centro'][1].toString();
    const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);
    this.mDpersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    this.mDpersonAddress.latitude = lngLatArr[0]['centro'][1].toString();
    this.mDpersonAddress.idStatus = 1;
    console.log(this.mDpersonAddress);
    this._personAddressService.save(this.mDpersonAddress).subscribe(
      response => {
        
        if (response.result == true) {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.getDivInfo();
          this.borrarMarcador(0);
          this.verDireccion(response.records[9][0].id);
          this.getAllAddressByClient2(this.person2.id);
          this.task.idAddressPetitioner=response.records[9][0].id;
          setTimeout(function(){
            
            window.location.reload();
        },1000);
        } else {
          console.log(response);
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }


  async getDivDireccion(idPersona_) {
    this.menuEdit = true;
    
    this.mDpersonAddress.idCustomer = idPersona_;

    await this.getAllAddressByClient2(this.idCliente);
    
    await this.personAddress2.forEach(element => {
      if(idPersona_.data.id == element.id){
        console.log(element);
        this.mDpersonAddress= element;
        this.mDpersonAddress.idAddressMunicipality = element.idMunicipality;
        this.mDpersonAddress.idAddressZone = element.idZone;
        this.mDpersonAddress.idCatAddress = element.idAddressType;
        this.mDpersonAddress.latitude = element.latitude;
        this.mDpersonAddress.longitude = element.longitude;
        this.mDpersonAddress.idCustomer = element.idCustomer;
      }
    });
    this.center =[Number(this.mDpersonAddress.longitude), Number(this.mDpersonAddress.latitude)];
    this.map = new mapboxgl.Map({
      container: this.divMap.nativeElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: 15,
    });
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.addMarker();
  }
  getDivCliente() {
    this.mPerson = new Person(0,'','','','',0,0);
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    // this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    document.location.href = "cliente#crearCliente";
    
  }

  getDivInfo() {
    this.menuEdit = false;
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:none;');
  }
  borrarMarcador(i: number) {

    this.marcadores[i].marker.remove();
    this.marcadores.splice(i, 1);
    this.guardarLocalStorage();
  }

  async verDireccion(e) {
    this._personAddressService.getOneAddressByPersonId(e).subscribe(
      response => {
        if (response.result == true) {
          this._personAddress2 = response.records[1][0];
           
        }
      },
      error => {
        console.log();
      }
    );
    
    // this._personAddressService.getOneAddressByPersonId(e).subscribe(
    //   response => {
    //     if (response.result == true) {
    //       this._personAddress = response.records[1][0];
    //       //console.log(response);
    //     }
    //   },
    //   error => {
    //     console.log();
    //   }
    // );
  }
  
  guardarLocalStorage() {

    const lngLatArr: MarcadorColor[] = [];

    this.marcadores.forEach(m => {

      const color = m.color;
      const { lng, lat } = m.marker!.getLngLat();

      lngLatArr.push({
        color: color,
        centro: [lng, lat]
      });

    })

    localStorage.setItem('marcadores', JSON.stringify(lngLatArr));

  }


  addMarker() {
    console.log(this.map);
    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));

    if (this.marcadores.length < 1) {
      const nuevoMarcador = new mapboxgl.Marker({
        draggable: true,
        color
      })
        .setLngLat(this.center)
        .addTo(this.map);

      this.marcadores.push({
        color,
        marker: nuevoMarcador
      });

      this.guardarLocalStorage();

      nuevoMarcador.on('dragend', () => {
        this.guardarLocalStorage();
      });

    }
  }

  getMarker(marker: mapboxgl.Marker) {
    this.map.flyTo({
      center: marker.getLngLat()
    });
  }

  

  leerCoordenadas(sucursal) {

    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));
    const centro: [number, number] = [sucursal.longitude, sucursal.latitude];

    const newMarker = new mapboxgl.Marker({
      color: color,
      draggable: true
    })
      .setLngLat(centro)
      .addTo(this.map);

    this.marcadores.push({
      marker: newMarker,
      color: color
    });

    newMarker.on('dragend', () => {
      this.guardarLocalStorage();
    });

  }


  onSubmit() {

    /* Obtenemos la longitud y latitud del marcador */
    // const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);
    // this.mPersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    // this.mPersonAddress.latitude = lngLatArr[0]['centro'][1].toString();

    // console.log(this.mPerson);
    // console.log(this.mPersonAddress);
    //console.log(this.mPerson);

   


    this.mPerson.userCreate = this.userDB.id;
    this.mPerson.idStatus = 1;
    console.log(this.mPerson);
    this._clientService.save(this.mPerson).subscribe(
      response => {
        if (response.result == true) {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.getDivInfo();
          setTimeout(function(){
            
            window.location.reload();
        },1000);
          
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }
  



}
