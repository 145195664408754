import { Component, OnInit } from '@angular/core';
import { Report } from '../../models/report';
import { ReportService } from '../../services/report.service';
import { User } from '../../models/user';
import { NbToastrService, NbGlobalPosition, 
  NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-carrier-report',
  templateUrl: './carrier-report.component.html',
  styleUrls: ['./carrier-report.component.scss'],
  providers: [ReportService]
})
export class CarrierReportComponent implements OnInit {

  public report: Report;
  public _report: Report[];
  public reportList = new LocalDataSource();
  public _idUsuario;
  settin;
  user = {} as User;
  userRoleShow;
  user1=new Array();
  userDB = {} as User;
  constructor(
    private _reportService: ReportService,
    private toastrService: NbToastrService,
    private userService: UserService,
    ) { 
    this.report = new Report(2, null, null, null, null);

    let user = {} as User;
    if (localStorage.getItem('user')) {

      user = JSON.parse(localStorage.getItem('user'));
    }
    

    this._idUsuario = user.idCognito ? user.idCognito : null;
  }

  async ngOnInit() {
    
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    this.user = userLogged;
    
    await this.getUserList();
    
  }
  async getUserList(){
   
    // console.log(user12);
    // var user=user12.user;
    // let user1 = new Array();
    this.userService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.user1 = response.records[0];
          
          this.getUser();
          
        }
      },
      error => {
        console.log(error);
      }
    );
    
  }

  async getUser(){
    let user12 = {} as User;
    if (localStorage.getItem('user')) {
      user12 = JSON.parse(localStorage.getItem('user'));
    }
    var user=user12.user;
    this.user1.forEach(element => {
      if (element.user == user) {
       
        this.userDB = element;
        if(this.userDB.idRol === 4){
          this.settin = this.settingsRepartidor;
      
         
        }else{
          this.settin = this.settingsRepartidor;
         
         
        }
     
      }

    });
   
    // console.log(this.userDB);

  }
  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: false,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      codeTask: {
        title: 'Tarea',
        editable: false
      },
      sucursalName: {
        title: 'Sucursal',
        editable: false
      },
      dateShipping: {
        title: 'Fecha Recolección',
        editable: false
      },
      dateReceiption: {
        title: 'Fecha Recolección',
        editable: false
      },
      vehicleName: {
        title: 'Vehiculo',
        editable: false
      },
      commission: {
        title: 'Comision',
        editable: false
      },
      statusName: {
        title: 'Estado',
        editable: false
      }
    }
  };

  settingsRepartidor = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: false,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      codeTask: {
        title: 'Tarea',
        editable: false
      },
      dateShipping: {
        title: 'Fecha Envio',
        editable: false
      },
      hourReceiption: {
        title: 'Hora Recolección',
        editable: false
      },
      dateReceiption: {
        title: 'Fecha Recolección',
        editable: false
      },
      customName: {
        title: 'Nombre del cliente',
        editable: false
      },
      phone: {
        title: 'Número de teléfono',
        editable: false
      },
      address: {
        title: 'Direccion',
        editable: false
      },
      commission: {
        title: 'Comision',
        editable: false
      },
      statusName: {
        title: 'Estado',
        editable: false
      }
    }
  };
  generar(){
    this.report.idUsuario = this._idUsuario;
    //console.log(this.report);
    this._reportService.getCarrierReport(this.report).subscribe(
      response => {
        if(response.result == true){
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          console.log(response.records);
          this._report = response.records[3];
          console.log(this._report);
          this.reportList = new LocalDataSource(this._report);
        }else{
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

}
