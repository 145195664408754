<nb-card class="col-md-5 mx-auto" accent="info">
  <nb-card-header>Mantenimiento de comisiones</nb-card-header>
  <nb-card-body>
    <form #form="ngForm" (ngSubmit)="onSubmit(form)">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="commission" class="label">Monto comisión</label>
                    <input type="text" nbInput fullWidth name="commission" 
                    nbTooltip="Monto de la comision" nbTooltipPlacement="top" 
                    [(ngModel)]="_commission.commission" required />
                </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                  <label for="idPetitioner" class="label">Vehículo: <span nbTooltip="Campo requerido" style="color:red;">*</span></label>
                  <nb-select fullWidth id="idSucursal" name="idSucursal" [(ngModel)]="_commission.id_vehiculo" placeholder="Seleccione..."  nbTooltipPlacement="top" nbTooltip="Vehículo" required="true">
                      <nb-option [value]="vehicle.id" *ngFor="let vehicle of vehicles">
                          {{vehicle.name}}</nb-option>
                  </nb-select>
              </div>
          </div>

            <div class="col-sm-6">
                <div class="form-group">
                  <label for="commission" class="label label-button">Boton guardar</label>
                    <button nbButton ghost status="success" >
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </form>
  </nb-card-body>
</nb-card>
<nb-card class="col-md-5 mx-auto" accent="info">
  <nb-card-header>Listado de comisiones</nb-card-header>
  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="list" (edit)="editCommission($event)" (delete)="deleteCommission($event)"></ng2-smart-table>
  </nb-card-body>
</nb-card>
