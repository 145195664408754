<app-loading *ngIf="loading"></app-loading>

    
<nb-user size="large"
             name=""
             title=""
             picture="https://w7.pngwing.com/pngs/406/481/png-transparent-back-arrow-key-material-free-material-return-thumbnail.png"
             onlyPicture style="cursor: pointer;" (click)="regresar()">
    </nb-user>
<nb-layout-column >
    <nb-card >
       
        <nb-card-body>
            
            <nb-auth-block class="card-col2">
                <div>
                    <h1 style="margin-bottom: 2px!important"> CREA TU </h1>
                    <h1 style="margin-top: 0px!important">CUENTA</h1>
                </div>
                <form>
                    <!-- <button type="button" *ngIf="azureLogin" fullWidth (click)="signInWithAzureAD()" nbButton ghost
                        style="border-radius: 0.75rem!important;">
                        Ingresar con correo
                        <img width="20px" style="margin-left:0.5rem;" alt="Google"
                            src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" />
                    </button> -->
                    <!-- <button type="button" *ngIf="gmailLogin" fullWidth (click)="signInWithGoogle()" nbButton ghost
                        style="border-radius: 0.75rem!important;">
                        Ingresar con correo
                        <img width="20px" style="margin-left:0.5rem;" alt="Google"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                    </button> -->
                    <div class="form-control-group">
                        
                  
                        <input nbInput fullWidth class="inputText" pattern=".+@.+\..+" autofocus type="text"
                            shape="semi-round" autocomplete="off" placeholder="Correo" name="user" id="user"
                            [(ngModel)]="user.user" maxlength="50" required style="margin: 0%;border-color: #92D6E3!important;background-color: #92D6E3!important; margin-bottom: 1px;border-bottom: #1378DE;color: #0C94AC;position: relative;left: 5%;">
                            <nb-icon  nbButton style="position:relative;left: -90%;color: #d87e4b;" class="mat-icon-button-hide" 
                            icon="email-outline"></nb-icon>
                    </div>
                    <div class="form-control-group">
                        
                  
                        <input nbInput fullWidth class="inputText" autofocus type="text"
                            shape="semi-round" autocomplete="off" placeholder="Usuario" name="user" id="user"
                            [(ngModel)]="user.name" maxlength="50" required style="margin: 0%;border-color: #92D6E3!important;background-color: #92D6E3!important; margin-bottom: 1px;border-bottom: #1378DE;color: #0C94AC;position: relative;left: 5%;">
                            <nb-icon  nbButton style="position:relative;left: -90%;color: #d87e4b;" class="mat-icon-button-hide" 
                            icon="people-outline"></nb-icon>
                    </div>
                    <div class="form-control-group">
                        <input  [nbDatepicker]="datepicker" type="text" nbInput fullWidth [(ngModel)]="user.userBirthDate" name="userBirthDate" id="userBirthDate" placeholder="Fecha de Cumpleaños" maxlength="20"
                        style="margin: 0%;border-color: #92D6E3!important;background-color: #92D6E3!important; margin-bottom: 1px;border-bottom: #1378DE;color: #0C94AC;position: relative;left: 5%;" autocomplete="off">
                        <nb-datepicker #datepicker format="dd/MM/yyyy"></nb-datepicker>
                        <nb-icon  nbButton style="position:relative;left: -90%;color: #d87e4b;" class="mat-icon-button-hide"
                            icon="calendar-outline"></nb-icon>
                    </div>
                    <div class="form-control-group">
                        
                  
                        <input nbInput fullWidth class="inputText" type="text"
                            shape="semi-round" autocomplete="off" placeholder="Número de Teléfono" name="userNumberPhone" id="userNumberPhone"
                            [(ngModel)]="user.userNumberPhone" maxlength="50" required style="margin: 0%;border-color: #92D6E3!important;background-color: #92D6E3!important; margin-bottom: 1px;border-bottom: #1378DE;color: #0C94AC;position: relative;left: 5%;">
                            <nb-icon  nbButton style="position:relative;left: -90%;color: #d87e4b;" class="mat-icon-button-hide" 
                            icon="phone-outline"></nb-icon>
                    </div>
                    <div class="form-control-group">
                        
                  
                        <input nbInput fullWidth class="inputText" pattern=".+@.+\..+" type="text"
                            shape="semi-round" placeholder="Número de NIT (opcional)" name="userNit" id="userNit"
                            [(ngModel)]="user.userNit" maxlength="50" style="margin: 0%;border-color: #92D6E3!important;background-color: #92D6E3!important; margin-bottom: 1px;border-bottom: #1378DE;color: #0C94AC;position: relative;left: 5%;">
                            <nb-icon  nbButton style="position:relative;left: -90%;color: #d87e4b;" class="mat-icon-button-hide" 
                            icon="file-outline"></nb-icon>
                    </div>
                    <div class="form-control-group">
                        <input nbInput fullWidth class="inputText" pattern="(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{6,16}$" [type]="hide ? 'password' : 'text'"
                            shape="semi-round" placeholder="Contraseña"
                            [(ngModel)]="user.password" name="password" id="password" maxlength="50" style="margin: 0%;border-color: #92D6E3!important;background-color: #92D6E3!important; position: relative;left: 5%;">
                        <nb-icon  nbButton style="position: sticky;color: #0C94AC;" class="mat-icon-button-hide" (click)="hide = !hide"
                            icon="eye-outline"></nb-icon>
                            <nb-icon  nbButton style="position:relative;left: -90%;color: #d87e4b;" class="mat-icon-button-hide"
                            icon="lock-outline"></nb-icon>
                    </div>
                    
                    
                    <br><br>
                    <button nbButton fullWidth type="submit" (click)="saveUser()"
                        style="border-radius: 0.40rem!important; border-color:#0C94AC ; background-color: #0C94AC !important; color: #92D6E3; height: 50px;">CREAR USUARIO
                        
                    </button>
                    <!-- <div class="extra-button">
                        <button type="button" *ngIf="resetPassword" fullWidth nbButton ghost responsive size="tiny"
                            class="reset-button" style=" color: #1378DE;" (click)="onClickResetPassword()">Restablecer
                            contraseña<nb-icon icon="unlock-outline"></nb-icon></button>
                        <button type="button" *ngIf="resendVCode" fullWidth nbButton ghost responsive size="tiny"
                            animation="pulse" class="reset-button" style="color: #1378DE;"
                            (click)="onResendValid()">Reenviar código
                            <nb-icon icon="email-outline"></nb-icon>
                        </button>
                    </div> -->
                </form>
                <div class="brand-logo2">
                    <!-- <img class="brand-logo-img2" src="assets/images/mTrainingWorld.png"> -->
                </div>
            </nb-auth-block>
        </nb-card-body>
        <nb-card-footer>
            <div style="float: right;">
                <span style="line-height: 1.5rem; font-size: 0.75rem;"><a href="https://myappsoftware.com/" target="_blank"
                        style="text-decoration: none !important;"><span
                            style="color: #FF9900;">Myappsoftware</span></a><span style="color: #707070;"> powered by
                    </span></span>
                <a href="https://aws.amazon.com/partners/find/partnerdetails/?n=Criptonube%20-%20Myappsoftware&id=001E000000VKaCSIA1"
                    target="_blank">
                    <img src="assets/images/awsLogo.png" style="height: 1rem; margin-bottom: -0.4rem;">
                </a>
            </div>
            
        </nb-card-footer>
    </nb-card>
</nb-layout-column>
