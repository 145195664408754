import { Component, OnInit } from '@angular/core';
import { Person } from '../../models/person';
import { CarrierService } from '../../services/carrier.service';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
  providers: [CarrierService]
})
export class TaskViewComponent implements OnInit {

  public carrier: Person;
  public carriers : Person[];

  constructor(
  	private _carrierService: CarrierService,
  ) { }

  ngOnInit() {
  	this.getAllRepartidores();
  }

  getAllRepartidores(){
    this._carrierService.getAll().subscribe(
      response => {
        if(response.result == true){
          this.carriers = response.records;
          console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

}
