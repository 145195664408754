<nb-card class="col-md-10 mx-auto" accent="info" *ngIf="!menuEdit">
  <nb-card-header>
    <h4>
        <span>Listado de Clientes</span>
        <div>
            <button nbButton ghost nbTooltip="Crear Ciente" (click)="getDivCliente()">
                <nb-icon icon="plus-square-outline" style="color:#5F78DE; object-position: right;"></nb-icon>
            </button>
            
        </div>
    </h4>
  </nb-card-header>
  
  <nb-card-body>
    <ng2-smart-table (edit)="assignTask($event)" (add)="getDivCliente()" (delete)="editClient($event)" [settings]="settings" [source]="tasksList">
      
    </ng2-smart-table>
  </nb-card-body>
</nb-card>

<nb-card class="col-md-10 mx-auto" accent="info" *ngIf="!menuEdit">
  <nb-card-header>Listado de direcciones del cliente {{this.clienteName}}</nb-card-header>
  <nb-card-body>
    <ng2-smart-table (edit)="getDivDireccion($event)" [settings]="settingsAddress" [source]="tasksList2">
      
    </ng2-smart-table>
  </nb-card-body>
</nb-card>


<div class="column"[style]="flgDireccion">
  
  <nb-card accent="info" class="col-sm-8">
      <nb-card-header>Ingresar dirección</nb-card-header>
      <nb-card-body>
          <div class="row">
              <div class="col-sm-12">
                  <div class="form-group">
                      <label for="address" class="label">Dirección</label>
                      <input type="text" nbInput fullWidth name="address" [(ngModel)]="mDpersonAddress.address" required="true" nbTooltip="Dirección de cliente" nbTooltipPlacement="top" />
                  </div>
              </div>
              <!-- <div class="col-sm-12">
                  <div class="form-group">
                      <label for="idCatAddress" class="label">Tipo Dirección: </label>
                      <nb-select fullWidth id="idCatAddress" name="idCatAddress" [(ngModel)]="mDpersonAddress.idCatAddress" placeholder="Tipo Dirección">
                          <nb-option [value]="address.id" *ngFor="let address of _mDaddress">
                              {{address.name}}</nb-option>
                      </nb-select>
                  </div>
              </div> -->
              <div class="col-sm-4">
                  <div class="form-group">
                      <label for="idAddressMunicipality" class="label">Municipio</label>
                      <nb-select fullWidth id="idAddressMunicipality" name="idAddressMunicipality" placeholder="Seleccione..." [(ngModel)]="mDpersonAddress.idAddressMunicipality" nbTooltipPlacement="top" nbTooltip="Municipio del remitente">
                          <nb-option [value]="p2Address.value" *ngFor="let p2Address of municipality">
                              {{p2Address.name}}</nb-option>
                      </nb-select>
                  </div>
              </div>
              
              <div class="col-sm-4">
                  <div class="form-group">
                      <label for="idAddressZone" class="label">Zona</label>
                      <nb-select fullWidth id="idAddressZone" name="idAddressZone" placeholder="Seleccione..." [(ngModel)]="mDpersonAddress.idAddressZone" nbTooltipPlacement="top" nbTooltip="Zona del remitente">
                          <nb-option [value]="p2Address.value" *ngFor="let p2Address of zones">
                              {{p2Address.name}}</nb-option>
                      </nb-select>
                  </div>
              </div>
              <div class="col-sm-4">
                  <div class="form-group">
                      <label for="idAddressPetitioner" class="label">Tipo de Dirección: </label>
                      <nb-select fullWidth id="idCatAddress" name="idCatAddress" placeholder="Seleccione..." [(ngModel)]="mDpersonAddress.idCatAddress" nbTooltipPlacement="top" nbTooltip="Dirección remitente">
                          <nb-option [value]="p2Address.value" *ngFor="let p2Address of addressType">
                              {{p2Address.name}}</nb-option>
                      </nb-select>
                      <span style="color:red;" *ngIf="mDpersonAddress.idCatAddress === 2"> Favor tomar en cuenta que por politicas, la tarifas de Rapi2Go no incluyen pagos de parqueos</span>
                  </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                    <label for="condominiumName" class="label">Nombre de Condominio (Opcional)</label>
                    <input type="text" nbInput fullWidth name="condominiumName" [(ngModel)]="mDpersonAddress.condominiumName" required="true" nbTooltip="Nombre de Condominio (Opcional)" nbTooltipPlacement="top" />
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="accessCode" class="label">Código de Acceso (Opcional)</label>
                    <input type="text" nbInput fullWidth name="accessCode" [(ngModel)]="mDpersonAddress.accessCode" required="true" nbTooltip="Código de Acceso (Opcional)" nbTooltipPlacement="top" />
                </div>
            </div>
              <div class="col-sm-3">
                  <div class="form-group">
                      <label for="button" class="label label-button">boton</label>
                      <button nbButton ghost status="success" (click)="save2()">
                          <nb-icon icon="save-outline"></nb-icon>
                          Guardar
                      </button>
                  </div>
              </div>
              
          </div>
          
      </nb-card-body>
      <nb-card-footer class="text-right">
          <button nbButton ghost status="secondary" (click)="getDivInfo()">Cerrar</button>
      </nb-card-footer>
  </nb-card>
  <div class="col-sm-4" [style]="flgMapa" >
    <div #map class="map-container">
        <ul class="list-group">
            <!-- <li class="list-group-item list-group-item-secondary" (click)="addMarker()">
                + Agregar
            </li> -->
  
            <li class="list-group-item" (click)="getMarker(marcador.marker)" (dblclick)="borrarMarcador(i)" *ngFor="let marcador of marcadores; let i = index" [ngStyle]="{
                'background-color': marcador.color
            }">
                Marcador
            </li>
  
        </ul>
    </div>
  </div>
  
</div>

<div class="column" [style]="flgCliente" id="crearCliente">
    <nb-card accent="info">
        <nb-card-header>Ingresar información</nb-card-header>
        <nb-card-body>
            <form #companyForm="ngForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="label">NIT <span nbTooltip="Campo requerido" style="color:red;">*</span></label>
                            <input type="text" nbInput fullWidth name="nit" [(ngModel)]="mPerson.nit" required="true" autocomplete="false" nbTooltip="NIT" nbTooltipPlacement="top" />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="name" class="label">Nombre <span nbTooltip="Campo requerido" style="color:red;">*</span></label>
                            <input type="text" nbInput fullWidth name="name" [(ngModel)]="mPerson.name" required="true" nbTooltip="Nombre de cliente" nbTooltipPlacement="top" />
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label for="phone" class="label">Teléfono <span nbTooltip="Campo requerido" style="color:red;">*</span></label>
                            <input type="text" nbInput fullWidth name="phone" [(ngModel)]="mPerson.phone" required="true" maxlength="8" autocomplete="false" nbTooltip="Número de teléfono" nbTooltipPlacement="top" />
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <div class="form-group">
                            <label for="mail" class="label">Correo Electrónico <span nbTooltip="Campo requerido" style="color:red;">*</span></label>
                            <input type="email" nbInput fullWidth name="mail" [(ngModel)]="mPerson.mail" required="true" nbTooltip="Correo electrónico de cliente" nbTooltipPlacement="top" />
                        </div>
                    </div>
                    
                    <div class="col-sm-3">
                        <div class="form-group">
                            <button nbButton ghost status="success">
                                <nb-icon icon="save-outline"></nb-icon>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </nb-card-body>
        <nb-card-footer class="text-right">
            <button nbButton ghost status="secondary" (click)="getDivInfo()">Cerrar</button>
        </nb-card-footer>
    </nb-card>
</div>

