<nb-card class="col-md-6 mx-auto" accent="info">
    <nb-card-header>Asingar vehiculo a empresa</nb-card-header>
    <nb-card-body>
        <form #form="ngForm" (ngSubmit)="onSubmit(form)">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="idVehicle" class="label">Vehículo</label>
                        <nb-select fullWidth placeholder="Vehículo" name="idVehicle" [(ngModel)]="companyVehicleRate.idVehicle" required>
                            <nb-option [value]="vehicle.id" *ngFor="let vehicle of _vehicles">
                                {{vehicle.name}}</nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="idRate" class="label">Tarifa</label>
                        <nb-select fullWidth placeholder="Tarifas" name="idRate" [(ngModel)]="companyVehicleRate.idRate" required>
                            <nb-option [value]="_rate.id" *ngFor="let _rate of _rates">
                                {{_rate.rate}}</nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <button nbButton ghost status="success">
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>
<nb-card class="col-md-6 mx-auto" accent="info">
    <nb-card-header>Vehiculos disponibles</nb-card-header>
    <nb-card-body>
        <ng2-smart-table (delete)="deleteVehicleRate($event)" [settings]="settings" [source]="list"></ng2-smart-table>
    </nb-card-body>
</nb-card>