import { Component, OnInit } from '@angular/core';
import { Sucursal } from '../../models/sucursal';
import { SucursalService } from '../../services/sucursal.service';
import { Report } from '../../models/report';
import { ReportService } from '../../services/report.service';
import { User } from '../../models/user';
import { NbToastrService, NbGlobalPosition, 
  NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-client-report',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.scss'],
  providers: [SucursalService, ReportService]
})
export class ClientReportComponent implements OnInit {

  public sucursales: Sucursal[];
  public report: Report;
  public _report: Report[];
  public reportList = new LocalDataSource();
  public _idSucursal;
  public _idUsuario;

  constructor(
    private _sucursalService: SucursalService,
    private _reportService: ReportService,
    private toastrService: NbToastrService,
    ) { 
    this.report = new Report(3, null, null, null, null);

    let user = {} as User;
    if (localStorage.getItem('user')) {

      user = JSON.parse(localStorage.getItem('user'));
    }

    this._idUsuario = user.idCognito ? user.idCognito : null;
    this._idSucursal = user.idSucursal ? parseInt(user.idSucursal.toString()) : 0;
  }

  ngOnInit() {

    this.getAllSucursalesByCompany(this._idSucursal);
  }

  getAllSucursalesByCompany(idSucursal) {
    this._sucursalService.getAllByIdEmpresa(idSucursal).subscribe(
      response => {
        if (response.result == true) {
          this.sucursales = response.records[1];
          //        console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: false,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      codeTask: {
        title: 'Tarea',
        editable: false
      },
      petitionerName: {
        title: 'Remitente',
        editable: false
      },
      petitionerAddress: {
        title: 'Dirección Recolección',
        editable: false
      },
      receiverName: {
        title: 'Destinatario',
        editable: false
      },
      receiverAddress: {
        title: 'Dirección Entrega',
        editable: false
      },
      totalPackages: {
        title: 'Paquetes',
        editable: false
      },
      amount: {
        title: 'Monto',
        editable: false
      },
      carrierName: {
        title: 'Repartidor',
        editable: false
      },
      dateShipping: {
        title: 'Fecha Recolección',
        editable: false
      },
      dateReceiption: {
        title: 'Fecha Recepción',
        editable: false
      },
      vehicleName: {
        title: 'Vehiculo',
        editable: false
      },
      statusName: {
        title: 'Estado',
        editable: false
      }
    }
  };

  generar(){
    this.report.idUsuario = this._idUsuario;
    this.report.idSucursal = 2;
    //console.log(this.report);
    this._reportService.getClientReport(this.report).subscribe(
      response => {
        if(response.result == true){
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          console.log(response.records);
          this._report = response.records[3];
          this.reportList = new LocalDataSource(this._report);
        }else{
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

}
