<app-loading *ngIf="loading"></app-loading>
<nb-layout-column>
    <nb-card>
        <nb-card-body>
            <nb-auth-block class="card-col2">
                <div>
                    
                    <h1 style="margin-bottom: 8px!important"> INICIO DE </h1>
                    <h1 style="margin-top: 0px!important">&nbsp;SESIÓN</h1>
                </div>
                <br>
                <!-- <div class="brand-logo">
                    <img class="brand-logo-img" [src]="urlLog">
                </div> -->
                <form aria-labelledby=" title" (ngSubmit)="onSubmit(f)" #f="ngForm">
                    <!-- <button type="button" *ngIf="azureLogin" fullWidth (click)="signInWithAzureAD()" nbButton ghost
                        style="border-radius: 0.75rem!important;">
                        Ingresar con correo
                        <img width="20px" style="margin-left:0.5rem;" alt="Google"
                            src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" />
                    </button> -->
                    <!-- <button type="button" *ngIf="gmailLogin" fullWidth (click)="signInWithGoogle()" nbButton ghost
                        style="border-radius: 0.75rem!important;">
                        Ingresar con correo
                        <img width="20px" style="margin-left:0.5rem;" alt="Google"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                    </button> -->
                    <div class="form-control-group">
                        <input nbInput fullWidth class="inputText" pattern=".+@.+\..+" autofocus type="email"
                            shape="semi-round" autocomplete="off" placeholder="Correo electrónico" name="email"
                            id="email" ngModel maxlength="50" required>
                    </div>
                    <div class="form-control-group">
                        <input nbInput fullWidth class="inputText" [type]="hide ? 'password' : 'text'"
                            shape="semi-round" autocomplete="off" placeholder="Contraseña" name="password" id="password"
                            ngModel maxlength="50" required>
                        <nb-icon nbButton style="position: sticky" class="mat-icon-button-hide" (click)="hide = !hide"
                            icon="eye-outline"></nb-icon>
                    </div>
                    <button nbButton fullWidth type="submit"
                        style="border-radius: 0.75rem!important; background-color: #1378DE !important; color: #e4e9f2;">Ingresar
                        <nb-icon icon="checkmark-outline"></nb-icon>
                    </button>
                    <!-- <div class="extra-button">
                        <button type="button" *ngIf="resetPassword" fullWidth nbButton ghost responsive size="tiny"
                            class="reset-button" style=" color: #1378DE;" (click)="onClickResetPassword()">Restablecer
                            contraseña<nb-icon icon="unlock-outline"></nb-icon></button>
                        <button type="button" *ngIf="resendVCode" fullWidth nbButton ghost responsive size="tiny"
                            animation="pulse" class="reset-button" style="color: #1378DE;"
                            (click)="onResendValid()">Reenviar código
                            <nb-icon icon="email-outline"></nb-icon>
                        </button>
                    </div> -->
                </form>
                <form  aria-labelledby="title" (ngSubmit)="signIn()" >
                    <button nbButton fullWidth name="signIn"
                        style="border-radius: 0.75rem!important; background-color: #1378DE !important; color: #e4e9f2;">Registrarse
                        <nb-icon icon="people-outline"></nb-icon>
                    </button>
                </form>
                <div class="brand-logo2">
                    <!-- <img class="brand-logo-img2" src="assets/images/mTrainingWorld.png"> -->
                </div>
                <div class="brand-logo" style="margin-top: -30px;">
                    <img class="brand-logo-img" [src]="urlLog">
                </div>
            </nb-auth-block>
        </nb-card-body>
        <nb-card-footer>
            <div style="float: right;">
                <span style="line-height: 1.5rem; font-size: 0.75rem;"><a href="https://myappsoftware.com/" target="_blank"
                        style="text-decoration: none !important;"><span
                            style="color: #FF9900;">Myappsoftware</span></a><span style="color: #707070;"> powered by
                    </span></span>
                <a href="https://aws.amazon.com/partners/find/partnerdetails/?n=Criptonube%20-%20Myappsoftware&id=001E000000VKaCSIA1"
                    target="_blank">
                    <img src="assets/images/awsLogo.png" style="height: 1rem; margin-bottom: -0.4rem;">
                </a>
            </div>
        </nb-card-footer>
    </nb-card>
</nb-layout-column>