import { Component, OnInit } from '@angular/core';
import { Rate } from '../../models/rate';
import { RateService } from '../../services/rate.service';
import { NbToastrService, NbGlobalPosition, 
		     NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
  providers: [RateService]
})
export class RateComponent implements OnInit {

	public _rate: Rate;
	public rates : Rate[];
	public list = new LocalDataSource();

	constructor(
		private _rateService: RateService,
		private toastrService: NbToastrService,
		private router: Router
	) {
		this._rate = new Rate(0,'',0,'',1);
	}

	ngOnInit() {
		this.getAllRates();
	}

	getAllRates(){
	    this._rateService.getAll().subscribe(
	      response => {
	        if(response.result == true){
	          this.rates = response.records[0];
	          this.list = new LocalDataSource(this.rates);
	        }
	      },
	      error => {
	        console.log();
	      }
	    );
  	}

  	settings = {
	    hideSubHeader: false,
	    mode: "external",
	    actions: {
	      edit: false,
	      delete: false,
	      add: false,
	      columnTitle: "",
	      position: "left",
	      class: "action-colum"
	    },
	    edit: {
	      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
	    },
	    columns: {
	      rate: {
	        title: 'Tarifa',
	        editable: false
	      },
	      totalDeliveries: {
	        title: 'Envíos',
	        editable: false
	      },
	      amount: {
	        title: 'Costo',
	        editable: false
	      }
	    }
  	};

  	onSubmit(form){
//    	console.log(this._rate);
	    this._rateService.save(this._rate).subscribe(
	      response => {
	        if(response.result == true){
	          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
	          const status: NbComponentStatus = 'success';
	          this.toastrService.show(status || 'Success', response.message, { position, status });
	          form.reset();
	          this.getAllRates();
	        }else{
	          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
	          const status: NbComponentStatus = 'warning';
	          this.toastrService.show(status || 'Warning', response.message, { position, status });
	        }
	      },
	      error => {
	        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
	        const status: NbComponentStatus = 'danger';
	        this.toastrService.show(status || 'Danger', <any>error, { position, status });
	      }
	    )
  	}

}
