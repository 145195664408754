export class Person{
	constructor(
		public id : number,
		public name : string,
		public phone : string,
		public mail: string,
		public nit: string,
		public idStatus : number,
		public userCreate:number
	){}
}