import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CompanyComponent } from './pages/company/company.component';
import { UserComponent } from './pages/user/user.component';
import { SucursalesComponent } from './pages/sucursales/sucursales.component';
import { ClientComponent } from './pages/client/client.component';
import { CarrierComponent } from './pages/carrier/carrier.component';
import { PersonAddressComponent } from './pages/person-address/person-address.component';
import { TaskEntryComponent } from './pages/task-entry/task-entry.component';
import { TaskEntryComponentClient } from './pages/task-entry-client/task-entry.component';
import { TaskTracingOperatorComponent } from './pages/task-tracing-operator/task-tracing-operator.component';
import { TaskTracingClientComponent } from './pages/task-tracing-client/task-tracing-client.component';
import { TaskAssignmentComponent } from './pages/task-assignment/task-assignment.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { TaskTrackingComponent } from './pages/task-tracking/task-tracking.component';
import { AdministrationReportComponent } from './pages/administration-report/administration-report.component';
import { ClientReportComponent } from './pages/client-report/client-report.component';
import { CarrierReportComponent } from './pages/carrier-report/carrier-report.component';
import { CompanyVehicleRateComponent } from './pages/company-vehicle-rate/company-vehicle-rate.component';
import { CompanyCommissionComponent } from './pages/company-commission/company-commission.component';
import { RateComponent } from './pages/rate/rate.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { CommissionComponent } from './pages/commission/commission.component';
import { TaskCarrierComponent } from './pages/task-carrier/task-carrier.component';
import { LoginComponent } from './pages/auth/login/login.component';
import {signInComponent} from './pages/signIn/signIn.component';
import { AuthGuard } from './services/auth-guard.service';
import { ClientAddressComponent } from './pages/client-address/client-address.component';
import { CarrierTrackingComponent } from './pages/carrier-tracking/carrier-tracking.component';
import { TimeReportComponent } from './pages/time-report/time-report.component';
import { CarrierLocationComponent } from './pages/carrier-location/carrier-location.component';
import { AutoConfirmComponent } from './core/auto-confirm/auto-confirm.component';
import { UserMyProfileComponent } from './pages/auth/user-my-profile/user-my-profile.component';
import { weekDaysComponent} from './pages/weekDays/weekDays.component';
import { clienteComponent } from './pages/cliente/cliente.component';
import { TaskProgressComponent } from './pages/task-progress/task-progress.component';
const routes: Routes = [
  // Login
  {path: 'userAutoConfirm/:userEmail/:verificationCode', component: AutoConfirmComponent},
  { path: 'login', canActivate: [AuthGuard], component: LoginComponent },
  { path: 'signIn', canActivate: [AuthGuard], component: signInComponent },
  // Inicio
  { path: '', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'userProfile', canActivate: [AuthGuard], component: UserMyProfileComponent },
  // Administración
  { path: 'company', canActivate: [AuthGuard], component: CompanyComponent },
  { path: 'company-vehicle-rate/:idCompany', canActivate: [AuthGuard], component: CompanyVehicleRateComponent },
  { path: 'company-commission/:idCompany', canActivate: [AuthGuard], component: CompanyCommissionComponent },
  { path: 'user', canActivate: [AuthGuard], component: UserComponent },
  { path: 'sucursales-empresa/:idCompany', canActivate: [AuthGuard], component: SucursalesComponent },
  { path: 'sucursales-empresa/:idCompany/:idSucursal', canActivate: [AuthGuard], component: SucursalesComponent },
  { path: 'carrier', canActivate: [AuthGuard], component: CarrierComponent },
  { path: 'carrier-location', canActivate: [AuthGuard], component: CarrierLocationComponent },
  { path: 'personAddress/:idPerson', canActivate: [AuthGuard], component: PersonAddressComponent },
  { path: 'administrationReport', canActivate: [AuthGuard], component: AdministrationReportComponent },
  { path: 'timeReport', canActivate: [AuthGuard], component: TimeReportComponent },
  { path: 'rate', canActivate: [AuthGuard], component: RateComponent },
  { path: 'vehicle', canActivate: [AuthGuard], component: VehicleComponent },
  { path: 'commission', canActivate: [AuthGuard], component: CommissionComponent },
  { path: 'daysWeek', canActivate: [AuthGuard], component: weekDaysComponent},
  { path: 'cliente', canActivate: [AuthGuard], component: clienteComponent},
  // Clientes
  { path: 'addClient', canActivate: [AuthGuard], component: ClientComponent },
  { path: 'taskRequest', canActivate: [AuthGuard], component: TaskEntryComponent },
  { path: 'taskRequestClient', canActivate: [AuthGuard], component: TaskEntryComponentClient },
  { path: 'taskTracingClient', canActivate: [AuthGuard], component: TaskTracingClientComponent },
  { path: 'clientReport', canActivate: [AuthGuard], component: ClientReportComponent },
  { path: 'addAddress/:idClient', canActivate: [AuthGuard], component: ClientAddressComponent },

  // Operadores
  { path: 'taskAssignment', canActivate: [AuthGuard], component: TaskAssignmentComponent },
  { path: 'task-carrier-assign/:idTask', canActivate: [AuthGuard], component: TaskCarrierComponent },
  { path: 'taskView/:idTask', canActivate: [AuthGuard], component: TaskViewComponent },
  { path: 'taskTracingOperator', canActivate: [AuthGuard], component: TaskTracingOperatorComponent },
  // Repartidores
  { path: 'taskTracking', canActivate: [AuthGuard], component: TaskTrackingComponent },
  { path: 'carrierTracking/:idTask', canActivate: [AuthGuard], component: CarrierTrackingComponent },
  { path: 'carrierReport', canActivate: [AuthGuard], component: CarrierReportComponent },
  { path: 'taskProgress', canActivate: [AuthGuard], component: TaskProgressComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
