import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbButtonModule,
  NbMenuModule,
  NbToastrModule,
  NbSelectModule,
  NbStepperModule,
  NbListModule,
  NbActionsModule,
  NbDialogModule,
  NbProgressBarModule,
  NbTabsetModule,
  NbTooltipModule,
  NbSearchModule,
  NbCheckboxModule,
  NbRadioModule,
  NbUserModule,
  NbToggleModule,
  NbAlertModule,
  NbDatepickerModule,
  NbBadgeModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbWindowModule,
  NbAutocompleteModule
} from '@nebular/theme';
import { NbAuthModule } from '@nebular/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { LoadingComponent } from './core/loading/loading.component';
import { FooterComponent } from './core/footer/footer.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { HeaderComponent } from './core/header/header.component';
// Componentes Renatto Trinidad
import { HomeComponent } from './pages/home/home.component';
import { CompanyComponent } from './pages/company/company.component';
import { UserComponent } from './pages/user/user.component';
import { SucursalesComponent } from './pages/sucursales/sucursales.component';
import { ClientComponent } from './pages/client/client.component';
import { CarrierComponent } from './pages/carrier/carrier.component';
import { TaskEntryComponent } from './pages/task-entry/task-entry.component';
import { TaskEntryComponentClient } from './pages/task-entry-client/task-entry.component';
import { TaskTracingOperatorComponent } from './pages/task-tracing-operator/task-tracing-operator.component';
import { TaskTracingClientComponent } from './pages/task-tracing-client/task-tracing-client.component';
import { TaskAssignmentComponent } from './pages/task-assignment/task-assignment.component';
import { TaskTrackingComponent } from './pages/task-tracking/task-tracking.component';
import { TaskProgressComponent } from './pages/task-progress/task-progress.component';
import { AdministrationReportComponent } from './pages/administration-report/administration-report.component';
import { TimeReportComponent } from './pages/time-report/time-report.component';
import { ClientReportComponent } from './pages/client-report/client-report.component';
import { CarrierReportComponent } from './pages/carrier-report/carrier-report.component';
import { PersonAddressComponent } from './pages/person-address/person-address.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { CompanyVehicleRateComponent } from './pages/company-vehicle-rate/company-vehicle-rate.component';
import { CompanyCommissionComponent } from './pages/company-commission/company-commission.component';
import { RateComponent } from './pages/rate/rate.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { CommissionComponent } from './pages/commission/commission.component';
import { TaskCarrierComponent } from './pages/task-carrier/task-carrier.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { signInComponent } from './pages/signIn/signIn.component';
import { UserChangePasswordComponent } from './pages/auth/user-change-password/user-change-password.component';
import { UserMyProfileComponent } from './pages/auth/user-my-profile/user-my-profile.component';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth-guard.service';
import { ClientAddressComponent } from './pages/client-address/client-address.component';
import { CarrierTrackingComponent } from './pages/carrier-tracking/carrier-tracking.component';
import {weekDaysComponent} from './pages/weekDays/weekDays.component';
import {clienteComponent} from './pages/cliente/cliente.component';
import { SwipeAngularListModule } from 'swipe-angular-list'; // <------ IMPORT

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { CarrierLocationComponent } from './pages/carrier-location/carrier-location.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TermsandconditionsComponent } from './pages/termsandconditions/termsandconditions.component';
import { AutoConfirmComponent } from './core/auto-confirm/auto-confirm.component';
import { ConfirmWindowComponent } from './core/tools/confirm-window/confirm-window.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LoadingComponent,
    LoginComponent,
    signInComponent,
    UserChangePasswordComponent,
    UserMyProfileComponent,
    // Componentes Renatto Trinidad
    HomeComponent,
    CompanyComponent,
    UserComponent,
    SucursalesComponent,
    ClientComponent,
    CarrierComponent,
    TaskEntryComponent,
    TaskEntryComponentClient,
    TaskTracingOperatorComponent,
    TaskTracingClientComponent,
    TaskAssignmentComponent,
    TaskTrackingComponent,
    AdministrationReportComponent,
    TimeReportComponent,
    ClientReportComponent,
    CarrierReportComponent,
    PersonAddressComponent,
    TaskViewComponent,
    CompanyVehicleRateComponent,
    CompanyCommissionComponent,
    RateComponent,
    VehicleComponent,
    CommissionComponent,
    TaskCarrierComponent,
    ClientAddressComponent,
    CarrierTrackingComponent,
    CarrierLocationComponent,
    // Componentes Javier Cano
    AutoConfirmComponent,
    TermsandconditionsComponent,
    //Componentes Milton Rodriguez
    clienteComponent,
    weekDaysComponent,
    TaskProgressComponent,
    ConfirmWindowComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbCardModule,
    NbIconModule,
    NbAuthModule,
    NbInputModule,
    FormsModule,
    RouterModule,
    NbButtonModule,
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSelectModule,
    NbStepperModule,
    NbListModule,
    NgxMaterialTimepickerModule,
    NbActionsModule,
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbProgressBarModule,
    ReactiveFormsModule,
    NbTabsetModule,
    NbTooltipModule,
    Ng2SmartTableModule,
    NbSearchModule,
    NbCheckboxModule,
    NbRadioModule,
    NbUserModule,
    NbTabsetModule,
    NbToggleModule,
    NbAlertModule,
    NbBadgeModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbDateFnsDateModule,
    NbAutocompleteModule,
    SwipeAngularListModule,
    AngularFireDatabaseModule,
      AngularFireAuthModule,
      AngularFireMessagingModule,
      AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    UserService,
    AuthGuard,
    MessagingService,
    AsyncPipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TermsandconditionsComponent
  ],
})
export class AppModule { }
