import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

interface MarcadorColor {
  color: string;
  marker?: mapboxgl.Marker;
  centro?: [number, number];
}

@Component({
  selector: 'app-task-tracing-operator',
  templateUrl: './task-tracing-operator.component.html',
  styleUrls: ['./task-tracing-operator.component.scss']
})
export class TaskTracingOperatorComponent implements AfterViewInit {

  @Input() latitude: any;
  @Input() longitude: any;

  @ViewChild('map', {static: false}) divMap!: ElementRef;
  map!: mapboxgl.Map;
  zoomLevel: number = 10;
  center: [number, number] = [-90.49322418747106, 14.580160123137151];

  // Arreglo de marcadores
  marcadores: MarcadorColor[] = [];

  constructor() { }

  ngAfterViewInit() {

    this.map = new mapboxgl.Map({
      container: this.divMap.nativeElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: 15,
    });

    //this.leerLocalStorage();

  }

  getMarker(marker: mapboxgl.Marker) {
    this.map.flyTo({
      center: marker.getLngLat()
    });
  }

  addMarker() {

    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));

    if (this.marcadores.length < 1) {
      const nuevoMarcador = new mapboxgl.Marker({
        draggable: true,
        color
      })
        .setLngLat(this.center)
        .addTo(this.map);

      this.marcadores.push({
        color,
        marker: nuevoMarcador
      });

      this.guardarLocalStorage();

      nuevoMarcador.on('dragend', () => {
        this.guardarLocalStorage();
      });

    }

  }

  guardarLocalStorage() {

    const lngLatArr: MarcadorColor[] = [];

    this.marcadores.forEach(m => {

      const color = m.color;
      const { lng, lat } = m.marker!.getLngLat();

      lngLatArr.push({
        color: color,
        centro: [lng, lat]
      });

    })

    localStorage.setItem('marcadores', JSON.stringify(lngLatArr));

  }

  leerLocalStorage(lng, lat) {

    // if(!localStorage.getItem('marcadores')){
    //   return;
    // }

    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16)); 
    const centro: [number, number] = [lng, lat];

    const newMarker = new mapboxgl.Marker({
      color: color,
      draggable: true
    })
      .setLngLat(centro)
      .addTo(this.map);

    this.marcadores.push({
      marker: newMarker,
      color: color
    });

    newMarker.on('dragend', () => {
      this.guardarLocalStorage();
    });

  }

  borrarMarcador(i: number) {

    this.marcadores[i].marker.remove();
    this.marcadores.splice(i, 1);
    this.guardarLocalStorage();
  }

}