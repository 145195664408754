import { environment } from './environments/environment';


const awsmobile = {
    aws_project_region: environment.region,
    aws_cognito_identity_pool_id: environment.identityPoolId,
    aws_cognito_region: environment.region,
    aws_user_pools_id: environment.userPoolId,
    aws_user_pools_web_client_id: environment.clientId,
    oauth: {
        domain: environment.cognitoDomain,
        scope: [
            'phone',
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: environment.cognitoRedirectSignIn,
        redirectSignOut: environment.cognitoRedirectSignOut,
        responseType: environment.cognitoResponseType
    },
    federationTarget: 'COGNITO_USER_POOLS'
};


export default awsmobile;
