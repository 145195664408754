<nb-card class="col-md-10 mx-auto" accent="info">
    <nb-card-header>Listado de usuarios</nb-card-header>
    <nb-card-body>
        <ng2-smart-table (create)="open(dialog)" (edit)="editUser(dialog, $event)" (delete)="deleteUser($event)" [settings]="settings" [source]="users">

        </ng2-smart-table>
    </nb-card-body>
</nb-card>

<!-- Ingreso de usuarios modal -->

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="col-6 mx-auto" accent="info">
        <nb-card-header>Ingreso de usuarios</nb-card-header>
        <nb-card-body>
            <nb-card-body>
                <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm)">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="idSucursal" class="label">Nombre sucursal</label>
                                <nb-select fullWidth placeholder="Sucursal" name="idSucursal" [(ngModel)]="user.idSucursal" required>
                                    <nb-option [value]="sucursal.id" *ngFor="let sucursal of sucursales">
                                        {{sucursal.sucursalName}}</nb-option>
                                </nb-select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="userName" class="label">Nombre Completo</label>
                                <input type="text" nbInput fullWidth name="userName" autocomplete="false" [(ngModel)]="user.name" required nbTooltip="Nombre de usuario" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="userMail" class="label">Correo del usuario</label>
                                <input type="text" nbInput fullWidth name="userMail" autocomplete="false" [(ngModel)]="user.user" required nbTooltip="Correo de usuario" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                              <label for="password" class="label">Contraseña</label>
                              <nb-alert name="password" status="success">Contraseña automatica</nb-alert>
                            </div>
                            <!-- <div class="form-group">
                                <label for="password" class="label">Contraseña</label>
                                <input type="password" nbInput fullWidth name="password" required [(ngModel)]="user.password" nbTooltip="La política de contraseña es: 8 caracteres mínimo, 1 Letra mayúscula al menos, 1 letra minúscula al menos, 1 caracter especial al menos, 1 número al menos"
                                    nbTooltipPlacement="top" />
                            </div> -->
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="idRol" class="label">Rol</label>
                                <nb-select fullWidth placeholder="Rol de usuario" name="idRol" required [(ngModel)]="user.idRol">
                                    <nb-option [value]="rol.id" *ngFor="let rol of roles">
                                        {{rol.name}}</nb-option>
                                </nb-select>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                    <label for="userNumberPhone" class="label">Número de Telefono</label>
                                    <input type="text" nbInput fullWidth id="userNumberPhone" name="userNumberPhone" [(ngModel)]="user.userNumberPhone"
                                        placeholder="Número de Telefono" maxlength="80" required>
                                
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                
                                    <label for="userBirthDate" class="label">Fecha de Nacimiento</label>
                                    <input  [nbDatepicker]="datepicker" type="text" nbInput fullWidth [(ngModel)]="user.userBirthDate" name="userBirthDate" 
                                    id="userBirthDate" placeholder="Fecha de Nacimiento" maxlength="80">
                                    <nb-datepicker #datepicker format="dd/MM/yyyy"></nb-datepicker>
                                
                            </div>
                        </div>
                        <br>
                        <div class="col-sm-4">
                            <div class="form-group">
                                
                                    <label for="userNit" class="label">NIT (Opcional)</label>
                                    <input type="text" nbInput fullWidth id="userNit" name="userNit" [(ngModel)]="user.userNit"
                                        placeholder="NIT (Opcional)" maxlength="80" required>
                               
                            </div>
                        </div>
                        <!-- <div class="col-sm-4">
                          <div class="form-group">
                              <label for="discount" class="label">Descuento</label>
                              <input type="text" nbInput fullWidth name="discount" required />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                              <label for="discount" class="label">Costo especial</label>
                              <input type="text" nbInput fullWidth name="discount" required />
                          </div>
                        </div> -->
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <button nbButton ghost status="success">
                            <nb-icon icon="save-outline"></nb-icon>
                            Guardar
                        </button>
                        </div>
                    </div>
                </form>
            </nb-card-body>
        </nb-card-body>
        <nb-card-footer class="text-right">
            <button nbButton ghost status="secondary" (click)="ref.close()">Cerrar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>
