import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { UserToken } from '../models/userToken';
import { UserService } from './user.service';

@Injectable()
export class MessagingService {
    public _idCognito;
    public _name;
    public userToken = {} as UserToken;
    public headers = new HttpHeaders();

    currentMessage = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging, private userService: UserService, private _http: HttpClient) {

        let user = {} as User;

        if (localStorage.getItem('user')) {
          user = JSON.parse(localStorage.getItem('user'));
        }
    
        this._idCognito = user.idCognito ? user.idCognito : null;
        this._name = user.name ? user.name : null;

        this.headers.append("Content-Type", "application/json");
        this.headers.append("Content-Type", "application/json");
        
        this.angularFireMessaging.messages.subscribe(
            (_messaging: any) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }

    requestPermission(longitude, latitude) {
        this.userToken.userCognitoId = this._idCognito;
        this.userToken.name = this._name;
        this.userToken.longitude = longitude;
        this.userToken.latitude = latitude;
        this.userToken.tokenPush = '';

        this.userService.saveUserTokenPush(this.userToken).subscribe(
            response => {
                console.log(response);
              },
              error => {
                console.error('Unable to get permission to notify.', error);
              }
        )


        //console.log(this.userToken);
        
        // this.angularFireMessaging.requestToken.subscribe(
        //     (token) => {
        //         this.userToken.tokenPush = token;
        //         //console.log(token);
        //         //console.log(this.userToken);
        //         this.userService.saveUserTokenPush(this.userToken).subscribe(
        //             response => {
        //                 //console.log(response);
        //               },
        //               error => {
        //                 console.error('Unable to get permission to notify.', error);
        //               }
        //         )
        //     },
        //     (err) => {
        //         console.error('Unable to get permission to notify.', err);
        //     }
        // );
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
            })
    }

    sendMessage(message){
		let headers = new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAA-pt3eCs:APA91bEFPXXM9KPJWq1fl6UxsOmT4cuypCw_lpP1Ktl-Dihf8ir7W5PGoNTbW23iKRhbNQ1qo782Zijftzi4KkZxAD02-8fpeJ67rlzTgu9Rv60jNRjVliw0aTbIpaX_8_F8Sw0TxF_M'
            }
        );

		return this._http.post('https://fcm.googleapis.com/fcm/send', message, { headers: headers });
	}
}