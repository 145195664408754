<nb-card accent="info">
  <nb-card-header>Asignación de repartidor</nb-card-header>
  <nb-card-body>
  	<div class="row">
    	<div class="col-sm-3">
            <div class="form-group">
                <label for="sucursal" class="label">Remitente: </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    	<div class="col-sm-2">
            <div class="form-group">
                <label for="sucursal" class="label label-input">Pais </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    	<div class="col-sm-2">
            <div class="form-group">
                <label for="sucursal" class="label label-input">Departamento </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    	<div class="col-sm-4">
            <div class="form-group">
                <label for="sucursal" class="label label-input">Dirección: </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    </div>
    <div class="row">
    	<div class="col-sm-3">
            <div class="form-group">
                <label for="sucursal" class="label">Destinatario: </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    	<div class="col-sm-2">
            <div class="form-group">
                <label for="sucursal" class="label label-input">Pais </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    	<div class="col-sm-2">
            <div class="form-group">
                <label for="sucursal" class="label label-input">Departamento </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    	<div class="col-sm-4">
            <div class="form-group">
                <label for="sucursal" class="label label-input">Dirección: </label>
                <input type="number" nbInput fullWidth
                       nbTooltip="Sucursal solicitante" nbTooltipPlacement="top" disabled />
            </div>
    	</div>
    </div>
    <div class="row">
    	<div class="col-sm-3">
            <label for="idCarrier" class="label">Repartidores: </label>
            <nb-select fullWidth id="idCarrier" name="idCarrier" [(ngModel)]="carrier.id">
            <nb-option [value]="carrier.id" *ngFor="let carrier of carriers">
                {{carrier.name}}</nb-option>
            </nb-select>
    	</div>
        <div class="col-sm-2">
            <div class="form-group">
                <label for="button" class="label label-button">boton</label>
                <button nbButton ghost status="success">
                    <nb-icon icon="save-outline"></nb-icon>
                    Asignar
                </button>
            </div>
        </div>
    </div>
  </nb-card-body>
</nb-card>
