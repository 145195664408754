import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../../models/vehicle';
import { VehicleService } from '../../services/vehicle.service';
import {
	NbToastrService, NbGlobalPosition,
	NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-vehicle',
	templateUrl: './vehicle.component.html',
	styleUrls: ['./vehicle.component.scss'],
	providers: [VehicleService]
})

export class VehicleComponent implements OnInit {

	public _vehicle: Vehicle;
	public vehicles: Vehicle[];
	public list = new LocalDataSource();

	constructor(
		private _vehicleService: VehicleService,
		private toastrService: NbToastrService,
		private router: Router
	) {
		this._vehicle = new Vehicle(0, '', 1);
	}

	ngOnInit() {
		this.getAllVehicles();
	}

	getAllVehicles() {
		this._vehicleService.getAll().subscribe(
			response => {
				if (response.result == true) {
					this.vehicles = response.records[0];
					this.list = new LocalDataSource(this.vehicles);
				}
			},
			error => {
				console.log();
			}
		);
	}

	settings = {
		hideSubHeader: false,
		mode: "external",
		actions: {
			edit: false,
			delete: false,
			add: false,
			columnTitle: "",
			position: "left",
			class: "action-colum"
		},
		edit: {
			editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
		},
		columns: {
			id: {
				title: '#',
				editable: false
			},
			name: {
				title: 'Vehículo',
				editable: false
			}
		}
	};

	onSubmit(form) {
		console.log(this._vehicle);
 		this._vehicleService.save(this._vehicle).subscribe(
			response => {
				if (response.result == true) {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'success';
					this.toastrService.show(status || 'Success', response.message, { position, status });
					form.reset();
					this.getAllVehicles();
				} else {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'warning';
					this.toastrService.show(status || 'Warning', response.message, { position, status });
				}
			},
			error => {
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				this.toastrService.show(status || 'Danger', <any>error, { position, status });
			}
		)
	}

}
