<app-loading *ngIf="loading"></app-loading>
<nb-card>
    <nb-card-body>
        <div class="perfil__box">
                <div class="row">
                    <div class="col-md-6">
                        <h4 style="font-family: 'Raleway', sans-serif!important; font-size: 2.6rem!important;"> Mi  Perfil</h4>
                    </div>
                    <div class="col-md-6 ">
                        <div class="form-group">
                            <!-- <label for="picture" class="label">Avatar</label> -->
                            <!-- <nb-user size="large" name="" title="" (picture)="this.image" badgeStatus="success"
                            badgePosition="bottom right" onlyPicture [(ngModel)]="image"></nb-user> -->
                            <img [src]="image === '' ? 'assets/images/avatar/0.png' : image"
                                style="cursor: pointer; margin-left: 1rem;" class="profileImg">
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                    <nb-user *ngIf="this.user.userAvatar == 5" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/5.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 5" size="large" name="" title="" picture="assets/images/avatar/5.png" onlyPicture (click)="changeAvatar(5)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 6" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/6.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 6" size="large" name="" title="" picture="assets/images/avatar/6.png" onlyPicture  (click)="changeAvatar(6)" style="cursor: pointer;"></nb-user>
                    
                    <nb-user *ngIf="this.user.userAvatar == 7" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/7.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 7" size="large" name="" title="" picture="assets/images/avatar/7.png" onlyPicture  (click)="changeAvatar(7)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 8" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/8.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 8" size="large" name="" title="" picture="assets/images/avatar/8.png" onlyPicture  (click)="changeAvatar(8)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 9" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/9.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 9" size="large" name="" title="" picture="assets/images/avatar/9.png" onlyPicture  (click)="changeAvatar(9)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 10" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/10.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 10" size="large" name="" title="" picture="assets/images/avatar/10.png" onlyPicture  (click)="changeAvatar(10)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 11" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/11.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 11" size="large" name="" title="" picture="assets/images/avatar/11.png" onlyPicture  (click)="changeAvatar(11)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 12" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/12.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 12" size="large" name="" title="" picture="assets/images/avatar/12.png" onlyPicture  (click)="changeAvatar(12)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 13" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/13.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 13" size="large" name="" title="" picture="assets/images/avatar/13.png" onlyPicture  (click)="changeAvatar(13)" style="cursor: pointer;"></nb-user>

                    <nb-user *ngIf="this.user.userAvatar == 14" size="large" name="" title="" badgeText="Actual" picture="assets/images/avatar/14.png" badgeStatus="success"
                    badgePosition="bottom right" onlyPicture></nb-user>
                    <nb-user *ngIf="this.user.userAvatar != 14" size="large" name="" title="" picture="assets/images/avatar/14.png" onlyPicture  (click)="changeAvatar(14)" style="cursor: pointer;"></nb-user>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="email" class="label">Correo Electrónico</label>
                            <input type="text" nbInput fullWidth id="email" name="email" [(ngModel)]="user.user"
                                disabled="true" placeholder="Correo Electrónico" maxlength="80" required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="name" class="label">Nombre</label>
                            <input type="text" nbInput fullWidth id="name" name="name" [(ngModel)]="user.name"
                                placeholder="Nombre" maxlength="80" required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="userNumberPhone" class="label">Número de Telefono</label>
                            <input type="text" nbInput fullWidth id="userNumberPhone" name="userNumberPhone" [(ngModel)]="user.userNumberPhone"
                                placeholder="Número de Telefono" maxlength="80" required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-control-group">
                            <label for="userBirthDate" class="label">Fecha de Nacimiento</label>
                            <input  [nbDatepicker]="datepicker" type="text" nbInput fullWidth [(ngModel)]="user.userBirthDate" name="userBirthDate" 
                            id="userBirthDate" placeholder="Fecha de Nacimiento" maxlength="80">
                            <nb-datepicker #datepicker format="dd/MM/yyyy"></nb-datepicker>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="userNit" class="label">NIT (Opcional)</label>
                            <input type="text" nbInput fullWidth id="userNit" name="userNit" [(ngModel)]="user.userNit"
                                placeholder="NIT (Opcional)" maxlength="80" required>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="userRole" class="label">Rol</label>
                            <input type="text" nbInput fullWidth id="userRole" name="userRole" [(ngModel)]="userRoleShow"
                                disabled="true" placeholder="Rol" maxlength="80" required>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="theme" class="label">Tema</label>
                            <nb-select fullWidth placeholder="Tema" (selectedChange)="changeTheme($event)" id="theme"
                                name="theme" [(ngModel)]="user.userTheme" required maxlength="0">
                                <nb-option *ngFor="let theme of themes" [value]="theme.value">
                                    {{ theme.name }}</nb-option>
                            </nb-select>
                        </div>
                    </div>
                </div> -->
                <form  aria-labelledby="title" (ngSubmit)="changeUser()" >
                    <button nbButton fullWidth name="changeUser"
                        style="border-radius: 0.75rem!important; background-color: #1378DE !important; color: #e4e9f2;">Guardar Cambios
                        <nb-icon icon="people-outline"></nb-icon>
                    </button>
                </form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <button nbButton (click)="changePassword()" ghost style="margin-top:1rem;font-family: 'Raleway', sans-serif!important;">
                                Cambiar Contraseña
                            </button>
                            
                        </div>
                       
                        
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <button nbButton nbTooltip="Cancelar" (click)="cancel()" ghost style="color: #C81016 !important; margin: 0 1rem 0 20px;margin-top:1rem;font-family: 'Raleway', sans-serif!important;">
                                Cancelar
                            </button>
                            
                        </div>
                    </div>
                    
                </div>
                <!-- <button nbButton (click)="saveUser()" nbTooltip="Guardar" ghost style="color: #0A7E12 !important;">
                    Guardar
                </button> -->
                
        </div>
    </nb-card-body>
</nb-card>