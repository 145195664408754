<nb-card class="col-md-12 mx-auto" accent="info">
    <nb-card-header>Reporte de Tiempos</nb-card-header>
    <nb-card-body>
        <div class="row">
            <!-- <div class="col-sm-6">
                <div class="form-group">
                    <label for="idSucursal" class="label">Nombre sucursal</label>
                    <nb-select fullWidth placeholder="Sucursal" name="idSucursal" [(ngModel)]="report.idSucursal" required>
                        <nb-option [value]="sucursal.id" *ngFor="let sucursal of sucursales">
                            {{sucursal.sucursalName}}</nb-option>
                    </nb-select>
                </div>
            </div> -->
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="dateShipping" class="label">Fecha Inicial:</label>
                    <input [nbDatepicker]="datepicker_1" type="text" nbInput fullWidth required="true" name="dateIni" nbTooltip="Fecha de envío" nbTooltipPlacement="top" [(ngModel)]="report.dateIni" required>
                    <nb-datepicker #datepicker_1 format="yyyy/MM/dd"></nb-datepicker>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="dateShipping" class="label">Fecha Final:</label>
                    <input [nbDatepicker]="datepicker_2" type="text" nbInput fullWidth required="true" name="dateFin" nbTooltip="Fecha de envío" nbTooltipPlacement="top" [(ngModel)]="report.dateFin" required>
                    <nb-datepicker #datepicker_2 format="yyyy/MM/dd"></nb-datepicker>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="button" class="label label-button">Generar Reporte</label>
                    <button nbButton ghost status="success" (click)="generar()">
                        <nb-icon icon="save-outline"></nb-icon>
                        Generar
                    </button>
                </div>
            </div>
            <div class="col-sm-2" *ngIf="export">
                <div class="form-group">
                    <nb-icon icon="cloud-download-outline" style="font-size: 2rem; color:#0A7E12;" nbButton ghost nbTooltip="Exportar a excel" (click)="downloadToExcel()">
                    </nb-icon>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<nb-card class="col-md-12 mx-auto" accent="info">
    <nb-card-header>Información Generada</nb-card-header>
    <nb-card-body>
        <ng2-smart-table [settings]="settings" [source]="reportList"></ng2-smart-table>
    </nb-card-body>
</nb-card>