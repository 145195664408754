import { Component, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { Commission } from '../../models/commission';
import { CommissionService } from '../../services/commission.service';
import { CompanyCommission } from '../../models/companyCommission';
import {
	NbToastrService, NbGlobalPosition,
	NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
	selector: 'app-company-commission',
	templateUrl: './company-commission.component.html',
	styleUrls: ['./company-commission.component.scss'],
	providers: [CompanyService, CommissionService]
})
export class CompanyCommissionComponent implements OnInit {

	public company: Company;
	public commissions: Commission[];
	public companyCommission: CompanyCommission;
	public companyCommissions: CompanyCommission[];
	public list = new LocalDataSource();

	constructor(
		private _companyService: CompanyService,
		private _commissionService: CommissionService,
		private toastrService: NbToastrService,
		private _router: Router,
		private _route: ActivatedRoute
	) {
		this.company = new Company(0, null, null, null, 1);
		this.companyCommission = new CompanyCommission(0, 0, 0, 1, null);
	}

	ngOnInit() {
		this._route.paramMap.subscribe(async (params: any) => {
			const idCompany = params.params.idCompany;

			if (idCompany !== undefined) {

				this.company.id = idCompany;
				this.companyCommission.idCompany = idCompany;
				this.getCompanyById(idCompany);
				this.getCommissionById(idCompany);
			}
		});
		this.getAllCommissions();
	}

	settings = {
		hideSubHeader: false,
		mode: "external",
		actions: {
			edit: false,
			delete: true,
			add: false,
			columnTitle: "",
			position: "left",
			class: "action-colum"
		},
		delete: {
			deleteButtonContent: "<i class='fa fa-minus-square-o' title='Eliminar'></i>"
		},
		columns: {
			commission: {
				title: 'Comisión',
				editable: false
			}
		}
	};

	getCommissionById(idCompany) {
		this._companyService.getCommission(idCompany).subscribe(
			response => {
				if (response.result == true) {
					this.companyCommissions = response.records[1];
					this.list = new LocalDataSource(this.companyCommissions);
					//console.log(response);
				}
			},
			error => {
				console.log();
			}
		);
	}

	getCompanyById(id) {
		this._companyService.getById(id).subscribe(
			response => {
				if (response.result == true) {
					this.company = response.records[0];
				}
			},
			error => {
				console.log();
			}
		);
	}

	getAllCommissions() {
		this._commissionService.getAll().subscribe(
			response => {
				if (response.result == true) {
					this.commissions = response.records[0];
				}
			},
			error => {
				console.log();
			}
		);
	}

	onSubmit(form) {
		const idCompany = this.companyCommission.idCompany;
		this._companyService.saveCommission(this.companyCommission).subscribe(
			response => {
				if (response.result == true) {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'success';
					this.toastrService.show(status || 'Success', response.message, { position, status });
					form.reset();
					this.getAllCommissions();
					this.getCompanyById(idCompany);
					this.getCommissionById(idCompany);
				} else {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'warning';
					this.toastrService.show(status || 'Warning', response.message, { position, status });
				}
			},
			error => {
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				this.toastrService.show(status || 'Danger', <any>error, { position, status });
			}
		)
	}

	deleteCommission(event) {

		this.companyCommission.id = 1;
		this.companyCommission.commission = event.data.commission;
		this.companyCommission.status = 2;

		//console.log(this.companyCommission);
		const idCompany = this.companyCommission.idCompany;
		this._companyService.saveCommission(this.companyCommission).subscribe(
			response => {
				if (response.result == true) {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'success';
					this.toastrService.show(status || 'Success', response.message, { position, status });
					this.getAllCommissions();
					this.getCompanyById(idCompany);
					this.getCommissionById(idCompany);
				} else {
					const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
					const status: NbComponentStatus = 'warning';
					this.toastrService.show(status || 'Warning', response.message, { position, status });
				}
			},
			error => {
				const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
				const status: NbComponentStatus = 'danger';
				this.toastrService.show(status || 'Danger', <any>error, { position, status });
			}
		)
	}

}
