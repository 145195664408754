import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Sucursal } from '../models/sucursal';
import { Global } from './global';

@Injectable()
export class SucursalService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	save(sucursal): Observable<any>{
		let params = JSON.stringify(sucursal);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-sucursal', params, { headers: headers });

	}

	getAll(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-sucursal', {headers: headers});
	}

	getSucursalById(idSucursal): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-sucursal/one/'+idSucursal, {headers: headers});
	}

	getAllByIdEmpresa(idCompany): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-sucursalCompanyGetAll/one/'+idCompany, {headers: headers});
	}

	getAllByIdEmpresa2(idCompany): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-companySucursalGetAll/one/'+idCompany, {headers: headers});
	}
}