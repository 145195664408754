import { Component, OnInit, TemplateRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { User } from '../../models/user';
import { UserToken } from '../../models/userToken';
import { Task } from '../../models/task';
import { TaskService } from '../../services/task.service';
import { UserService } from '../../services/user.service';
import { Sucursal } from '../../models/sucursal';
import { SucursalService } from '../../services/sucursal.service';
import { Person } from '../../models/person';
import { ClientService } from '../../services/client.service';
import { Address } from '../../models/address';
import { AddressService } from '../../services/address.service';
import { CompanyService } from '../../services/company.service';
import { CompanyVehicleRate } from '../../models/companyVehicleRate';
import { PersonAddress } from '../../models/person-address';
import { PersonAddressService } from '../../services/person-address.service';
import { NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition, NbDialogRef, NbStepperComponent } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, startWith } from 'rxjs/operators';
import * as mapboxgl from 'mapbox-gl';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { MessagingService } from '../../services/messaging.service';
import { TrackingStatusService } from 'src/app/services/trackingStatus.service';
import { TaskTracking } from 'src/app/models/taskTracking';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DaysWeekService } from 'src/app/services/daysWeek.service';
interface MarcadorColor {
  color: string;
  marker?: mapboxgl.Marker;
  centro?: [number, number];
}

@Component({
  selector: 'app-task-entry',
  templateUrl: './task-entry.component.html',
  styleUrls: ['./task-entry.component.scss'],
  providers: [TaskService, CompanyService,
    SucursalService, ClientService, PersonAddressService, AddressService, TrackingStatusService, DaysWeekService]
})
export class TaskEntryComponent implements AfterViewInit, OnInit {

  public task: Task;
  public tasks: Task[];
  public userToken: UserToken[];
  public person: Person;
  public person2: Person;
  public personAddress: any[];
  public personAddress2: any[];
  public _personAddress: any;
  public _personAddress2: any;
  public tasksList = new LocalDataSource();
  public sucursales: Sucursal[];
  public clients: any[];
  public clients2: any[];
  public companyVehicle: CompanyVehicleRate;
  public role: number;
  public _idSucursal;
  public _namePetitioner;
  public _nameReceiver;
  addTask;
  filter1;
  loading = false;
  public mPerson: Person;
  public mAddress: Address;
  public mPersonAddress: PersonAddress;
  public _mAddress: Address[];
  isStep1Complete = false;
  public mDperson: Person;
  public mDaddress: Address;
  public mDpersonAddress: PersonAddress;
  public _mDaddress: Address[];
  public user1 = new Array();
  public user = {} as User;
  public userDB = {} as User;
  public flgInfo;
  public flgMapa;
  public flgCliente;
  public flgDireccion;
  public tiempo;

  linearMode1 = true;
  linearMode2 = true;
  public horario;
  public min = '08:00 AM';
  holidayDate;
  public max = '05:00 PM';
  client1Name="";
  client1AdressName="";
  client2Name = "";
  client2AdressName="";
  /* Autocomplete */
  //options: any[];
  filteredControlOptions$: Observable<string[]>;
  filteredNgModelOptions$: Observable<string[]>;
  inputFormControl: FormControl;

  filteredControlOptions2$: Observable<string[]>;
  filteredNgModelOptions2$: Observable<string[]>;
  inputFormControl2: FormControl;
  stepperIndex1 = 0;
  mapa=false;
  clientSelect = true;


  /* Integracion mapboxgl */
  public _latitude;
  public _longitude;

  public optionSelected = 0;

  @ViewChild('map', { static: false }) divMap!: ElementRef;
  map!: mapboxgl.Map;
  zoomLevel: number = 10;
  center: [number, number] = [-90.49322418747106, 14.580160123137151];

  // Arreglo de marcadores
  marcadores: MarcadorColor[] = [];

  constructor(
    private _taskService: TaskService,
    private _userService: UserService,
    private _sucursalService: SucursalService,
    private _clientService: ClientService,
    private _companyService: CompanyService,
    private _personAddressService: PersonAddressService,
    private toastrService: NbToastrService,
    private router: Router,
    private _rolService: CompanyService,
    private _route: ActivatedRoute,
    private dialogService: NbDialogService,
    private _addressService: AddressService,
    private _router: Router,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private messagingService: MessagingService,
    private _trackingStatusService: TrackingStatusService,
    private daysService: DaysWeekService
  ) {

    this.task = new Task(0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1,
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,0);



    this.person = new Person(0, null, null, null, null, 1, 0);
    this.person2 = new Person(0, null, null, null, null, 1, 0);
    this._personAddress = new PersonAddress(0, null, null, null, null, null, 1, 0, 0, "", "");
    this._personAddress2 = new PersonAddress(0, null, null, null, null, null, 1, 0, 0, "", "");

    this.mPerson = new Person(0, null, null, null, null, 1, 0);
    this.mAddress = new Address(0, null, 1);
    this.mPersonAddress = new PersonAddress(0, null, null, null, null, null, 1, 0, 0, "", "");

    this.mDperson = new Person(0, null, null, null, null, 1, 0);
    this.mDaddress = new Address(0, null, 1);
    this.mDpersonAddress = new PersonAddress(0, null, null, null, null, null, 1, 0, 0, "", "");




  }

  ngOnChanges() {

    if (this.task.idVehicle != null && this.task.dateShipping != null
      && this.task.hourShipping != null && this.task.idPetitioner != null) {
      this.linearMode1 = false;
    }

  }

  ngAfterViewInit() {

    this.map = new mapboxgl.Map({
      container: this.divMap.nativeElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: 15,
    });

    //console.log(this.divMap.nativeElement);

  }

  ngOnchan

  addressType = [
    {
      value: 1,
      name: 'Casa',
    },
    {
      value: 2,
      name: 'Oficina',
    },
  ];
  packagesType = [
    {
      value: 1,
      name: 'Ropa',
    },
    {
      value: 2,
      name: 'Documentos',
    },
    {
      value: 3,
      name: 'Comida',
    },
    {
      value: 4,
      name: 'Botellas de licor',
    },
    {
      value: 5,
      name: 'Chocolates',
    },
    {
      value: 6,
      name: 'Pastel',
    },
    {
      value: 7,
      name: 'Cheques',
    },
    {
      value: 8,
      name: 'Documentos',
    },
    {
      value: 9,
      name: 'Recipientes de vidrio',
    },
    {
      value: 10,
      name: 'Regalos comestibles',
    },
    {
      value: 11,
      name: 'Producto perecedero',
    },
    {
      value: 12,
      name: 'Flores',
    },
    {
      value: 13,
      name: 'Arreglos de globos',
    },
    {
      value: 14,
      name: 'Material de costura',
    },
    {
      value: 15,
      name: 'Medicina',
    },
    {
      value: 16,
      name: 'Equipo de computo',
    },
    {
      value: 17,
      name: 'Útiles escolares',
    },
    {
      value: 18,
      name: 'Material de empaque',
    },
    {
      value: 18,
      name: 'Otros...',
    },

  ];
  municipality = [
    {
      value: 1,
      name: 'Ciudad capital ',
    },
    {
      value: 2,
      name: 'Mixco',
    },
    {
      value: 3,
      name: 'Villa nueva',
    },
    {
      value: 4,
      name: 'San José Pinula',
    },
    {
      value: 5,
      name: 'Fraijanes',
    },
    {
      value: 6,
      name: 'San Miguel Petapa',
    },
    {
      value: 7,
      name: 'Boca del monte',
    },
    {
      value: 8,
      name: 'Villa canales',
    },
    {
      value: 9,
      name: 'Chinautla',
    },
  ];
  zones = [
    {
      value: 100,
      name: 'No Aplica',
    },
    {
      value: 1,
      name: 'Zona 1',
    },
    {
      value: 2,
      name: 'Zona 2',
    },
    {
      value: 3,
      name: 'Zona 3',
    },
    {
      value: 4,
      name: 'Zona 4',
    },
    {
      value: 5,
      name: 'Zona 5',
    },
    {
      value: 6,
      name: 'Zona 6',
    },
    {
      value: 7,
      name: 'Zona 7',
    },
    {
      value: 8,
      name: 'Zona 8',
    },
    {
      value: 9,
      name: 'Zona 9',
    },
    {
      value: 10,
      name: 'Zona 10',
    },
    {
      value: 11,
      name: 'Zona 11',
    },
    {
      value: 12,
      name: 'Zona 12',
    },
    {
      value: 13,
      name: 'Zona 13',
    },
    {
      value: 14,
      name: 'Zona 14',
    },
    {
      value: 15,
      name: 'Zona 15',
    },
    {
      value: 16,
      name: 'Zona 16',
    },
    {
      value: 17,
      name: 'Zona 17',
    },
    {
      value: 18,
      name: 'Zona 18',
    },
    {
      value: 19,
      name: 'Zona 10',
    },
    {
      value: 21,
      name: 'Zona 21',
    },
    {
      value: 24,
      name: 'Zona 24',
    },
    {
      value: 25,
      name: 'Zona 25',
    },
  ];
  async ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    this.role = user.idRol ? parseInt(user.idRol.toString()) : 0;
    
    await this.getUser();

    this._idSucursal = user.idSucursal ? parseInt(user.idSucursal.toString()) : 0;
    if (this.role == 2) {
      this.clientSelect = true;
    }else{
      this.clientSelect = false;
    }



    await this.getAllDays();
    this.addTask = true;
    
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:none;');

    this._route.paramMap.subscribe(async (params: any) => {
      const idSucursal = this._idSucursal;

      if (idSucursal !== undefined) {

        this.getAllTasks(idSucursal);
      }
    });
    this.task.idSucursal = this._idSucursal;
    this.getAllVehicles(this._idSucursal);
    this.getAllSucursalesByCompany(this._idSucursal);
    // this.getAllVehicles();

    /* Autocomplete Remitente */
    this.clients = [];
    this.filteredControlOptions$ = of(this.clients);
    this.filteredNgModelOptions$ = of(this.clients);

    this.inputFormControl = new FormControl();
    this.filteredControlOptions$ = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );

    /* Autocomplete Destinatario */
    this.clients2 = [];
    this.filteredControlOptions2$ = of(this.clients2);
    this.filteredNgModelOptions2$ = of(this.clients2);

    this.inputFormControl2 = new FormControl();
    this.filteredControlOptions2$ = this.inputFormControl2.valueChanges
      .pipe(
        startWith(''),
        map(_filterString => this._filter(_filterString)),
      );

    this.getAddress();
    this.getAddress2();
    this.getDaysYear();




  }

  addTaskFunction() {
    this.addTask = false;
    const userLogged = JSON.parse(localStorage.getItem('user'));
    console.log(userLogged)
    if (userLogged.task != undefined && userLogged.task != null) {
      this.task = userLogged.task;
      
      this.getHorario(this.task.dateShipping);
      // this.person2.id = this.task.idPetitioner;
      if (this.task.idPetitioner!=null) {
        let _idAddressPetitioner = this.task.idPetitioner
      this.person2.id = _idAddressPetitioner;
      this.task.idPetitioner = _idAddressPetitioner;
      this.getInfoClient2(_idAddressPetitioner);
      this.getAllAddressByClient2(_idAddressPetitioner);

      if (this.task.idAddressPetitioner != null) {
        this.verDireccion(this.task.idAddressPetitioner);
      }
      }

      // if (this.task.idReceiver != null) {
        
      //   const _idAddressReceiver = this.task.idReceiver;
      //   this.person.id = _idAddressReceiver;
      //   this.task.idReceiver = _idAddressReceiver;
      //   this.getInfoClient(_idAddressReceiver);
      //   this.getAllAddressByClient(_idAddressReceiver);


      //   if (this.task.idAddressReceiver != null) {
      //     this.verDireccion2(this.task.idAddressReceiver);
      //   }
      // }
      

     
      
      this.next1(1)
      this.next2()
      console.log(this.task.dateShipping);
      if (this.task.dateShipping != null) {
        this.task.dateShipping = new Date(this.task.dateShipping);
        console.log(this.task.dateShipping);
      }



    }



    console.log(this.task);

  }
  filterCalendar() {
    let date = new Date();
    var dateNow = Date.now();
    
    var listDays = "";
    let frist = false;
    // var dateNow = Date.now();
    listDays = 'date => date > dateNow';

    this.holidayDate.forEach(element => {
      if (element.close == "false") {
        if (frist) {
          frist = false;
        } else {
          listDays = listDays + ' && '
        }
        var calendarDays = new Date(element.year + '-' + element.month + '-' + element.date);
        calendarDays = moment(calendarDays).toDate();
        calendarDays.setMinutes(calendarDays.getMinutes() + calendarDays.getTimezoneOffset());
        let dateString = calendarDays.getFullYear() + "-" + calendarDays.getMonth() + "-" + calendarDays.getDate();
        listDays = listDays + '(date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate()) != "' + dateString + '"';
      }

    });
    if (!frist) {
      this.filter1 = eval(listDays);
    }

  }



  getDaysYear() {
    // const userLogged = JSON.parse(localStorage.getItem('user')) as User;
    // console.log(localStorage)

    this.daysService.getAllYearDays(2).subscribe(
      response => {

        if (response.result == true) {
          console.log(response.records[1]);
          this.holidayDate = response.records[1];
          this.filterCalendar();
          // console.log(response);
          // this.tasks = response.records[1];

          // this.tasks.forEach(element => {
          //   // element.timeEnd =moment(element.timeEnd).format('HH:MM');
          //   // element.timeEnd = element.timeEnd.date('mm:ss');
          //   console.log(element.timeEnd);
          //   if (element.close == 'false' || element == '0') {
          //     element.close = false;
          //   }else{
          //     element.class = true;
          //   }
          // });
          // this.tasksList = new LocalDataSource(this.tasks);
          // console.log(response);
          // this.getDays();
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  async getUserList() {
    this._clientService.getAll().subscribe(
      response => {
        //console.log(response);
        if (response.result == true) {
          this.clients = response.records[0];
          //console.log(this.clients);
          //console.log(this.options);
          console.log(this.clients);
          console.log(this.userDB);
          if (this.userDB.idRol == 2 || this.userDB.idRol ) {
            this.role = this.userDB.idRol;
            console.log("Si entra aqui")
            this.clients= this.clients.filter(x => x.userCreate == this.userDB.id);
          }
        }
      },
      error => {
        console.log();
      }
    );

    // this.userService.getAll().subscribe(
    //   response => {
    //     if (response.result == true) {
    //       this.user1 = response.records[0];
    //       // console.log(this.user1);
    //       this.getUser();

    //     }
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );

  }
  async getUser() {
    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    console.log(user);
    this.userService.userGetOne(user.idCognito).then(
      async (result : any)=>{
      // console.log(result)
      if (result.result == true) {
        this.userDB = result.records[1][0];
        
        await this.getUserList();
      }
    },
      error => {
        console.log();
      })


  }
  private filter(value: string): string[] {
    if (value.length < 3) {
      return [];
    }
    const filterValue = value.toLowerCase();
    this._clientService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.clients = response.records[0];
          //console.log(this.options);
        }
      },
      error => {
        console.log();
      }
    );
    return this.clients.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));
  }

  private _filter(value: string): string[] {
    if (value.length < 3) {
      return [];
    }
    const filterValue = value.toLowerCase();
    this._clientService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.clients2 = response.records[0];
          //console.log(this.options);
        }
      },
      error => {
        console.log();
      }
    );
    return this.clients2.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));
  }

  getHorario(event) {
    //console.log(event);
    this.task.dateShipping = event;
    if (this.task.dateShipping == null) {

      //console.log("none")
    } else {
      //console.log(this.task.dateShipping);
      var date = moment(this.task.dateShipping).toDate();


      const selectedValue = this.horario.find(x => x.idDay === date.getDay());
      const selectCalendar = this.holidayDate.find(x => x.month == (date.getMonth() + 1) && x.date == date.getDate() && x.year == date.getFullYear());
      if (selectCalendar != undefined && selectCalendar != null) {
        this.min = selectCalendar.timeStart;
        this.max = selectCalendar.timeEnd;
      } else {
        this.min = selectedValue.timeStart;
        this.max = selectedValue.timeEnd;
      }

      // console.log(selectedValue);
    }

  }

  saveLocal(){
    const userLogged = JSON.parse(localStorage.getItem('user'));
    userLogged.task = this.task;
    localStorage.setItem('user', JSON.stringify(userLogged));
  }
  cleanLocal(){
    const userLogged = JSON.parse(localStorage.getItem('user'));
    userLogged.task = null;
    localStorage.setItem('user', JSON.stringify(userLogged));
  }
  next1(op) {

   this.saveLocal();

    if (this.task.idSucursal != null && this.task.idVehicle != null && this.task.dateShipping != null
      && this.task.idPetitioner != null && this.task.idAddressPetitioner != null) {
      this.linearMode1 = false;
      this.stepperIndex1 = 1;
    } else {
      if (op != 1) {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'warning';
      this.toastrService.show(status || '', 'Ingrese todos los campos', { position, status });
      }
      
    }
  }

  next2() {
    if (this.task.totalPackages != null && this.task.amount != null && this.task.idPackageType != null) {
      this.linearMode2 = false;
      this.stepperIndex1 = 2;
    }
  }

  evento(event) {
    console.log(event);
    this.saveLocal();

    const selectedValue = this.clients.find(x => x.name == event);
    const _idAddressReceiver = selectedValue.id;
    this.person.id = _idAddressReceiver;
    this.task.idReceiver = _idAddressReceiver;
    this.getInfoClient(_idAddressReceiver);
    this.getAllAddressByClient(_idAddressReceiver);
    //console.log(this.task.idAddressReceiver);
  }

  evento2(event) {
    //console.log(event);
    const selectedValue = this.clients.find(x => x.name === event);
    console.log(selectedValue)
    const _idAddressPetitioner = selectedValue.id;
    this.person2.id = _idAddressPetitioner;
    this.task.idPetitioner = _idAddressPetitioner;
    this.getInfoClient2(_idAddressPetitioner);
    this.getAllAddressByClient2(_idAddressPetitioner);
    this.saveLocal();

    //console.log(this.person2.id);
  }

  sucursalEvento(event) {
    //console.log(event);

    this.getAllVehicles(event);
  }

  getAllSucursalesByCompany(idSucursal) {
    console.log(idSucursal)
    this._sucursalService.getAllByIdEmpresa(2).subscribe(
      response => {
        if (response.result == true) {
          this.sucursales = response.records[1];
          console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllAddressByClient(_idAddressReceiver) {
    this._personAddressService.getAddressByPersonId(_idAddressReceiver).subscribe(
      response => {
        if (response.result == true) {
          this.personAddress = response.records[1];

          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllAddressByClient2(_idAddressReceiver) {
    this._personAddressService.getAddressByPersonId(_idAddressReceiver).subscribe(
      response => {
        if (response.result == true) {
          this.personAddress2 = response.records[1];

          console.log(this.personAddress2);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getInfoClient(_idPerson) {
    this._clientService.getAllByPersonId(_idPerson).subscribe(
      response => {
        if (response.result == true) {
          this.person = response.records[1][0];
          this.client2Name = this.person.name;
        }
      },
      error => {
        console.log();
      }
    );
  }

  getInfoClient2(_idPerson) {
    this._clientService.getAllByPersonId(_idPerson).subscribe(
      response => {
        if (response.result == true) {
          this.person2 = response.records[1][0];
          
          this.client1Name = this.person2.name;
        }
      },
      error => {
        console.log();
      }
    );
  }
  getAllDays() {

    this.daysService.getAll(2).subscribe(
      response => {

        if (response.result == true) {

          this.horario = response.records[1];

          this.horario.forEach(element => {
            // element.timeEnd =moment(element.timeEnd).format('HH:MM');
            // element.timeEnd = element.timeEnd.date('mm:ss');
            // console.log(element.timeEnd);
          });
          this.tasksList = new LocalDataSource(this.tasks);
          // console.log(response);
        }
      },
      error => {
        console.log();
      }
    );

    // this._rolService.getById(2).subscribe(
    //   response => {
    //     //console.log(response);
    //     if (response.result == true) {
    //       //console.log(response);
    //       this.horario = response.records[3];

    //       this.horario.forEach(element => {
    //         // element.timeEnd =moment(element.timeEnd).format('HH:MM');
    //         // element.timeEnd = element.timeEnd.date('mm:ss');
    //         //console.log(element.timeEnd);
    //       });
    //       this.tasksList = new LocalDataSource(this.tasks);
    //       //console.log(response);
    //     }
    //   },
    //   error => {
    //     console.log();
    //   }
    // );
  }
  getAllVehicles(idSucursal) {
    this._companyService.getCompanyVehicleRate(idSucursal).subscribe(
      response => {
        if (response.result == true) {
          this.companyVehicle = response.records[1];
          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }
  delete(task) {

    Swal.fire({
      title: 'Eliminar Tarea',
      text: "¿Está Seguro de Eliminar esta tarea?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        var taskTracking = {} as TaskTracking;
        task = task.data;
        taskTracking.idStatus = 0;
        taskTracking.idTask = task.id;
        taskTracking.kmTraveled = "0";

        this._trackingStatusService.updateTaskTracking(taskTracking).subscribe(
          response => {

            if (response.result == true) {
              // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              // const status: NbComponentStatus = 'success';
              // this.toastrService.show(status || 'Success', "Paso Cerrado Exitosamente", { position, status });
              // //form.reset();
              // this.getUserAdminToken();
              // // this.goToTaskTracking();

              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'success';
              this.toastrService.show(status || 'Success', "Tarea Eliminada exitosamente", { position, status });
              //form.reset();
              this.getUserAdminToken();
              setTimeout(function () {

                window.location.reload();
              }, 1000);





            } else {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'warning';
              this.toastrService.show(status || 'Warning', response.message, { position, status });
            }
          },
          error => {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(status || 'Danger', <any>error, { position, status });
          }
        )
      }
    })


  }
  getAllTasks(idSucursal) {
    this._taskService.getAllBySucusal(idSucursal).subscribe(
      response => {
        if (response.result == true) {
          this.tasks = response.records[1];
          this.tasksList = new LocalDataSource(this.tasks);

        }
      },
      error => {
        console.log();
      }
    );
  }

  addClientAddress() {
    this.router.navigate(
      ['/addAddress/' + this.person.id]
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: false,
      delete: true,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    delete: {
      deleteButtonContent: "<i class='fa fa-minus-square-o' title='Eliminar'></i>"
    },
    columns: {
      id: {
        title: 'Tarea #',
        editable: false,
        width: "3%"
      },
      nameClient: {
        title: 'Destinatario',
        editable: false
      },
      totalPackages: {
        title: 'Paquetes #',
        editable: false,
        width: "3%"
      },
      dateShipping: {
        title: 'Fecha envío',
        editable: false
      },
      estadoTracking: {
        title: 'Estado',
        editable: false
      }
    }
  };

  verDireccion2(e) {
    this.saveLocal();
    this._personAddressService.getOneAddressByPersonId(e).subscribe(
      response => {
        if (response.result == true) {
          this._personAddress = response.records[1][0];
          // console.log(this._personAddress2);
        }
      },
      error => {
        console.log();
      }
    );
  }

  verDireccion(e) {
    this._personAddressService.getOneAddressByPersonId(e).subscribe(
      response => {
        if (response.result == true) {
          this._personAddress2 = response.records[1][0];
          // console.log(this._personAddress2);
        }
      },
      error => {
        console.log();
      }
    );
    // console.log("Persona adress 2")
    // console.log(this._personAddress2);
    // this._personAddressService.getOneAddressByPersonId(e).subscribe(
    //   response => {
    //     if (response.result == true) {
    //       this._personAddress = response.records[1][0];
    //       //console.log(response);
    //     }
    //   },
    //   error => {
    //     console.log();
    //   }
    // );
  }

  confirmSave(dialog: TemplateRef<any>) {
    if (this.task.idSucursal === null || this.task.idSucursal === 0) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione la Sucursal", { position, status });
    } else if (this.task.idVehicle === null || this.task.idVehicle === 0) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione su Tipo de Envio", { position, status });
    } else if (this.task.dateShipping === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione la fecha de envio", { position, status });
    }
    // else if (this.task.hourShipping === null) {
    //   let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    //   let status: NbComponentStatus = 'danger';
    //   this.toastrService.show(status || 'Danger', "Seleccione la Hora del envio", { position, status });
    // }
    else if (this.task.idPetitioner === null || this.task.idPetitioner === 0) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione El cliente del campo ''Recolectar con'' ", { position, status });
    }
    else if (this.task.idAddressPetitioner === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione la dirección para recolectar el pedido ", { position, status });
    }
    else if (this.task.totalPackages === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Ingrese la Cantidad de Paquetes", { position, status });
    }
    else if (this.task.amount === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Ingrese el Monto a Cobrar", { position, status });
    }
    else if (this.task.idPackageType === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione el Tipo de Paquete", { position, status });
    }
    else if (this.task.idReceiver === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Ingrese el Destinatario", { position, status });
    }
    else if (this.task.idAddressReceiver === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Ingrese la dirección del destinatario", { position, status });
    } else {

      //console.log("Confirmar almacenado de tarea!");
      this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
    }




  }

  save() {

    // console.log(this.tiempo);
    let idSucursal = this.task.idSucursal;
    this.task.dateShipping = moment(this.task.dateShipping).format("YYYY-MM-DD") 
    
    if (this.task.comentario == null) {
      this.task.comentario = '';
    }
    let task = this.task;
    let task1 = {
      id:task.id,
      idSucursal:task.idSucursal,
      idPetitioner:task.idPetitioner,
      idAddressPetitioner:task.idAddressPetitioner,
      idReceiver:task.idReceiver,
      idAddressReceiver:task.idAddressReceiver,
      totalPackages:task.totalPackages,
      amount:task.amount,
      dateShipping:task.dateShipping,
      hourShipping:task.hourShipping,
      idVehicle:task.idVehicle,
      idStatus: task.idStatus,
      comentario:task.comentario,
      idUserCreate: this.userDB.id
    }
    console.log(task1)
    this._taskService.save(task1).subscribe(
      response => {
        console.log(response);
        
        console.log(this.task);
        if (response.result == true) {

         

          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', 'Tarea Creada satisfactoriamente', { position, status });
          // this.getUserAdminToken();
          // this.clean();
          // this.getAllTasks(idSucursal);
          // this.getUserAdminToken();
          // this.verDireccion2(response.records[9][0].id);
          // this.getAllAddressByClient(this.person.id);
          // this.task.idAddressReceiver = response.records[9][0].id;
          this.cleanLocal();
          
          setTimeout(function(){
            
            window.location.reload();
        },1000);
        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )

  }

  clean() {
    this.task.idPetitioner = null;
    this.task.idReceiver = null;
    this.task.idAddressReceiver = null;
    this.task.idAddressPetitioner = null;
    this.task.totalPackages = null;
    this.task.amount = null;
    this.task.dateShipping = null;
    this.task.idVehicle = null;
    this.task.comentario = null;
    this.person.mail = null;
    this.person.phone = null;
    this.person2.mail = null;
    this.person2.phone = null;
    this._personAddress.address = null;
    this._personAddress2.address = null;
  }

  deleteTask(event) {
    //console.log(event);

    this.task.id = 1;
    this.task.comentario = event.data.codeTask;

    let idSucursal = this._idSucursal;
    this._taskService.save(this.task).subscribe(
      response => {
        if (response.result == true) {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.clean();
          this.getAllTasks(idSucursal);
          this.task = new Task(0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,0);

        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )

  }

  getAddress() {
    this._addressService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this._mAddress = response.records[0];
          //console.log(response);
        }
      }, error => {
        console.log();
      }
    )
  }

  onSubmit() {

    /* Obtenemos la longitud y latitud del marcador */
    // const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);
    // this.mPersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    // this.mPersonAddress.latitude = lngLatArr[0]['centro'][1].toString();

    // console.log(this.mPerson);
    // console.log(this.mPersonAddress);
    //console.log(this.mPerson);
    this.mPerson.userCreate = this.userDB.id;
    this.mPerson.idStatus = 1;

    this._clientService.save(this.mPerson).subscribe(
      response => {
        if (response.result == true) {
          //this.mPersonAddress.idCustomer = response.records[0].idClient;
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.getUserList();
          this.getDivInfo();
          
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  open2(dialog2: TemplateRef<any>, idPersona_) {
    this.mDpersonAddress.idCustomer = idPersona_;
    this.dialogService.open(dialog2, { context: 'this is some additional data passed to dialog' });
  }

  getAddress2() {
    this._addressService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this._mDaddress = response.records[0];
          //console.log(response);
        }
      }, error => {
        console.log();
      }
    )
  }

  save2() {
    //console.log(this.personAddress);

    /* Obtenemos la longitud y latitud del marcador */

    // const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);

    // this.mDpersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    // this.mDpersonAddress.latitude = lngLatArr[0]['centro'][1].toString();

    this.mDpersonAddress.idStatus = 1;
    if (this.mDpersonAddress.address === null || this.mDpersonAddress.address === "") {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Ingrese La dirección", { position, status });
    } else if (this.mDpersonAddress.idAddressMunicipality === 0) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione su municipio", { position, status });
    }
    else if (this.mDpersonAddress.idAddressZone === 0) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione la Zona", { position, status });
    }
    else if (this.mDpersonAddress.idCatAddress === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Seleccione el tipo de dirección", { position, status });
    } else if (this.mDpersonAddress.longitude === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Ingrese Longitud de la dirección", { position, status });
    } else if (this.mDpersonAddress.latitude === null) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', "Ingrese Latitud de la dirección", { position, status });
    } else {
      //console.log(this.mDpersonAddress);
      this._personAddressService.save(this.mDpersonAddress).subscribe(
        response => {

          if (response.result == true) {
            let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            let status: NbComponentStatus = 'success';
            this.toastrService.show(status || 'Success', response.message, { position, status });
            this.getDivInfo();
            this.borrarMarcador(0);
            if (this.optionSelected === 1) {
              this.verDireccion(response.records[11][0].id);
              this.getAllAddressByClient2(this.mDpersonAddress.id);
            } else {
              this.verDireccion2(response.records[11][0].id);
              this.getAllAddressByClient(this.mDpersonAddress.id);
            }

            this.task.idAddressPetitioner = response.records[11][0].id;
            //reset flg Direction



          } else {
            //console.log(response);
            let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            let status: NbComponentStatus = 'warning';
            this.toastrService.show(status || 'Warning', response.message, { position, status });
          }
        },
        error => {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'danger';
          this.toastrService.show(status || 'Danger', <any>error, { position, status });
        }
      )
    }

  }

  /* Metodos para poder utilizar mapa */
  getDivCliente() {
    this.saveLocal();
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('overflow:flex;');
  }

  getDivDireccion(idPersona_, opcion) {
    this.mapa = true;
    this.saveLocal();
    this.mDpersonAddress.idCustomer = idPersona_;
    this.optionSelected = opcion;
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:block;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:visible;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
  }

  addMarker() {
    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));

    if (this.marcadores.length < 1) {
      const nuevoMarcador = new mapboxgl.Marker({
        draggable: true,
        color
      })
        .setLngLat(this.center)
        .addTo(this.map);

      this.marcadores.push({
        color,
        marker: nuevoMarcador
      });

      this.guardarLocalStorage();

      nuevoMarcador.on('dragend', () => {
        this.guardarLocalStorage();
      });

    }
    const lngLatArr: MarcadorColor[] = JSON.parse(localStorage.getItem('marcadores')!);

    this.mDpersonAddress.longitude = lngLatArr[0]['centro'][0].toString();
    this.mDpersonAddress.latitude = lngLatArr[0]['centro'][1].toString();
  }

  getMarker(marker: mapboxgl.Marker) {
    this.map.flyTo({
      center: marker.getLngLat()
    });
  }

  guardarLocalStorage() {

    const lngLatArr: MarcadorColor[] = [];

    this.marcadores.forEach(m => {

      const color = m.color;
      const { lng, lat } = m.marker!.getLngLat();

      lngLatArr.push({
        color: color,
        centro: [lng, lat]
      });

    })

    localStorage.setItem('marcadores', JSON.stringify(lngLatArr));

  }

  leerCoordenadas(sucursal) {

    const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));
    const centro: [number, number] = [sucursal.longitude, sucursal.latitude];

    const newMarker = new mapboxgl.Marker({
      color: color,
      draggable: true
    })
      .setLngLat(centro)
      .addTo(this.map);

    this.marcadores.push({
      marker: newMarker,
      color: color
    });

    newMarker.on('dragend', () => {
      this.guardarLocalStorage();
    });

  }

  borrarMarcador(i: number) {

    this.marcadores[i].marker.remove();
    this.marcadores.splice(i, 1);
    this.guardarLocalStorage();
  }

  async getDivInfo() {
    this.mapa = false;
    this.flgInfo = this.sanitizer.bypassSecurityTrustStyle('display:flex;');
    this.flgMapa = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgCliente = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.flgDireccion = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    this.mDpersonAddress.address = ' ';
    this.mDpersonAddress.idAddressMunicipality = null;
    this.mDpersonAddress.idAddressZone = null;
    this.mDpersonAddress.idCatAddress = null;
    this.mDpersonAddress.condominiumName = ' ';
    this.mDpersonAddress.accessCode = ' ';
    this.mDpersonAddress.latitude = ' ';
    this.mDpersonAddress.longitude = ' ';
    await this.callEvento2();
    await this.callEvento();
  }

  async callEvento() {
    this.evento(this.person.name);
  }

  async callEvento2() {
    this.evento2(this.person2.name);
  }
  /* Metodo para poder utilizar mensajes push */
  getUserAdminToken() {
    this._userService.getUserTokenAdminPush().subscribe(
      response => {
        if (response.result == true) {
          this.userToken = response.records[0];

          let title = 'Notificación mTracking';
          let message = "Se ingreso una nueva tarea";

          this.userToken.forEach(m => {
            // Operar los mensajes para el admin de tarea nueva
            const params = {
              "notification": {
                "title": title,
                "body": message
              },
              "to": m.tokenPush
            };

            this.messagingService.sendMessage(params).subscribe(
              response => {
                //console.log(response);
              },
              error => {
                console.log();
              }
            );

          });

          //console.log(this.userToken);
        }
      },
      error => {
        console.log();
      }
    );
  }

}
