export class User {
	constructor(
		public id: number,
		public idSucursal: number,
		public name: string,
		public user: string,
		public password: string,
		public idRol: number,
		public idStatus: number,
		public idCognito: string,
    public acceptPolicy: number,
	public userAvatar: number,
	public userNumberPhone:string,
	public userNit:string,
	public userBirthDate:Date
	) { }
}
