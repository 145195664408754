import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Task } from '../models/task';
import { Global } from './global';

@Injectable()
export class TaskService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	save(task): Observable<any>{
		// let params = JSON.stringify(task);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-task',task,{ headers: headers });

	}

	getAllBySucusal(idSucursal): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-taskSucursal/one/'+idSucursal, {headers: headers});
	}

	getAllTaskToAsign(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-taskToAssign', {headers: headers});
	}

	getOneTaskToAsign(idTask): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-taskToAssign/task-one/'+idTask, {headers: headers});
	}

	addCarrier(task: Task): Observable<any>{
		let params = JSON.stringify(task);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-taskCarrier',params,{ headers: headers });
	}

	getAllTasksByCarrier(params): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-taskCarrierAll', params, {headers: headers});
	}

	getAllTasks(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-task', {headers: headers});
	}

}