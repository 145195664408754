import { User } from 'src/app/models/user.js';
import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import sidebarMenu from '../../../assets/data/sidebarMenu/sidebarMenu.json';
import sidebarMenuClient from '../../../assets/data/sidebarMenu/sidebarMenuClient.json';
import sidebarMenuCarrier from '../../../assets/data/sidebarMenu/sidebarMenuCarrier.json';
import sidebarMenuOperator from '../../../assets/data/sidebarMenu/sidebarMenuOperator.json';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menu: NbMenuItem[];
  role;
  loading = false;
  public user1=new Array();
  public user = {} as User;
  public userDB = {} as User;

  constructor(private userService: UserService,) {
    
    // let user = {} as User;
    // if (localStorage.getItem('user')) {
    //   user = JSON.parse(localStorage.getItem('user'));
    //   // console.log(user);
    // }
    // this.getUserList();
    // this.role = user.idRol ? parseInt(user.idRol.toString()) : 0;
    this.menu = sidebarMenuClient;
    
  }
  async getUser(){
    this.loading = true;
    let user12 = {} as User;
    if (localStorage.getItem('user')) {
      user12 = JSON.parse(localStorage.getItem('user'));
    }
    var user=user12.user;
    this.user1.forEach(element => {
      if (element.user == user) {
        this.userDB = element;
      }

    });
    
    this.role = this.userDB.idRol;
    switch (this.role) {
      case 1:
        this.menu = sidebarMenu;
        break;
      case 2:
        this.menu = sidebarMenuClient;
        break;
        case 7:
          this.menu = sidebarMenuClient;
          break;
      case 3:
        this.menu = sidebarMenuOperator;
        break;
      case 4:
        this.menu = sidebarMenuCarrier;
        break;
      default:
        this.menu = sidebarMenu;
        // this.menu =[];
        break;
    }
    this.loading= false;

  }
  async getUserList(){
    
    // console.log(user12);
    // var user=user12.user;
    // let user1 = new Array();
    this.userService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.user1 = response.records[0];
          // console.log(this.user1);
          this.getUser();
          
        }
      },
      error => {
        console.log(error);
      }
    );
    this.loading = false;
  }
 async ngOnInit() {
   
    await this.getUserList();
    this.loading= false;
  }

}
