import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { PersonAddress } from '../models/person-address';
import { Global } from './global';

@Injectable()
export class PersonAddressService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	save(personAddress: PersonAddress): Observable<any>{
		let params = JSON.stringify(personAddress);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-address',params,{ headers: headers });

	}

	getAllByPersonId(id): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-address/one/'+id,{headers: headers});
	}

	getAddressByPersonId(id): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-client-address/one/'+id,{headers: headers});
	}

	getOneAddressByPersonId(id): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-client-address-one/one/'+id,{headers: headers});
	}
}