<nb-card accent="info">
  <nb-card-header>Mantenimiento de repartidores</nb-card-header>
  <nb-card-body>
    <form #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
        <div class="row justify-content-center">
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="name" class="label">Nombre</label>
                    <input type="text" nbInput fullWidth name="name" #name="ngModel" 
                            [(ngModel)]="person.name" required="true" 
                            nbTooltip="Nombre de repartidor" nbTooltipPlacement="top" />
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="phone" class="label">Teléfono</label>
                    <input type="text" nbInput fullWidth name="phone" #phone="ngModel" 
                            [(ngModel)]="person.phone" required="true" maxlength="8" autocomplete="false"
                            nbTooltip="Número de teléfono" nbTooltipPlacement="top" />
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="mail" class="label">Correo Electrónico</label>
                    <input type="text" nbInput fullWidth name="mail" #mail="ngModel" 
                            [(ngModel)]="person.mail" required="true" 
                            nbTooltip="Correo electrónico de repartidor" nbTooltipPlacement="top" />
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="button" class="label label-button">boton</label>
                    <button nbButton ghost status="success">
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </form>
  </nb-card-body>
</nb-card>

<nb-card accent="info">
  <nb-card-header>Listado de Repartidores</nb-card-header>
  <nb-card-body>
    <ng2-smart-table (edit)="addAddress($event)" [settings]="settings" [source]="carriersList"></ng2-smart-table>
  </nb-card-body>
</nb-card>