export class Task{
	constructor(
		public id: number,
		public idSucursal: number,
		public codeTask: string,
		public idPetitioner : number,
		public idAddressPetitioner: number,
		public idReceiver : number,
		public idAddressReceiver: number,
	    public totalPackages : number,
		public idPackageType: number,
	    public amount : string,
		public idOperator : string,
	    public idCarrier : number,
	    public dateShipping,
		public dateReception : string,
		public picturePackage: string,
		public idVehicle: number,
		public idStatus : number,
		public comentario : string,
		public accessCodeSend:string,
		public condominiumNameSend:string,
		public condominiumNameReceiver:string,
		public accessCodeReceiver:string,
		public receiver_municipality:string,
		public receiver_zone:string,
		public receiver_type:string,
		public petitioner_municipality:string,
		public petitioner_zone:string,
		public petitioner_type:string,
		// Estos atributos del objeto únicamente sirven para mostrar los joins
		public customerPhone: string,
		public petitionerName: string,
		public addressPetitioner: string,
		public sucursalName: string,
		public addressSucursal: string,
		public latitudeSucursal: string,
		public longitudeSucursal: string,
		public customerName: string,
		public addressCustomer: string,
		public latitudeCustomer: string,
		public longitudeCustomer: string,
		public nameVehicle: string,
		public petitionerPhone: string,
		public amountTask: number,
    public hourShipping: string,
	public idUserCreate: number
		){}
}
