import { Component, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { CompanyService } from '../../services/company.service';
import { NbToastrService, NbGlobalPosition, 
		     NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [CompanyService]
})
export class CompanyComponent implements OnInit {

	public company: Company;
  public companies : Company[];
  public companiesList = new LocalDataSource();

  constructor(
    private _companyService: CompanyService,
    private toastrService: NbToastrService,
    private router: Router
  ) {
    this.company = new Company(0,'','','',1);
  }

  ngOnInit(){
    const userLogged = JSON.parse(localStorage.getItem('user'));
    console.log(userLogged);
    this.getAllCompanies();
  }

  getAllCompanies(){
    this._companyService.getAll().subscribe(
      response => {
        if(response.result == true){
          this.companies = response.records[0];
          this.companiesList = new LocalDataSource(this.companies);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      name: {
        title: 'Nombre',
        editable: false
      },
      phone: {
        title: 'Teléfono',
        editable: false
      },
      nit: {
        title: 'NIT',
        editable: false
      }
    }
  };

  onSubmit(form){
    //console.log(this.company);
    this._companyService.save(this.company).subscribe(
      response => {
        if(response.result == true){
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          form.reset();
          this.getAllCompanies();
        }else{
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  addSucursal(event){
    this.router.navigate(
      ['/sucursales-empresa/' + event.data.id]
    );
  }


}