<!-- Lista de información de tareas de repartidores -->

<nb-card *ngIf="_idRol == 4" class="col-md-9 mx-auto" accent="info">
    <nb-card-header>Listado de tareas</nb-card-header>
    <nb-card-body>
        <ng2-smart-table (edit)="trackingTask($event)" (delete)="getLocation($event, dialog2)" [settings]="settings" [source]="tasksList">

        </ng2-smart-table>
    </nb-card-body>
</nb-card>

<!-- Modal de información de tarea para repartidores -->

<ng-template #dialog2 let-data let-ref="dialogRef">
    <nb-card class="col-11 mx-auto" accent="info">
        <nb-card-header>Ubicación de {{_tittle}}</nb-card-header>
        <nb-card-body>
            <nb-card-body>
                <div class="row">
                    <div class="col-12 text-center">
                        <a href="{{ data }}">
                            Ver en mapa.
                        </a>
                    </div>
                </div>
            </nb-card-body>
        </nb-card-body>
        <nb-card-footer class="text-right">
            <button nbButton ghost status="secondary" (click)="ref.close()">Cerrar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<!-- Lista de información de tareas de Administradores -->

<nb-card *ngIf="_idRol == 1 || _idRol == 3" class="col-md-10 mx-auto" accent="info">
    <nb-card-header>Listado de tareas</nb-card-header>
    <nb-card-body>
        <ng2-smart-table (edit)="getInfoTask(dialog, $event)" (delete)="deleteTask($event)" [settings]="_settings" [source]="tasksList">

        </ng2-smart-table>
    </nb-card-body>
</nb-card>

<!-- Modal para ver la información de la tarea -->

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="col-8 mx-auto" accent="info">
        <nb-card-header>Información de tarea</nb-card-header>
        <nb-card-body>
            <nb-card-body>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Sucursal: </label><br />
                            <span>{{_informacionTarea.sucursalName}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">No. tarea / Monto: </label><br />
                            <span>{{_informacionTarea.codeTask}}</span>
                            <span> - </span>
                            <span>{{_informacionTarea._amountTask | currency: 'Q'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Remitente: </label><br />
                            <span>{{_informacionTarea._petitioner}}</span><br />
                            <span>{{_informacionTarea._phonePetitioner}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Dirección recolección:</label><br />
                            <span>{{_informacionTarea._addressPetitioner}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Destinatario: </label><br />
                            <span>{{_informacionTarea._receiver}}</span><br />
                            <span>{{_informacionTarea._phone}}</span><br />
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Dirección entrega:</label><br />
                            <span>{{_informacionTarea._addressReceiver}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Observación: &nbsp;</label>
                            <span>{{_informacionTarea._comentario}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Repartidor / Vehículo: </label><br />
                            <span>{{_informacionTarea._carrier}}</span>
                            <span> - </span>
                            <span>{{_informacionTarea._nameVehicle}}</span>
                        </div>
                    </div>

                    <div class="col-sm-6" *ngIf="_informacionTarea.imgColect != ''">
                        <div class="form-group">
                            <label for="task" class="label">Imagen de recolección: &nbsp;</label><br>
                           <span> <a [href]="_informacionTarea.imgColect" for="task" target="_blank">Ver Imagen</a></span>
                        </div>
                    </div>

                    <div class="col-sm-6" *ngIf="_informacionTarea.imgDelivery != ''">
                        <div class="form-group">
                            <label for="task" class="label">Imagen de entrega: &nbsp;</label> <br>
                            <span> <a [href]="_informacionTarea.imgDelivery" for="task" target="_blank">Ver Imagen</a></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="task" class="label">Localización: </label><br />
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card-body>
        <nb-card-footer class="text-right">
            <button nbButton ghost status="primary" (click)="goToReAssign()">Reasignar</button>
            <button nbButton ghost status="secondary" (click)="ref.close()">Cerrar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>