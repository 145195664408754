import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { DaysWeek } from '../models/daysWeek';
import { Global } from './global';

@Injectable()
export class DaysWeekService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}
    apiDays = 'mtracking-days';

	save(person): Observable<any>{
		let params = JSON.stringify(person);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/'+this.apiDays,params,{ headers: headers });

	}

	getAll(params): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/'+this.apiDays+'/'+params,{headers: headers});
	}

	saveYearDays(person): Observable<any>{
		let params = JSON.stringify(person);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/'+this.apiDays+'/year',params,{ headers: headers });

	}
	saveYearAllDays(person): Observable<any>{
		let params = JSON.stringify(person);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/'+this.apiDays+'/yearAll',params,{ headers: headers });

	}

	getAllYearDays(params): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/'+this.apiDays+'/year/'+params,{headers: headers});
	}

	getAllByPersonId(id): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-client/one/'+id,{headers: headers});
	}
}