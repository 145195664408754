import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbWindowService } from '@nebular/theme';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {

  constructor(
    private dialogref: NbDialogRef<TermsandconditionsComponent>,
    private userService: UserService
    ) { }

  ngOnInit() {
  }

  close(){
    const user = JSON.parse(localStorage.getItem('user'));
    this.userService.acceptTerms(user).then(
      (result: any) => {
        window.location.reload();
      }
    );
  }
}
