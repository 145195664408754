import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Rate } from '../models/rate';
import { Global } from './global';

@Injectable()
export class RateService{
	public url:string;

	constructor(
		private _http: HttpClient
	){
		this.url = Global.url;
	}

	save(rate: Rate): Observable<any>{
		let params = JSON.stringify(rate);
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.post(this.url+'/mtracking-rate', params, { headers: headers });

	}

	getAll(): Observable<any>{
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return this._http.get(this.url+'/mtracking-rate',{headers: headers});
	}
}