import { Component, Input, OnInit } from '@angular/core';
import { NbComponentStatus, NbDialogRef, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {
  @Input() user = {} as User;
  oldPassword;
  newPassword;
  newConfirmationPassword;

  constructor(
    private dialogRef: NbDialogRef<UserChangePasswordComponent>,
    private toastrService: NbToastrService,
    private userService: UserService,
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  async save() {
    if (this.newPassword === undefined) this.newPassword = '';
    if (this.newConfirmationPassword === undefined) this.newConfirmationPassword = '';
    if (
      (this.newPassword !== this.newConfirmationPassword) ||
      ((this.newPassword === '') || (this.newConfirmationPassword === ''))
    ) {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        status || '',
        'Contraseñas no coinciden',
        { position, status });
    } else {
      await this.userService.changePasswordCognito(this.user.idCognito, this.oldPassword, this.newPassword)
        .then(
          async (result) => {
            const user = {
              id: this.user.id,
              password: this.newPassword,
            } as User;
            this.userService.updateUserBackend(user);
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'success';
            this.toastrService.show(
              status || '',
              'Contraseña actualizada',
              { position, status });
            this.dialogRef.close();
          }
        ).catch(
          (err) => {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              status || '',
              err,
              { position, status });
          }
        );
    }
  }

}
