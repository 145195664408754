<nb-card class="col-md-8 mx-auto" accent="info">
  <nb-card-header>Mantenimiento de empresas</nb-card-header>
  <nb-card-body>
    <form #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
        <div class="row">
            <div class="col-sm-5">
                <div class="form-group">
                    <label for="name" class="label">Nombre</label>
                    <input type="text" nbInput fullWidth name="name" nbTooltip="Nombre de la empresa" 
                            nbTooltipPlacement="top" [(ngModel)]="company.name" required />
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="phone" class="label">Teléfono</label>
                    <input type="text" nbInput fullWidth name="phone" [(ngModel)]="company.phone"
                            maxlength="8" autocomplete="false" nbTooltip="Teléfono de la empresa" nbTooltipPlacement="top" required="true" />
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="nit" class="label">Nit</label>
                    <input type="text" nbInput fullWidth name="nit" nbTooltip="NIT de la empresa"
                            nbTooltipPlacement="top" [(ngModel)]="company.nit" required />
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <button nbButton ghost status="success">
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </form>
  </nb-card-body>
</nb-card>

<nb-card class="col-md-8 mx-auto" accent="info">
  <nb-card-header>Listado de empresas</nb-card-header>
  <nb-card-body>
    <ng2-smart-table (edit)="addSucursal($event)" [settings]="settings" [source]="companiesList"></ng2-smart-table>
  </nb-card-body>
</nb-card>