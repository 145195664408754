<nb-card class="col-md-4 mx-auto" accent="info">
    <nb-card-header>Comisión por entrega</nb-card-header>
    <nb-card-body>
        <form #form="ngForm" (ngSubmit)="onSubmit(form)">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input type="hidden" nbInput name="idCompany" [(ngModel)]="company.nit" />
                        <label for="idCommission" class="label">Comisión</label>
                        <nb-select fullWidth placeholder="Comision" name="idCommission" #idCommission="ngModel" [(ngModel)]="companyCommission.idCommission" required>
                            <nb-option [value]="commi.id" *ngFor="let commi of commissions">
                                {{commi.commission}}
                            </nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group text-right">
                        <button nbButton ghost status="success">
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>
<nb-card class="col-md-4 mx-auto" accent="info">
    <nb-card-header>Comisión asignada</nb-card-header>
    <nb-card-body>
        <ng2-smart-table (delete)="deleteCommission($event)" [settings]="settings" [source]="list"></ng2-smart-table>
    </nb-card-body>
</nb-card>