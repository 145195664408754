<div class="row">
    <div class="col-6">
        <nb-card accent="info">
            <nb-card-header>Mantenimiento de sucursales</nb-card-header>
            <nb-card-body>
                <form #sucursalForm="ngForm" (ngSubmit)="onSubmit(sucursalForm)">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="idCompany" class="label">Empresa</label>
                                <input type="hidden" nbInput name="idCompany" #idCompany="ngModel" [(ngModel)]="company.id" />
                                <input type="text" nbInput fullWidth name="companyName" [(ngModel)]="company.name" required nbTooltip="Nombre de la sucursal" nbTooltipPlacement="top" disabled="" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="sucursalName" class="label">Nombre Sucursal</label>
                                <input type="text" nbInput fullWidth name="sucursalName" #sucursalName="ngModel" [(ngModel)]="sucursal.sucursalName" required="true" nbTooltip="Nombre de la sucursal" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="contactName" class="label">Contacto</label>
                                <input type="text" nbInput fullWidth name="contactName" #contactName="ngModel" [(ngModel)]="sucursal.contactName" required="true" nbTooltip="Contácto dentro de la sucursal" nbTooltipPlacement="top">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="contactMail" class="label">Correo Electrónico</label>
                                <input type="text" nbInput fullWidth name="contactMail" #contactMail="ngModel" [(ngModel)]="sucursal.contactMail" required="true" nbTooltip="Correo de contácto dentro de la sucursal" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="address" class="label">Dirección</label>
                                <input type="text" nbInput fullWidth name="address" #address="ngModel" [(ngModel)]="sucursal.address" required="true" nbTooltip="Dirección de la sucursal" nbTooltipPlacement="top" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="contactPhone" class="label">Teléfono</label>
                                <input type="text" nbInput fullWidth name="contactPhone" #contactPhone="ngModel" [(ngModel)]="sucursal.contactPhone" maxlength="8" autocomplete="false" nbTooltip="Teléfono de la sucursal" nbTooltipPlacement="top" required="true" />
                            </div>
                        </div>
                        <div class="col-sm-1" *ngIf="flgSucursal == false">
                            <div class="form-group">
                                <label for="button" class="label label-button">boton</label>
                                <a routerLink="../../company-commission/{{company.id}}" nbButton ghost status="basic">
                                    <nb-icon icon="inbox-outline"></nb-icon>
                                </a>
                            </div>
                        </div>
                        <div class="col-sm-1" *ngIf="flgSucursal == false">
                            <div class="form-group">
                                <label for="button" class="label label-button">boton</label>
                                <a routerLink="../../company-vehicle-rate/{{company.id}}" nbButton ghost status="basic">
                                    <nb-icon icon="car-outline"></nb-icon>
                                </a>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label for="button" class="label label-button">boton</label>
                                <button nbButton ghost status="success">
                                    <nb-icon icon="save-outline"></nb-icon>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-6">
        <div #map class="map-container">
            <ul class="list-group">
                <li class="list-group-item list-group-item-secondary" (click)="addMarker()">
                    + Agregar
                </li>

                <li class="list-group-item" (click)="getMarker(marcador.marker)" (dblclick)="borrarMarcador(i)" *ngFor="let marcador of marcadores; let i = index" [ngStyle]="{
                    'background-color': marcador.color
                }">
                    Marcador
                </li>

            </ul>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <nb-card accent="info">
            <nb-card-header>Listado de sucursales</nb-card-header>
            <nb-card-body>
                <ng2-smart-table (edit)="editSucursal($event)" [settings]="settings" [source]="sucursalesList">
                </ng2-smart-table>
            </nb-card-body>
        </nb-card>
    </div>
</div>