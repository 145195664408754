export const environment = {
  production: false,
  userPoolId: 'us-east-1_CoUD36Odb',
  clientId: '3hc87g1h7ktpl3hlh48f2s7qld',
  apiUrl: 'https://w4vbzich6j.execute-api.us-east-1.amazonaws.com/dev',
  accessKeyId: 'AKIAV42YBFE67KZFJEFJ',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucketName: 'mtracking-dev-content',
  bucketNameApp: 'mtracking-dev-myapp',
  regionSES: 'us-east-1',
  emailUrl: 'https://myapptracking.com/',
  emailImageUrl: 'https://rapi2go.myapptracking.com/assets/images/mLogo.png',
  cloudFrontUrl: 'https://d8c4puxci29wv.cloudfront.net/',
  identityPoolId: 'us-east-1:ab9d68dc-db11-49f4-b372-40e1b430f3da',
  cognitoDomain: 'mtracking-dev-myapp.auth.us-east-1.amazoncognito.com',
  cognitoRedirectSignIn: 'http://localhost:8091/',
  cognitoRedirectSignOut: 'http://localhost:8091/',
  cognitoResponseType: 'code',
  companyURL: 'https://myapptracking.net/',
  mapboxToken: 'pk.eyJ1IjoicmVuYXR0b3RyaW5pZGFkIiwiYSI6ImNrbTc0a3NzNTA4N3gydXFsOXEyYjRlNmwifQ.XwGFCNnHLEzVrErHnZdREQ',
  firebase: {
    apiKey: "AIzaSyCSlM0aY4ovmR3y_WcTjyv6z7oa3sDLhqI",
    authDomain: "mtracking-push.firebaseapp.com",
    projectId: "mtracking-push",
    storageBucket: "mtracking-push.appspot.com",
    messagingSenderId: "1076350122027",
    appId: "1:1076350122027:web:b56203b9abf56fd315126e",
  }
};

// //prod
// export const environment = {
//   production: true,
//   userPoolId: 'us-west-2_o6LqnrNdi',
//   clientId: '50cmalvk98346vn3e5kmlkhptq',
//   apiUrl: 'https://7e4nmnspf5.execute-api.us-west-2.amazonaws.com/devqa',
//   accessKeyId: 'AKIATHG7LMYEQDP226E6',
//   secretAccessKey: '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',
//   region: 'us-west-2',
//   bucketName: 'mtracking-prod-content',
//   bucketNameApp: 'mtracking-prod-myapp',
//   regionSES: 'us-east-1',
//   emailUrl: 'https://myapptracking.com/',
//   emailImageUrl: 'https://rapi2go.myapptracking.com/assets/images/mLogo.png',
//   cloudFrontUrl: 'https://dul632w51fob4.cloudfront.net/',
//   identityPoolId: 'us-west-2:008e09ca-cd04-454f-bfee-3492fabf90bb',
//   cognitoDomain: 'mtracking-devqa-myapp.auth.us-west-2.amazoncognito.com',
//   cognitoRedirectSignIn: 'http://myapptracking.com/',
//   cognitoRedirectSignOut: 'http://myapptracking.com/',
//   cognitoResponseType: 'code',
//   mapboxToken: 'pk.eyJ1IjoicmVuYXR0b3RyaW5pZGFkIiwiYSI6ImNrbTc0a3NzNTA4N3gydXFsOXEyYjRlNmwifQ.XwGFCNnHLEzVrErHnZdREQ',
//   companyURL: 'https://rapi2go.myapptracking.com/',
//   firebase: {
//         apiKey: "AIzaSyCSlM0aY4ovmR3y_WcTjyv6z7oa3sDLhqI",
//         authDomain: "mtracking-push.firebaseapp.com",
//         projectId: "mtracking-push",
//         storageBucket: "mtracking-push.appspot.com",
//         messagingSenderId: "1076350122027",
//         appId: "1:1076350122027:web:b56203b9abf56fd315126e",
//       }
// };
