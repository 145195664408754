import { Component, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbGlobalPosition, NbThemeService,
  NbToastrService, NbGlobalPhysicalPosition, NbComponentStatus
} from '@nebular/theme';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
// import themeListJson from '../../../../assets/data/themes/themes.json';
import { UserService } from 'src/app/services/user.service';
import { UserChangePasswordComponent } from 'src/app/pages/auth/user-change-password/user-change-password.component';
import * as moment from 'moment';

@Component({
  selector: 'app-user-my-profile',
  templateUrl: './user-my-profile.component.html',
  styleUrls: ['./user-my-profile.component.scss'],
  providers: [UserChangePasswordComponent]
})
export class UserMyProfileComponent implements OnInit {
  user = {} as User;
  userRoleShow;
  user1=new Array();
  userDB = {} as User;
  selected="/";
  // themes = themeListJson;
  loading: boolean;
  image="";

  constructor(
    private dialogService: NbDialogService,
    private userService: UserService,
    private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private router: Router,
  ) { }

  async ngOnInit() {
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    this.user = userLogged;
    
    await this.getUserList();
    
    // await this.getUser(userLogged.userCognitoCode);
  }
  async getUser(){
    let user12 = {} as User;
    if (localStorage.getItem('user')) {
      user12 = JSON.parse(localStorage.getItem('user'));
    }
    var user=user12.user;
    this.user1.forEach(element => {
      if (element.user == user) {
        console.log(element);
        this.userDB = element;
        this.user.userAvatar=this.userDB.userAvatar
        this.image = "assets/images/avatar/"+ this.user.userAvatar +".png"
        console.log(this.image);
        this.user.userNumberPhone=this.userDB.userNumberPhone
        this.user.userNit=this.userDB.userNit
        this.user.userBirthDate= this.userDB.userBirthDate
        // this.user.userBirthDate = moment(element.userBirthDate).format("DD/MM/YYYY");
        this.user.userBirthDate = moment(this.user.userBirthDate).toDate();
        this.user.userBirthDate.setMinutes(this.user.userBirthDate.getMinutes() + this.user.userBirthDate.getTimezoneOffset())
        
     
      }

    });
    
    // console.log(this.userDB);

  }
  async getUserList(){
   
    // console.log(user12);
    // var user=user12.user;
    // let user1 = new Array();
    this.userService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.user1 = response.records[0];
          console.log(response);
          
          this.getUser();
          
        }
      },
      error => {
        console.log(error);
      }
    );
    
  }

  async changeAvatar(idAvatar){
    await this.getUser();
    
    this.userDB.userAvatar=idAvatar;
    
    this.userService.save(this.userDB).then(
      response => {
        
        if (response.result == true) {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          window.location.reload();
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.errorMessage, { position, status });
          console.log(response.errorMessage);
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    );
  }
  async changeUser(){
    
    this.userDB.userAvatar=this.user.userAvatar;
    this.userDB.name = this.user.name;
    this.userDB.userNumberPhone = this.user.userNumberPhone;
    this.userDB.userBirthDate = this.user.userBirthDate;
    this.userDB.userBirthDate = moment(this.userDB.userBirthDate).toDate();
    this.userDB.userBirthDate.setMinutes(this.userDB.userBirthDate.getMinutes() + this.userDB.userBirthDate.getTimezoneOffset())
    if(this.user.userNit !== "" && this.user.userNit !== null && this.user.userNit !== undefined){
      this.userDB.userNit = this.user.userNit;
    }else{
      this.user.userNit = "";
    }
    
    this.userService.save(this.userDB).then(
      response => {
        
        if (response.result == true) {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          window.location.reload();
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.errorMessage, { position, status });
          console.log(response.errorMessage);
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    );
  }

  // getUser(userCode) {
  //   this.loading = true;
  //   this.userService.oneUserCognito(userCode).then(
  //     (result: any) => {
  //       const userCognito = result;
  //       const userId = userCognito.Username;
  //       const email = userCognito.UserAttributes.find((x) => x.Name === 'email');
  //       const name = userCognito.UserAttributes.find((x) => x.Name === 'name');
  //       const picture = userCognito.UserAttributes.find((x) => x.Name === 'picture');
  //       const role = userCognito.UserAttributes.find((x) => x.Name === 'custom:role');
  //       const group = userCognito.UserAttributes.find((x) => x.Name === 'custom:group');
  //       const company = userCognito.UserAttributes.find((x) => x.Name === 'custom:company');
  //       const theme = userCognito.UserAttributes.find((x) => x.Name === 'custom:theme');
  //       const status = userCognito.Enabled;
  //       if (role.Value === 'administrator') { roleListJson.push({ type: 'Administrador', value: 'administrator' }); }
  //       const roleShow = roleListJson.find((x) => x.value === role.Value);
  //       let groupShow;
  //       this.user = {
  //         userCode,
  //         userCognitoCode: 
  //       } as User;
  //       this.userRoleShow = roleShow.type;
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show('', err, { position, status });
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );

  // }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  // addUserImage() {
  //   this.dialogService.open(UserMyProfileImageComponent,
  //     {
  //       context: {
  //         user: this.user
  //       },
  //       hasBackdrop: true,
  //       closeOnEsc: false,
  //       closeOnBackdropClick: false
  //     }).onClose
  //     .subscribe(
  //       (user) => {
  //         this.user = user;
  //       });
  // }

  // saveUser() {
  //   const user = {
  //     userId: this.user.userId,
  //     userName: this.user.name,
  //     userTheme: this.user.theme,
  //     userPicture: this.user.picture,
  //   };
  //   this.userService.updateUserCognitoProfile(user).then(
  //     (result) => {
  //       let userLogged: User = JSON.parse(localStorage.getItem('user'));
  //       userLogged.picture = this.user.picture;
  //       localStorage.setItem('user', JSON.stringify(userLogged));
  //       this.authService.userProfileChanged.next(true);
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'success';
  //       this.toastrService.show(status || 'Success', 'Guardado', { position, status });
  //       this.loading = false;
  //     }
  //   ).catch(
  //     (err) => {
  //       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  //       const status: NbComponentStatus = 'danger';
  //       this.toastrService.show(
  //         status || 'Danger',
  //         err.message,
  //         { position, status });
  //       this.loading = false;
  //     }
  //   );
  // }

  cancel() {
    this.router.navigateByUrl('/');
  }

  changePassword() {
    console.log(this.user);
    this.dialogService.open(UserChangePasswordComponent,
      {
        
        context: {
          user: this.user
        
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
  }

}
