<nb-card class="col-md-5 mx-auto" accent="info">
    <nb-card-header>Seguimiento de tarea    /  Estado: {{this.idStatusName}}</nb-card-header>
    <nb-card-body>

        <div class="row" *ngIf="task.idStatus == 3 || task.idStatus == 4">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Recolectar con: </label><br />
                    <span>Nombre: {{task.petitionerName}}</span><br />
                    <span>Teléfono: {{task.petitionerPhone}}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Realizar una llamada a {{task.petitionerName}}: </label><br />
                    <!-- <a href="tel:+50245977747"><i class="fa fa-phone-square fa-3x" aria-hidden="true"></i> </a> -->
                    
                    <button nbButton style="width: 100%;"><a href="tel:+502{{task.petitionerPhone}}" style="width: 100%; font-size: 10px;"><i class="fa fa-phone-square fa-3x" aria-hidden="true" style="text-align: center;">&nbsp; Llamar</i>
                    </a>
                    </button>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Recolectar en la siguiente Dirección:</label><br />
                    <span>{{task.addressPetitioner}}</span>
                </div>
            </div>
            
            <div class="col-sm-6" *ngIf="task.condominiumNameSend != null && task.condominiumNameSend != ''">
                <div class="form-group">
                    <label for="task" class="label">Nombre de Condominio</label><br />
                    <span>{{task.condominiumNameSend}}</span>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="task.accessCodeSend != null && task.accessCodeSend != ''">
                <div class="form-group">
                    <label for="task" class="label">Codigo de Acceso</label><br />
                    <span>{{task.accessCodeSend}}</span>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Municipalidad:</label><br />
                    <span>{{task.petitioner_municipality}}</span>
                </div>
            </div>
            <div class="col-sm-6" >
                <div class="form-group">
                    <label for="task" class="label">Zona:</label><br />
                    <span>{{task.petitioner_zone}}</span>
                </div>
            </div>
                <div class="col-sm-6" >
                    <div class="form-group">
                        <label for="task" class="label">Tipo de Dirección:</label><br />
                        <span>{{task.petitioner_type}}</span>
                    </div>
                </div>
            
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Tipo de transporte: </label><br />
                    <span>{{task.nameVehicle}}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Monto a Cobrar: </label><br />
                    <!-- <span>{{task.codeTask}}</span>
                    <span> - </span> -->
                    <span>{{task.amountTask | currency: 'Q'}}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Observaciones de la tarea: &nbsp;</label>
                    <span>{{task.comentario}}</span>
                </div>
            </div>

        </div>


        <div class="row" *ngIf="task.idStatus == 5 || task.idStatus == 6">
            <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Destinatario: </label><br />
                            <span>Nombre:  {{task.customerName}}</span><br />
                            <span>Telefono:  {{task.customerPhone}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Realizar una llamada a {{task.customerName}}: </label><br />
                            <!-- <a href="tel:+50245977747"><i class="fa fa-phone-square fa-3x" aria-hidden="true"></i> </a> -->
                            
                            <button nbButton style="width: 100%;"><a href="tel:+502{{task.customerPhone}}" style="width: 100%; font-size: 10px;"><i class="fa fa-phone-square fa-3x" aria-hidden="true" style="text-align: center;">&nbsp; Llamar</i>
                            </a>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Dirección entrega:</label><br />
                            <span>{{task.addressCustomer}}</span>
                        </div>
                    </div>
                    
                    <div class="col-sm-6" *ngIf="task.condominiumNameReceiver != null && task.condominiumNameReceiver != ''">
                        <div class="form-group">
                            <label for="task" class="label">Nombre de Condominio</label><br />
                            <span>{{task.condominiumNameReceiver}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="task.accessCodeReceiver != null && task.accessCodeReceiver != ''">
                        <div class="form-group">
                            <label for="task" class="label">Codigo de Acceso</label><br />
                            <span>{{task.accessCodeReceiver}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Municipalidad:</label><br />
                            <span>{{task.receiver_municipality}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6" >
                        <div class="form-group">
                            <label for="task" class="label">Zona:</label><br />
                            <span>{{task.receiver_zone}}</span>
                        </div>
                    </div>
                        <div class="col-sm-6" >
                            <div class="form-group">
                                <label for="task" class="label">Tipo de Dirección:</label><br />
                                <span>{{task.receiver_type}}</span>
                            </div>
                        </div>
            
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Monto a Cobrar: </label><br />
                    <!-- <span>{{task.codeTask}}</span>
                    <span> - </span> -->
                    <span>{{task.amountTask | currency: 'Q'}}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="task" class="label">Observaciones de la tarea: &nbsp;</label>
                    <span>{{task.comentario}}</span>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12" style="display: flex!important;justify-content: center!important;" *ngIf="task.idStatus == 4 || task.idStatus == 6">
                <button class="boton" (click)="getLocation()"><p  class="iniciar" *ngIf="task.idStatus == 4">Ir a recolectar el pedido</p><p class="iniciar"  *ngIf="task.idStatus == 6">Ir a entregar el pedido</p></button>
                
            </div>
            
            
            
            
            
            <div class="col-sm-12" style="display: flex!important;justify-content: center!important;" *ngIf="task.idStatus == 4 || task.idStatus == 6">
            <div class="col-sm-8">
                <form novalidate [formGroup]="formImport" style="padding-top: 1.2rem !important;">
                  <div class="input-group mb-3">
                    <div class="custom-file">
                      <input nbInput type="file" class="custom-file-input" multiple formControlName="importFile" id="importFile" accept="image/x-png,image/gif,image/jpeg" (change)="onFileChange($event.target.files)" style="width: 100%!important;">
                      <label class="custom-file-label" #labelImport for="importFile"><i class="fas fa-search"></i>Cargar Fotografía</label>
                    </div>
                  </div>
                </form>
              </div>


              <!-- <div class="col-sm-12" style="text-align: center!important;">
                <span *ngIf="employee.employeePhoto == null || employee.employeePhoto == undefined || employee.employeePhoto == ''">
                    <img src="../../../../assets/images/user.png" style="opacity: 80%; text-align: left!important; margin-bottom: 0px !important; width: 8rem;" >
                </span>
                <span *ngIf="employee.employeePhoto != null || employee.employeePhoto != undefined || employee.employeePhoto != ''">
                    <img [src]="employee.employeePhoto" style="opacity: 80%; text-align: left!important; margin-bottom: 0px !important; width: 8rem;" >
                </span>
                <label class="custom-file-label" #labelImportP2 for="importFile2"> <img src="../../../../assets/images/edit.png" style="width: 1.5rem;opacity: 50%;cursor: pointer;"></label>
                
                  <span  for="importFile2" (click)="importFile2.click()" style="font-size: 4rem;"><div class="upload_arrow">  </div></span>
                  <input nbInput type="file" class="custom-file-input" #importFile2 id="importFile2" accept="image/x-png,image/gif,image/jpeg" (change)="image2($event.target.files)" hidden>
                  
            </div> -->
            
        <!-- </div> -->

            <div class="col-sm-4">
                <!-- <button style="margin-top: 18px!important; width: 100%!important;" nbButton (click)="upload()">Subir</button> -->
              </div>
            </div>
              <div class="col-sm-12" style="display: flex!important;justify-content: center!important;">
                
                
                    <!-- <sw-item-list *ngFor="let item of list" [inside]="item" [item-class]="'list-custom'" [editTemplate]="editTemplate" [trashTemplate]=null (callback)="save($event)" (swipeCb)="swipeCallback($event)">
                    </sw-item-list> -->
                    <button nbButton (click)="save()" style="width: 100%; height: 75px;">{{status}}
                    </button>
                
            </div>
        </div>

        <div class="row">

            <div class="col-sm-12">
                <br><br>
              </div>
            
        </div>
        <nb-card class="col-sm-12" accent="info">
            <nb-card-header>Información detallada de tarea</nb-card-header>
            <nb-card-body>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Observación: &nbsp;</label>
                            <span>{{task.comentario}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">No. tarea / Monto: </label><br />
                            <span>{{task.codeTask}}</span>
                            <span> - </span>
                            <span>{{task.amountTask | currency: 'Q'}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Sucursal: </label><br />
                            <span>{{task.sucursalName}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Transporte: </label><br />
                            <span>{{task.nameVehicle}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Remitente: </label><br />
                            <span>{{task.petitionerName}}</span><br />
                            <span>{{task.petitionerPhone}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Dirección recolección:</label><br />
                            <span>{{task.addressPetitioner}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Municipalidad:</label><br />
                            <span>{{task.petitioner_municipality}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6" >
                        <div class="form-group">
                            <label for="task" class="label">Zona:</label><br />
                            <span>{{task.petitioner_zone}}</span>
                        </div>
                    </div>
                        <div class="col-sm-6" >
                            <div class="form-group">
                                <label for="task" class="label">Tipo de Dirección:</label><br />
                                <span>{{task.petitioner_type}}</span>
                            </div>
                        </div>
                    <div class="col-sm-6" *ngIf="task.condominiumNameSend != null && task.condominiumNameSend != ''">
                        <div class="form-group">
                            <label for="task" class="label">Nombre de Condominio de recolección</label><br />
                            <span>{{task.condominiumNameSend}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="task.accessCodeSend != null && task.accessCodeSend != ''">
                        <div class="form-group">
                            <label for="task" class="label">Codigo de Acceso de Condominio de recolección</label><br />
                            <span>{{task.accessCodeSend}}</span>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Destinatario: </label><br />
                            <span>{{task.customerName}}</span><br />
                            <span>{{task.customerPhone}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Dirección entrega:</label><br />
                            <span>{{task.addressCustomer}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="task" class="label">Municipalidad:</label><br />
                            <span>{{task.receiver_municipality}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6" >
                        <div class="form-group">
                            <label for="task" class="label">Zona:</label><br />
                            <span>{{task.receiver_zone}}</span>
                        </div>
                    </div>
                        <div class="col-sm-6" >
                            <div class="form-group">
                                <label for="task" class="label">Tipo de Dirección:</label><br />
                                <span>{{task.receiver_type}}</span>
                            </div>
                        </div>
                    <div class="col-sm-6" *ngIf="task.condominiumNameReceiver != null && task.condominiumNameReceiver != ''">
                        <div class="form-group">
                            <label for="task" class="label">Nombre de Condominio de entrega</label><br />
                            <span>{{task.condominiumNameReceiver}}</span>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="task.accessCodeReceiver != null && task.accessCodeReceiver != ''">
                        <div class="form-group">
                            <label for="task" class="label">Codigo de Acceso de Condominio de entrega</label><br />
                            <span>{{task.accessCodeReceiver}}</span>
                        </div>
                    </div>
        
                </div>
            </nb-card-body>
        </nb-card>

    </nb-card-body>
</nb-card>



<ng-template #editTemplate>
    <i class="fa fa-check-square-o fa-10x"></i>
</ng-template>