import { Component, OnInit } from '@angular/core';
import { Person } from '../../models/person';
import { ClientService } from '../../services/client.service';
import { Address } from '../../models/address';
import { AddressService } from '../../services/address.service';
import { PersonAddress } from '../../models/person-address';
import { PersonAddressService } from '../../services/person-address.service';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client-address',
  templateUrl: './client-address.component.html',
  styleUrls: ['./client-address.component.scss'],
  providers: [ClientService, AddressService, PersonAddressService, NbToastrService]
})
export class ClientAddressComponent implements OnInit {

  public person: Person;
  public address: Address;
  public personAddress: PersonAddress;
  public _address: Address[];
  
  constructor(
    private _clientService: ClientService,
    private _addressService: AddressService,
    private _personAddress: PersonAddressService,
    private toastrService: NbToastrService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.person = new Person(0, null, null, null, null, 1,0);
    this.address = new Address(0, null, 1);
    this.personAddress = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");
  }

  ngOnInit() {
    this._route.paramMap.subscribe(async (params: any) => {
      const idClient = params.params.idClient;

      //console.log(idClient);

      if (idClient !== undefined) {
        this.personAddress.idCustomer = idClient;
      }
    });

    this.getAddress();
  }

  getAddress() {
    this._addressService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this._address = response.records[0];
          //console.log(response);
        }
      }, error => {
        console.log();
      }
    )
  }

  save() {
    //console.log(this.personAddress);
    this.personAddress.idStatus = 1;
    this._personAddress.save(this.personAddress).subscribe(
      response => {
        if (response.result == true) {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          //form.reset();
          this.goToRequest();
        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
   }

   goToRequest() {
    this._router.navigate(
      ['/taskRequest']
    );
  }

}
