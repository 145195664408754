import { Component, OnInit } from '@angular/core';
import { Sucursal } from '../../models/sucursal';
import { SucursalService } from '../../services/sucursal.service';
import { Report } from '../../models/report';
import { ReportService } from '../../services/report.service';
import { NbToastrService, NbGlobalPosition, 
  NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-administration-report',
  templateUrl: './administration-report.component.html',
  styleUrls: ['./administration-report.component.scss'],
  providers: [SucursalService, ReportService]
})
export class AdministrationReportComponent implements OnInit {

  public sucursales: Sucursal[];
  public report: Report;
  public _report: Report[];
  public reportList = new LocalDataSource();
  export: boolean;

  constructor(
    private _sucursalService: SucursalService, 
    private _reportService: ReportService,
    private toastrService: NbToastrService,
    ) { 
    this.report = new Report(1, null, null, null, null);
  }

  ngOnInit() {
    this.export = false;
    this.getAllSucursales();
  }

  getAllSucursales() {
    this._sucursalService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.sucursales = response.records[0];
          //        console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: false,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    columns: {
      dateShipping: {
        title: 'Fecha recolección',
        editable: false
      },
      id: {
        title: 'Tarea',
        editable: false
      },
      petitionerName: {
        title: 'Remitente',
        editable: false
      },
      petitionerAddress: {
        title: 'Dirección recolección',
        editable: false
      },
      receiverName: {
        title: 'Destinatario',
        editable: false
      },
      receiverAddress: {
        title: 'Dirección entrega',
        editable: false
      },
      kmTraveled: {
        title: 'Kms recorridos',
        editable: false
      },
      amount: {
        title: 'Cobro por recorrido',
        editable: false
      },
      carrierName: {
        title: 'Repartidor',
        editable: false
      },
      vehicleName: {
        title: 'Tipo Vehículo',
        editable: false
      },
      commission: {
        title: 'Comisión repartidor',
        editable: false
      },
      dateReceiption: {
        title: 'Fecha entrega',
        editable: false
      },
      statusName: {
        title: 'Estado',
        editable: false
      }
    }
  };

  generar(){

    if(this.report.idSucursal == null){
      this.report.idSucursal = 0;
    }
    this.report.idSucursal=2;
    //console.log(this.report);

    this._reportService.getAdminReport(this.report).subscribe(
      response => {
        if(response.result == true){
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          //console.log(response.records);
          this._report = response.records[3];
          this.reportList = new LocalDataSource(this._report);
          this.export = true;
        }else{
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
    
  }

  async downloadToExcel() {
    const checkList = await this.reportList.getFilteredAndSorted()
    const exportFile = new Array<any>();
    // console.log(checkList);
    checkList.forEach(element => {
      let json = {};
      Object.keys(this.settings.columns).forEach((item, index, arr) => {
        if (this.settings.columns[item].type === "number") {
          json[this.settings.columns[item].title] = parseFloat(element[item]);
        } else {
          json[this.settings.columns[item].title] = element[item];
        }
      });
      exportFile.push(json);
    });
    const ws: xlsx.WorkSheet =
      xlsx.utils.json_to_sheet(exportFile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte.xlsx');
  }

}
