// import { AuthResenValidComponent } from './../auth-resen-valid/auth-resen-valid.component';
// import { UserResetPasswordComponent } from './../user-reset-password/user-reset-password.component';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
// import { UserConfirmationComponent } from '../user-confirmation/user-confirmation.component';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Global } from 'src/app/services/global';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  public url: string;
  loading = false;
  urlLog = 'assets/images/mLogo.png';
  urlAlt;
  resetPassword = false;
  resendVCode = false;
  gmailLogin = false;
  azureLogin = false;
  constructor(
    private userService: UserService,
    private dialogService: NbDialogService,
    private router: Router,
  ) {
    this.url = Global.url;
  }

  ngOnInit() {
    const companyLogo = window.location.hostname;
    this.urlAlt = companyLogo.split('.')[0];
  }

  onSubmit(form: NgForm) {
    const email = form.value.email;
    const password = form.value.password;
    this.userService.signIn(email, password);
  }
  signIn(){
    
    this.router.navigate(['/signIn']);
  }
  // async signInWithGoogle() {
  //   this.loading = true;
  //   await this.authService.socialSignIn(AuthService.GOOGLE).then(
  //     (auth) => {
  //       this.loading = false;
  //     }
  //   ).catch(
  //     (err) => {
  //       this.loading = false;
  //     }
  //   ).finally(
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  // async signInWithAzureAD() {
  //   window.location.assign(
  //     'https://' +
  //     environment.cognitoDomain +
  //     '/oauth2/authorize?' +
  //     'identity_provider=office365&redirect_uri=' +
  //     environment.cognitoRedirectSignIn +
  //     '&response_type=code&client_id=' +
  //     environment.clientId +
  //     '&scope=aws.cognito.signin.user.admin+email+openid+phone+profile'
  //   );
  // }

  // onClickValidateUser() {
  //   this.dialogService.open(UserConfirmationComponent,
  //     {
  //       hasBackdrop: true,
  //       closeOnEsc: false,
  //       closeOnBackdropClick: false
  //     });
  // }

  // onResendValid() {
  //   this.dialogService.open(AuthResenValidComponent,
  //     {
  //       hasBackdrop: true,
  //       closeOnEsc: false,
  //       closeOnBackdropClick: false
  //     });
  // }

  // onClickResetPassword() {
  //   this.dialogService.open(UserResetPasswordComponent,
  //     {
  //       hasBackdrop: true,
  //       closeOnEsc: true,
  //       closeOnBackdropClick: false
  //     });
  // }

}
