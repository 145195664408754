<nb-card class="col-md-5 mx-auto" accent="info">
    <nb-card-header>Ingreso de clientes</nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-sm-10">
                <div class="form-group">
                    <label for="address" class="label">Dirección</label>
                    <input type="text" nbInput fullWidth name="address" [(ngModel)]="personAddress.address"
                        required="true" nbTooltip="Dirección de cliente" nbTooltipPlacement="top" />
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="button" class="label label-button">boton</label>
                    <a routerLink="" nbButton ghost status="basic">
                        <nb-icon icon="pin-outline"></nb-icon>
                    </a>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="form-group">
                    <label for="idCatAddress" class="label">Tipo Dirección: </label>
                    <nb-select fullWidth id="idCatAddress" name="idCatAddress" [(ngModel)]="personAddress.idCatAddress"
                        placeholder="Tipo Dirección">
                        <nb-option [value]="address.id" *ngFor="let address of _address">
                            {{address.name}}</nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="button" class="label label-button">boton</label>
                    <button nbButton ghost status="success" (click)="save()">
                        <nb-icon icon="save-outline"></nb-icon>
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>