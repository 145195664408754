import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from '../../models/task';
import { User } from '../../models/user';
import { TaskService } from '../../services/task.service';
import { TrackingStatusService } from 'src/app/services/trackingStatus.service';
import { UserService } from 'src/app/services/user.service';
import { TrackingStatus } from '../../models/trackingStatus';
import { TaskTracking } from '../../models/taskTracking';
@Component({
  selector: 'app-task-tracking',
  templateUrl: './task-tracking.component.html',
  styleUrls: ['./task-tracking.component.scss'],
  providers: [TaskService,TrackingStatusService]
})
export class TaskTrackingComponent implements OnInit {

  public task: Task;
  // public tasks: Task[];
  tasks: Task[] = [];
  public tasks1: Task[];
  public user: User;
  public tasksList = new LocalDataSource();
  public _idCognito;
  public _idRol;
  public _informacionTarea;
  public _tittle;
  public _event;
  public user1=new Array();
  public userDB = {} as User;
 
  public taskTracking = {} as TaskTracking;
  public carriers: User[];
  public trackingStatus: TrackingStatus[];
  status = "";
  public _taskStatus;

  constructor(
    private _trackingStatusService: TrackingStatusService,
    private _taskService: TaskService,
    private _router: Router,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private userService: UserService,
  ) {

    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    this._idCognito = user.idCognito ? user.idCognito : null;
    this._idRol = user.idRol ? user.idRol : null;
    // this._idRol = 4;
    this.task = new Task(0, null, null, null, null, null, null, null,null, null, null, null, null, null, null, null, 1,
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,null,null,null,null,null,null,null,null,null,null,0);
  }

  async ngOnInit() {
    await this.getUserList();
    // if (this._idRol == 4) {
    //   this.getAllTasksByCarrier(this._idCognito);
    // } else {
    //   this.getAllTasks();
    // }

  }
  async getUser(){
    let user12 = {} as User;
    if (localStorage.getItem('user')) {
      user12 = JSON.parse(localStorage.getItem('user'));
    }
    var user=user12.user;
    this.user1.forEach(element => {
      if (element.user == user) {
        this.userDB = element;
        this._idRol = this.userDB.idRol;
        if (this._idRol == 4) {
          this.getAllTasksByCarrier(this._idCognito);
        } else {
          this.getAllTasks();
        }
      }

    });
    
    

  }
  async getUserList(){
   
    // console.log(user12);
    // var user=user12.user;
    // let user1 = new Array();

    this.userService.getAll().subscribe(
      response => {
        if (response.result == true) {
          //console.log(response);
          this.user1 = response.records[0];
          //console.log(this.user1);
          // console.log(this.user1);
          this.getUser();
          
        }
      },
      error => {
        console.log(error);
      }
    );
    
  }
  getAllTasksByCarrier(_idCarrier) {

    const params = {
      "idCarrier": _idCarrier
    };

    this._taskService.getAllTasksByCarrier(params).subscribe(
      response => {
        if (response.result == true) {
          this.tasks1 = response.records[1];
          
          this.tasks1.forEach(element => {
            if (element.idStatus === 2) {
              this.tasks.push(element);
            }
          });
          // console.log(this.tasks);
          this.tasksList = new LocalDataSource(this.tasks);
        
         
        }
      },
      error => {
        console.log();
      }
    );

  }

  getAllTasks() {
    this._taskService.getAllTasks().subscribe(
      response => {
        if (response.result == true) {
          this.tasks = response.records[0];
          this.tasksList = new LocalDataSource(this.tasks);
          
        }
      },
      error => {
        console.log();
      }
    );
  }
  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-check' title='Empezar tarea'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='fa fa-map-marker' title='Ir'></i>"
    },
    columns: {
      addressSend: {
        title: 'Dirección de Recolección',
        editable: false
      },
      addressReceiver: {
        title: 'Dirección de Entrega',
        editable: false
      },
      customPetitioner: {
        title: 'Envia:',
        editable: false
      },
      customPhone: {
        title: 'Número de Teléfono',
        editable: false
      }
    }
  };
  settings1 = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: true,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='fa fa-map-marker' title='Ir'></i>"
    },
    columns: {
      codeTask: {
        title: 'Tarea #',
        editable: false
      },
      dateShipping: {
        title: 'Fecha tarea',
        editable: false
      },
      sucursalName: {
        title: 'Remitente',
        editable: false
      },
      totalPackages: {
        title: 'Paquetes',
        editable: false
      },
      amount: {
        title: 'Valor',
        editable: false
      }
    }
  };

  _settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: true,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='fa fa-minus-square-o' title='Eliminar'></i>"
    },
    columns: {
      id: {
        title: 'Tarea #',
        editable: false
      },
      _dateShipping: {
        title: 'Fecha tarea',
        editable: false
      },
      _addressPetitioner: {
        title: 'Dirección de recolección',
        editable: false
      },
      _addressReceiver: {
        title: 'Dirección de recepción',
        editable: false
      },
      _carrier: {
        title: 'Repartidor',
        editable: false
      },
      _status: {
        title: 'Estado',
        editable: false
      }
    }
  };

  deleteTask(event) {
    //console.log(event);

    this.task.id = 1;
    this.task.comentario = event.data.codeTask;

    this._taskService.save(this.task).subscribe(
      response => {
        if (response.result == true) {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this.getAllTasks();
          this.task = new Task(0, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,null,null,null,null,null,null,null,null,null,null,0);

        } else {
          let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )

  }

  async trackingTask(event) {
    
    // this._router.navigate(
    //   ['/carrierTracking/' + event.data.id]
    // );
    await this.save(event.data.id,event.data.idStatus);
    
  }
  async save(taskId,idStatus) {
    this.taskTracking.idTask = taskId;
    this.taskTracking.idStatus = idStatus + 1;
    this.taskTracking.kmTraveled = "0";
    
    this._trackingStatusService.updateTaskTracking(this.taskTracking).subscribe(
      response => {
        if (response.result == true) {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          this._router.navigate(
            ['/taskProgress']
          );
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  getInfoTask(dialog: TemplateRef<any>, event) {
    this._informacionTarea = event.data;
    this._event = event.data;

    this.dialogService.open(dialog, { context: '' });

    //console.log(event.data);
  }

  goToReAssign(){
    // console.log(this._event.id);
    this._router.navigate(
      ['/task-carrier-assign/' + this._event.id]
    );

  }

  getLocation(event, dialog: TemplateRef<any>) {

    let _latitude;
    let _longitude;
    let _response;
    let _idStatus = event.data.idStatus;

    if (_idStatus == 1 || _idStatus == 2 || _idStatus == 3) {
      _latitude = event.data.latitudeSucursal;
      _longitude = event.data.longitudeSucursal;

      _response = "https://www.waze.com/ul?ll="+_latitude+"%2C"+_longitude+"&navigate=yes&zoom=15";
      this._tittle = "Recolección";

    } else if (_idStatus == 4) {
      _latitude = event.data.latitudeCustomer;
      _longitude = event.data.longitudeCustomer;

      _response = "https://www.waze.com/ul?ll="+_latitude+"%2C"+_longitude+"&navigate=yes&zoom=15";
      this._tittle = "Destino";

    }else{
      _response = "Ubicación ya no disponible";
      this._tittle = "No diponible";
    }


    //console.log(_response);

    this.dialogService.open(dialog, { context: _response });
  }


}
