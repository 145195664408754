<nb-card class="col-md-5 mx-auto" accent="info">
    <nb-card-header>Ingreso de clientes</nb-card-header>
    <nb-card-body>
        <form #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="nit" class="label">NIT</label>
                        <input type="text" nbInput fullWidth name="nit" [(ngModel)]="person.nit" required="true" autocomplete="false" nbTooltip="NIT" nbTooltipPlacement="top" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="name" class="label">Nombre</label>
                        <input type="text" nbInput fullWidth name="name" [(ngModel)]="person.name" required="true" nbTooltip="Nombre de cliente" nbTooltipPlacement="top" />
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="phone" class="label">Teléfono</label>
                        <input type="text" nbInput fullWidth name="phone" [(ngModel)]="person.phone" required="true" maxlength="8" autocomplete="false" nbTooltip="Número de teléfono" nbTooltipPlacement="top" />
                    </div>
                </div>
                <div class="col-sm-7">
                    <div class="form-group">
                        <label for="mail" class="label">Correo Electrónico</label>
                        <input type="email" nbInput fullWidth name="mail" [(ngModel)]="person.mail" required="true" nbTooltip="Correo electrónico de cliente" nbTooltipPlacement="top" />
                    </div>
                </div>
                <div class="col-sm-5">
                    <div class="form-group">
                        <label for="idCatAddress" class="label">Tipo Dirección: </label>
                        <nb-select fullWidth id="idCatAddress" name="idCatAddress" [(ngModel)]="personAddress.idCatAddress" placeholder="Tipo Dirección">
                            <nb-option [value]="address.id" *ngFor="let address of _address">
                                {{address.name}}</nb-option>
                        </nb-select>
                    </div>
                </div>
                <div class="col-sm-10">
                    <div class="form-group">
                        <label for="address" class="label">Dirección</label>
                        <input type="text" nbInput fullWidth name="address" [(ngModel)]="personAddress.address" required="true" nbTooltip="Dirección de cliente" nbTooltipPlacement="top" />
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label for="button" class="label label-button">boton</label>
                        <a routerLink="" nbButton ghost status="basic">
                            <nb-icon icon="pin-outline"></nb-icon>
                        </a>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <button nbButton ghost status="success">
                            <nb-icon icon="save-outline"></nb-icon>
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>