import { Component, OnInit } from '@angular/core';
import { Person } from '../../models/person';
import { ClientService } from '../../services/client.service';
import { Address } from '../../models/address';
import { AddressService } from '../../services/address.service';
import { PersonAddress } from '../../models/person-address';
import { PersonAddressService } from '../../services/person-address.service';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  providers: [ClientService, AddressService, PersonAddressService]
})
export class ClientComponent implements OnInit {

  public person: Person;
  public address: Address;
  public personAddress: PersonAddress;
  public _address: Address[];
  public mPerson: Person;

  constructor(
    private _clientService: ClientService,
    private _addressService: AddressService,
    private _personAddress: PersonAddressService,
    private toastrService: NbToastrService,
    private _router: Router

  ) {
    this.person = new Person(0, null, null, null, null, 1,0);
    this.address = new Address(0, null, 1);
    this.personAddress = new PersonAddress(0, null, null, null, null, null, 1,0,0,"","");
    this.person = new Person(0, null, null, null, null, 1,0);
  }

  ngOnInit() {
    
    this.getAddress();
  }

  getAddress() {
    this._addressService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this._address = response.records[0];
          //console.log(response);
        }
      }, error => {
        console.log();
      }
    )
  }

  onSubmit(form) {
    /*  console.log(this.person);
        console.log(this.personAddress); */
    this._clientService.save(this.person).subscribe(
      response => {
        if (response.result == true) {
          this.personAddress.idCustomer = response.records[0].idClient;
          //console.log(response.records[0].idClient);
          this._personAddress.save(this.personAddress).subscribe(
            response => {
              if (response.result == true) {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'success';
                this.toastrService.show(status || 'Success', response.message, { position, status });
                form.reset();
                this.goToRequest();
              } else {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'warning';
                this.toastrService.show(status || 'Warning', response.message, { position, status });
              }
            },
            error => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(status || 'Danger', <any>error, { position, status });
            }
          )
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  goToRequest() {
    this._router.navigate(
      ['/taskRequest']
    );
  }

}
