export class Sucursal{
	constructor(
		public id : number,
		public idCompany : number,
		public sucursalName : string,
		public contactName : string,
	    public contactMail : string,
	    public contactPhone  : string,
		public address : string,
		public latitude : string,
		public longitude : string,
	    public idStatus : number
	){}
}