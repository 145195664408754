import { Component, ElementRef, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { LocationService } from '../../services/location.service';
import * as moment from 'moment';


interface MarcadorColor {
  color: string;
  marker?: mapboxgl.Marker;
  centro?: [number, number];
  name: string;
}

@Component({
  selector: 'app-carrier-location',
  templateUrl: './carrier-location.component.html',
  styleUrls: ['./carrier-location.component.scss'],
  providers: [LocationService]
})
export class CarrierLocationComponent implements AfterViewInit, OnInit {

  public _latitude;
  public _longitude;

  public _lon;
  public _lat;

  @ViewChild('map', { static: false }) divMap!: ElementRef;
  map!: mapboxgl.Map;
  zoomLevel: number = 10;
  center: [number, number] = [-90.49322418747106, 14.580160123137151];

  // Arreglo de marcadores
  marcadores: MarcadorColor[] = [];
  arrLocation = new Array();
  locationCarrier: [number, number];

  constructor(
    private locationService: LocationService,
  ) { }

  async ngAfterViewInit() {

    await this.getPositionAllCarriers();

    this.map = new mapboxgl.Map({
      container: this.divMap.nativeElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: 14,
    });

  }

  ngOnInit() {
  }

  getPositionAllCarriers() {
    this.locationService.getAllCarriers().then(
      response => {
        console.log(response.records[0]);

        response.records[0].forEach(e => {
          console.log(e);
          var hour="";
          if (e.date != null) {
            e.date = moment(e.date).toDate();
            e.date.setMinutes(e.date.getMinutes() + e.date.getTimezoneOffset())   
            var time = moment(e.date).format("HH:mm:ss");
             
              
            var date = moment(e.date).format("DD/MM/YYYY");
            
            hour = " últ. vez: "+date + " a las "+time;
          }
          if (e.longitude != null) {
            this.arrLocation.push({
              longitude: e.longitude,
              latitude: e.latitude,
              name: e.name + hour
            });
          }

        });

        this.arrLocation.forEach(e => {
          console.log(this.arrLocation);
          this.addMarker(e.longitude, e.latitude, e.name);
        });

        //console.log(this.arrLocation);

      }, error => {
        console.log(error);
      }
    )
  }

  /* Manejo de mapas */

  addMarker(longitude, latitude, name) {

    // const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));
    const color = "#CDCDCD";

    this.locationCarrier = [longitude, latitude];
    // console.log(this.locationCarrier);
    const nuevoMarcador = new mapboxgl.Marker({
      draggable: true,
      color
    })
      .setLngLat(this.locationCarrier)
      .addTo(this.map);
     

    this.marcadores.push({
      color,
      marker: nuevoMarcador,
      name: name
    });

    // this.guardarLocalStorage();

    // nuevoMarcador.on('dragend', () => {
    //   this.guardarLocalStorage();
    // });

  }

  getMarker(marker: mapboxgl.Marker) {
    this.map.flyTo({
      center: marker.getLngLat()
    });
  }

  // guardarLocalStorage() {

  //   const lngLatArr: MarcadorColor[] = [];

  //   this.marcadores.forEach(m => {

  //     const color = m.color;
  //     const { lng, lat } = m.marker!.getLngLat();

  //     lngLatArr.push({
  //       color: color,
  //       centro: [lng, lat],
  //       nombre: "Marcador"
  //     });

  //   })

  //   localStorage.setItem('marcadores', JSON.stringify(lngLatArr));

  // }

  // leerCoordenadas(sucursal) {

  //   const color = "#xxxxxx".replace(/x/g, y => (Math.random() * 16 | 0).toString(16));
  //   const centro: [number, number] = [sucursal.longitude, sucursal.latitude];

  //   const newMarker = new mapboxgl.Marker({
  //     color: color,
  //     draggable: true
  //   })
  //     .setLngLat(centro)
  //     .addTo(this.map);

  //   this.marcadores.push({
  //     marker: newMarker,
  //     color: color
  //   });

  //   newMarker.on('dragend', () => {
  //     this.guardarLocalStorage();
  //   });

  // }

  // borrarMarcador(i: number) {

  //   this.marcadores[i].marker.remove();
  //   this.marcadores.splice(i, 1);
  //   this.guardarLocalStorage();
  // }

}
