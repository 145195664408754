<div #map class="map-container">
    <ul class="list-group">
        <li class="list-group-item list-group-item-secondary" (click)="addMarker()">
            + Agregar
        </li>

        <li class="list-group-item" (click)="getMarker(marcador.marker)" (dblclick)="borrarMarcador(i)" *ngFor="let marcador of marcadores; let i = index" [ngStyle]="{
            'background-color': marcador.color
        }">
            Marcador
        </li>

    </ul>
</div>