import { Component, OnInit } from '@angular/core';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from '../../models/task';
import { TaskService } from '../../services/task.service';

@Component({
  selector: 'app-task-assignment',
  templateUrl: './task-assignment.component.html',
  styleUrls: ['./task-assignment.component.scss'],
  providers: [TaskService]
})
export class TaskAssignmentComponent implements OnInit {

  public task: Task;
  public tasks: Task[];
  public tasksList = new LocalDataSource();

  constructor(
    private _taskService: TaskService,
    private _router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getAllTasks();
  }

  getAllTasks() {
    this._taskService.getAllTaskToAsign().subscribe(
      response => {
        if (response.result == true) {
          this.tasks = response.records[0];
          console.log(this.tasks);
          this.tasksList = new LocalDataSource(this.tasks);
          //console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-plus-square' title='Aignar Piloto'></i>"
    },
    columns: {
      //codeTask: {
     //   title: 'Tarea #',
      //  editable: false
       id: {
         title: 'Tarea #',
         editable: false
       },
      sucursalName: {
        title: 'Nombre del repartidor',
        editable: false
      },
      petitionerName: {
        title: 'Remitente',
        editable: false
      },
      customerName: {
        title: 'Destinatario',
        editable: false
      },
      dateShipping: {
        title: 'Fecha envío',
        editable: false
      },
      addressRecoleccion: {
        title: 'Dirección de recolección',
        editable: false
      },
      addressRecepcion: {
        title: 'Dirección de recepción',
        editable: false
      },
      nameVehicle: {
        title: 'Vehiculo',
        editable: false
      }
    }
  };

  assignTask(event) {
    this._router.navigate(
      ['/task-carrier-assign/' + event.data.id]
    );
  }

}
