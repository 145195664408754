<app-loading *ngIf="loading"></app-loading>
<nb-layout>

    <nb-layout-header responsive *ngIf="isLogged === true">
        <app-header></app-header>
    </nb-layout-header>

    <nb-sidebar #leftSidebar state="expanded" tag="left" responsive *ngIf="isLogged === true">
        <app-sidebar></app-sidebar>
    </nb-sidebar>

    <nb-layout-column class="backgroundStyle" responsive>
        <router-outlet></router-outlet>
    </nb-layout-column>

    <nb-layout-footer responsive *ngIf="isLogged === true">
        <app-footer></app-footer>
    </nb-layout-footer>

</nb-layout>