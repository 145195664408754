<!-- <nb-card class="col-md-10 mx-auto" accent="info">
  <nb-card-header>Listado de Dias de la semana</nb-card-header>
  <nb-card-body>
    <ng2-smart-table (edit)="assignTask($event)" [settings]="settings" [source]="tasksList">

    </ng2-smart-table>
  </nb-card-body>
</nb-card> -->
<div style="background-color: white; height: 100%;">
  <div >
<div class="row">
  <div class="col-sm-5">



    <h6 style="text-align: center;">Listado de dias de la Semana</h6>

<table>
  <thead>
      <tr>
          <th style="text-align: center;">Día</th>
          <th style="text-align: center;">Disponible</th>
          <th style="text-align: center;">Hora de inicio de labores</th>
          <th style="text-align: center;">Hora de finalización de labores</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let days of tasks">
          <td>{{days.nameDay}}</td>
          <td><div class="row row2 rowT">
            <label for="municipalityStatus" class="label label2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <nb-toggle class="toggle" id="municipalityStatus" name="municipalityStatus"
                [(ngModel)]="days.close" [(checked)]="days.close">
            </nb-toggle>
            <label for="municipalityStatus" class="label label3">&nbsp;&nbsp;</label>
        </div></td>
          <td *ngIf="days.close">

              <input nbInput fullWidth required="true" name="timeShipping" [ngxTimepicker]="picker" [(ngModel)]="days.timeStart" nbTooltip="Hora de Inicio de labores" nbTooltipPlacement="top">
              <ngx-material-timepicker #picker></ngx-material-timepicker>

              <!-- <input type="text" [(ngModel)]="days.timeEnd" nbInput fullWidth /> -->
          </td>
          <td *ngIf="days.close">
                  <input nbInput fullWidth required="true" name="timeShipping" [ngxTimepicker]="picker1" [(ngModel)]="days.timeEnd" nbTooltip="Hora de Fin de labores" nbTooltipPlacement="top">
              <ngx-material-timepicker #picker1></ngx-material-timepicker>
          </td>
      </tr>
  </tbody>
</table>
<div class="row" style="margin-top: 1rem;">
  <button type="button" nbButton ghost style="color: #0A7E12;" (click)="save()">Guardar</button>

  <button class="button2" style="width: 50%!important" (click)="saveAllmonth()">
    Establecer Horarios al mes de {{monthLetter}} del {{year}}
   </button>
  <!-- <button type="button" nbButton ghost style="color: #C81016;" (click)="closeEmployee()">Cancelar</button>
  <button type="button" nbButton ghost style="color: #1378DE;" (click)="closeEmployee()">Regresar</button> -->
</div>
</div>


<div class="col-sm-6">
  <div class="row">
    <div class="col-sm-12" style="text-align: center;">
      <button class="button3" (click)="restMonth()">
        << prev
     </button>
<span>
  {{monthLetter}} del {{year}}
</span>
     <button class="button3" (click)="addMonth()">
       next >>
   </button>
    </div>
    
    <div class="col-sm-12" style="margin: 10px!important;border: solid #004e58 2px;">
      <div style="display: block;">
        <button class="button2">
              Lunes
        </button>
        <button class="button2">
              Martes
        </button>
        <button class="button2">
              Miércoles
        </button>
        <button class="button2">
              Jueves
        </button>
        <button class="button2">
              Viernes
        </button>
        <button class="button2">
              Sábado
        </button>
        <button class="button2">
              Domingo
        </button>

        <button *ngFor="let day of arrayDays" class="button" (click)="daySelected(day)" [disabled]="!day.enable ? 'disabled': null" >
         <span *ngIf="day.close"> {{day.date}} <span style="font-size: 8px!important; text-align: center;line-height: -1rem!important; "><br> {{day.timeStart}} - {{day.timeEnd}}</span></span> 
         <span *ngIf="!day.close && day.date!=''" style="font-weight: 500!important;color: #a70300;"> {{day.date}} <span style="font-size: 8px!important; text-align: center;line-height: -1rem!important; "><br> Cerrado</span></span> 
       </button>
        
      </div>
    </div>
    <div class="col-sm-12" style="border: solid #d9d9d9 1px;margin: 10px!important;" *ngIf="daySelec != null && daySelec!= undefined">
     

      <div class="row" style="margin-top: 1rem!important;">
        <div class="col-sm-8">
          <span style="font-weight: 800;">{{daySelec.dayLetter}} {{daySelec.date}} de {{daySelec.monthString}} del {{daySelec.year}} <br></span>
        </div>

        <div class="col-sm-4">
          <div class="row row2 rowT">
              <label for="municipalityStatus" class="label label2">No disponible</label>
              <nb-toggle class="toggle" id="municipalityStatus" name="municipalityStatus"
                  [(ngModel)]="daySelec.close" [(checked)]="daySelec.close">
              </nb-toggle>
              <label for="municipalityStatus" class="label label3">Disponible</label>
          </div>
      </div>

        <div class="col-sm-1" *ngIf="daySelec.close">

        </div>
        

        <div class="col-sm-5" *ngIf="daySelec.close">
          <span>Hora de Inicio de labores</span>
          <input nbInput fullWidth required="true" name="timeShipping" [ngxTimepicker]="picker3" [(ngModel)]="startC" nbTooltip="Hora de Inicio de labores" nbTooltipPlacement="top">
          <ngx-material-timepicker #picker3 ></ngx-material-timepicker>
        </div>
        <div class="col-sm-5" *ngIf="daySelec.close">
          <span>Hora de Fin de labores</span>
          <input nbInput fullWidth required="true" name="timeShipping" [ngxTimepicker]="picker4" [(ngModel)]="endC" nbTooltip="Hora de Fin de labores" nbTooltipPlacement="top">
          <ngx-material-timepicker #picker4></ngx-material-timepicker>
        </div>
        <br>
        <div class="col-sm-12" style="text-align: center; margin-bottom: 1rem!important;">
          
          <button class="button2" style="width: 30%!important" (click)="saveDay()">
           Guardar
          </button>
        </div>
      </div>
   

  </div>
   
    
   
  </div>

</div>
</div>

</div>

</div>