import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-auto-confirm',
  templateUrl: './auto-confirm.component.html',
  styleUrls: ['./auto-confirm.component.scss']
})
export class AutoConfirmComponent implements OnInit {
  loading = false;
  userName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(){
    this.route.paramMap.subscribe((params: any) => {
      const userEmail = params.params.userEmail;
      const verificationCode = params.params.verificationCode;
      if (userEmail !== undefined || verificationCode !== undefined) {
        this.userAutoValidation(userEmail, verificationCode);
      }
    });
  }

  userAutoValidation(userEmail, verificationCode) {
    this.loading = true;
    const usrName = userEmail;
    let userId = '';
    let password = '';
    this.userService.confirmUser(userEmail, verificationCode).then(
      (result) => {
        console.log("llega aqui")
        console.log(result);
        // this.userService.getUsers().then(
          // (resultUsers: any) => {
            // console.log("Lista de usuarios")
            // console.log(resultUsers)
            // const listUsers = resultUsers.Users;
            // listUsers.forEach(user => {
            //   const sub = user.Attributes.find((x) => x.Name === 'sub');
            //   const email = user.Attributes.find((x) => x.Name === 'email');
            //   console.log("Credenciales")
            //   console.log(sub);
            //   console.log(email);
            //   if (userEmail === email.Value) {
            //     userId = sub.Value;
            //   }
            // });
            console.log(usrName);
            this.userService.oneUserBackend(usrName).then(
              (resultUsers: any) => {
              console.log("resultados");
              console.log(resultUsers);
                console.log(resultUsers.records[1][0]);
                console.log(typeof (resultUsers));
                // if (typeof (resultUsers) === 'string') {
                //   console.log("resultadoI  "+ resultUsers);
                //   resultUsers = JSON.parse(resultUsers);
                // } else {
                //   console.log("resultadoE  "+ resultUsers);
                //   throw resultUsers.errorMessage;
                // }
                const userDB: User = resultUsers.records[1][0];
                // console.log("resultado  "+ resultUsers);
                password = userDB.password;
                // this.sesService.sendEmail(usrName, password, usrName, subdomain);
                let params = {
                  userName: usrName,
                  userPassword: password,
                  userEmail: usrName,
                  companyURL: environment.companyURL,
                }
                this.userService.sendEmail(params);
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Usuario validado',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.loading = false;
                this.router.navigate(['/login']);
              }
            ).catch((err) => {
              console.log(err);
              console.error(err);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo salio mal',
              })
              this.loading = false;
              this.router.navigate(['/login']);
            });
          // }
          
        // ).catch((err) => {
        //   console.error(err);
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'Oops...',
        //     text: 'Algo salio mal',
        //   })
        //   this.loading = false;
        //   this.router.navigate(['/login']);
        // });

      }
    ).catch(
      (err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Usuario o código invalido',
        })
        this.loading = false;
        this.router.navigate(['/login']);
      }
    );
  }

}
