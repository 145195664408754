import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Sucursal } from '../../models/sucursal';
import { SucursalService } from '../../services/sucursal.service';
import { Rol } from '../../models/rol';
import { RolService } from '../../services/rol.service';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { formatCurrency } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [RolService, UserService, SucursalService]
})
export class UserComponent implements OnInit {

  public user: User;
  public users: User[];
  public roles: Rol[];
  public sucursales: Sucursal[];

  constructor(

    private _userService: UserService,
    private _rolService: RolService,
    private _sucursalService: SucursalService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,

  ) {
    this.user = new User(0, null, '', '', '', null, 1, '', 0,0,'','',null);
  }

  ngOnInit() {
    this.getAllRoles();
    this.getAllSucursales();
    this.getAllUsers();
  }

  getAllSucursales() {
    this._sucursalService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.sucursales = response.records[0];
          //        console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllRoles() {
    this._rolService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.roles = response.records[0];
          //        console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllUsers() {
    this._userService.getAll().subscribe(
      response => {
        if (response.result == true) {
          this.users = response.records[0];
          console.log(this.users);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: true,
      add: true,
      columnTitle: "",
      position: "right",
      class: "action-colum"
    },
    add: {
      addButtonContent: "<i class='fa fa-check-square-o' title='Agregar'></i>"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    delete: {
      deleteButtonContent: "<i class='fa fa-minus-square-o' title='Eliminar'></i>"
    },
    columns: {
      sucursalName: {
        title: 'Sucursal',
        editable: false
      },
      name: {
        title: 'Nombre',
        editable: false
      },
      user: {
        title: 'Correo electrónico',
        editable: false
      },
      rol: {
        title: 'Rol',
        editable: false
      }
    }
  };

  async onSubmit(form) {
    this.user.password = this.generatePassword();
    console.log(this.user);
    if (this.user.id == 0) {
      const UserSub: any = await this._userService.insertUserCognito(this.user);
      this.user.idCognito = UserSub.UserSub;
    } else {
      // await this._userService.updateUserCognito(this.user);
    }
    this._userService.save(this.user).then(
      response => {
        console.log(response);
        if (response.result == true) {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          form.reset();
          this.getAllUsers();
          this.getAllSucursales();
          
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.errorMessage, { position, status });
          console.log(response.errorMessage);
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  open(dialog: TemplateRef<any>) {
    this.user = new User(0, null, '', '', '', null, 1, '', 0,0,'','',null);
    this.dialogService.open(dialog, { context: '' });
  }

  editUser(dialog: TemplateRef<any>, e) {
    this.user = e.data;
    this.user.userBirthDate = moment(this.user.userBirthDate).toDate();
    this.user.userBirthDate.setMinutes(this.user.userBirthDate.getMinutes() + this.user.userBirthDate.getTimezoneOffset())
    this.dialogService.open(dialog, { context: '' });
    //console.log(this.user);
  }

  deleteUser(e) {
    console.log("Eliminar");
  }

  generatePassword() {
    // No cambiar los var loquea el generador.
    const length = 5;
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const punctuation = '!@#$%&';
    let retVal = '';
    for (var i = 0, n = lowercase.length; i < length; ++i) {
      retVal += lowercase.charAt(Math.floor(Math.random() * n));
      // tslint:disable-next-line:no-shadowed-variable
      for (var i = 0, n = uppercase.length; i < length; ++i) {
        retVal += uppercase.charAt(Math.floor(Math.random() * n));
        // tslint:disable-next-line:no-shadowed-variable
        for (var i = 0, n = numbers.length; i < length; ++i) {
          retVal += numbers.charAt(Math.floor(Math.random() * n));
          // tslint:disable-next-line:no-shadowed-variable
          for (var i = 0, n = punctuation.length; i < length; ++i) {
            retVal += punctuation.charAt(Math.floor(Math.random() * n));
          }
        }
      }
    }
    return retVal;
  }

}
