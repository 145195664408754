import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-task-tracing-client',
  templateUrl: './task-tracing-client.component.html',
  styleUrls: ['./task-tracing-client.component.scss']
})
export class TaskTracingClientComponent implements OnInit {

  constructor() { 
  }

  ngOnInit() {

    var map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-90.49322418747106, 14.580160123137151 ],
      zoom: 15,
    });
    
  }

}
