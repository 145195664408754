import { Component, OnInit } from '@angular/core';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from '../../models/task';
import { TaskService } from '../../services/task.service';
import { CompanyService } from '../../services/company.service';
import { SucursalService } from 'src/app/services/sucursal.service';
import {
  NbToastrService, NbGlobalPosition, NbComponentStatus, NbGlobalPhysicalPosition,
  NbDialogService
} from '@nebular/theme';
import * as moment from 'moment';
import { DaysWeekService } from 'src/app/services/daysWeek.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { element } from 'protractor';

@Component({
  selector: 'app-weekDays',
  templateUrl: './weekDays.component.html',
  styleUrls: ['./weekDays.component.scss'],
  providers: [TaskService, CompanyService,SucursalService,DaysWeekService]
})
export class weekDaysComponent implements OnInit {

  public task: Task;
  public tasks;
  public tasksList;
  public daysInMonth: number[] = [];
  public arrayDays = new Array();
  public month = 0;
  public monthLetter = "";
  public year = 2023;
  public daySelec;
  public startC;
  public endC;
  public user: User;
  public daysList = new Array();

  constructor(
    private _taskService: TaskService,
    private _router: Router,
    private _rolService: CompanyService,
    private _route: ActivatedRoute,
    private _sucursalService: SucursalService,
    private toastrService: NbToastrService,
    private daysService: DaysWeekService,
    private userService: UserService,
  ) { 
    this.getDaysYear();
    this.generateDaysInMonth(new Date());
    this.getAllTasks();
  }

  ngOnInit() {
   
  }
  generateDaysInMonth(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const lastDay = new Date(year, month + 1, 0); 
    this.month = lastDay.getMonth()+1;
    this.year=year;
    
  }

  getDaysYear() {
    // const userLogged = JSON.parse(localStorage.getItem('user')) as User;
    // console.log(localStorage)
    
    this.daysService.getAllYearDays(2).subscribe(
      response => {
        
        if (response.result == true) {
          this.daysList = response.records[1];
          // console.log(response);
          // this.tasks = response.records[1];
          
          // this.tasks.forEach(element => {
          //   // element.timeEnd =moment(element.timeEnd).format('HH:MM');
          //   // element.timeEnd = element.timeEnd.date('mm:ss');
          //   console.log(element.timeEnd);
          //   if (element.close == 'false' || element == '0') {
          //     element.close = false;
          //   }else{
          //     element.class = true;
          //   }
          // });
          // this.tasksList = new LocalDataSource(this.tasks);
          // console.log(response);
          this.getDays();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getDays(){
    // const userLogged = localStorage
    const userLogged = JSON.parse(localStorage.getItem('user')) as User;
   
    let dateString = this.month+"/01/"+this.year;
   
    let date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = date.getMonth();
    let day = date.getDay();
    if (day == 0) {
      day = 7;
    }

    const lastDay = new Date(year, month + 1, 0).getDate();
    let tope = lastDay+day-1;
    this.arrayDays = new Array();
    this.monthLetter = NumeroAMeses(parseInt(this.month+''))
    let dayy = 0;
    for (let i = 1; i <= tope; i++) {
     
      if (dayy> 7) {
        dayy = 0;
      }
      dayy ++;
  
     if (i>= day) {
      let dayInt = i-day+1;
      
      let findDay = this.daysList.find(x=> x.date == dayInt && x.month == this.month && x.year == this.year);
      
      if (findDay != undefined && findDay != null) {
        
        if (findDay.close == 1 || findDay.close=='true') {findDay.close = true}else{findDay.close = false}
        if (findDay.enable == 1 || findDay.enable =='true') {findDay.enable = true}else{findDay.enable = false}

        let color = '';
        if (!findDay.close) {
          color = 'background-color: #e11b2c!important;';
        }
        findDay.color = color;
        findDay.userEdit = userLogged.idCognito;
        this.arrayDays.push(findDay);
      }else{
       
        let color = '';
       
        let object = {
          idDay:0,
          idSucursal:userLogged.idSucursal,
          date : dayInt,
          day : dayy,
          dayLetter : NumeroADias(dayy),
          monthString: NumeroAMeses(this.month),
          fullDate: dayInt+ '/'+this.month+'/'+this.year,
          month:this.month,
          year:this.year,
          timeStart:'',
          timeEnd:'',
          close: true,
          userEdit:userLogged.idCognito,
          enable:true,
          color:color
        }
        this.arrayDays.push(object)
      }
      
     
     }else{
      let object = {
        date : '',
        monthString: NumeroAMeses(this.month),
        day : dayy,
        dayLetter : NumeroADias(dayy),
        fullDate: '',
        month:this.month,
        year:this.year,
        enable:false
      }
      this.arrayDays.push(object)
     }
      
    }

  }

  saveAllmonth(){
    const userLogged = JSON.parse(localStorage.getItem('user')) as User;
    

    this.arrayDays.forEach(element=>{
      let find = this.tasks.find(x=>x.idDay == element.day);
      if (find != undefined && find != null) {
        element.userEdit = userLogged.idCognito;
        element.timeStart = find.timeStart;
      element.timeEnd = find.timeEnd;
      element.close = find.close;
      }

      
    })

    let ob = {list:this.arrayDays}
    this.daysService.saveYearAllDays(ob).subscribe(
      response => {

        this.save();
      
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    );

  }

  daySelected(event){
      this.startC = '';
      this.endC = '';
      this.startC = event.timeStart;
      this.endC = event.timeEnd;
      this.daySelec = event;
  }

  addMonth(){
    this.month = this.month+1;
    if (this.month > 12) {
      this.year = this.year+1;
      this.month = 1;
    }
    this.getDays();
  }
  restMonth(){
    this.month = this.month-1;
    if (this.month < 1) {
      this.year = this.year-1;
      this.month = 12;
    }
    this.getDays();
  }

  saveDay(){
    if (!this.daySelec.close) {
      if (this.daySelec.close) {this.daySelec.close = 'true'}else{this.daySelec.close = 'false'}
      if (this.daySelec.enable) {this.daySelec.enable = 'true'}else{this.daySelec.enable = 'false'}
      this.daySelec.timeStart = this.startC;
      this.daySelec.timeEnd = this.endC;
      this.saveOneDay(this.daySelec);
    }else if (this.startC == '' || this.endC == '' || this.endC == null || this.startC== null || this.startC == undefined || this.endC==undefined) {
      let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      let status: NbComponentStatus = 'danger';
      this.toastrService.show(status || 'Danger', 'Ingrese los horarios de labores', { position, status });
    }else{
      if (this.daySelec.close) {this.daySelec.close = 'true'}else{this.daySelec.close = 'false'}
      if (this.daySelec.enable) {this.daySelec.enable = 'true'}else{this.daySelec.enable = 'false'}
      this.daySelec.timeStart = this.startC;
      this.daySelec.timeEnd = this.endC;
      this.saveOneDay(this.daySelec);
    }
    
  }

  getAllTasks() {
    this.daysService.getAll(2).subscribe(
      response => {
        
        if (response.result == true) {
        
          this.tasks = response.records[1];
          
          this.tasks.forEach(element => {
            // element.timeEnd =moment(element.timeEnd).format('HH:MM');
            // element.timeEnd = element.timeEnd.date('mm:ss');
            
            if (element.close == 'false' || element.close == '0') {
              element.close = false;
            }else{
              element.close = true;
            }
          });
          this.tasksList = new LocalDataSource(this.tasks);
          
        }
      },
      error => {
        console.log(error);
      }
    );
  }



  async saveOneDay(element){
    this.daysService.saveYearDays(element).subscribe(
      response => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', "Actualizado Exitosamente", { position, status });

          setTimeout(() => {
            window.location.reload();
          }, 300);
      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    );
  
    
  }

  async save(){
    // let limit= this.tasks.length;
    // let limit1 = 1;
    // await this.tasks.forEach(element => {
    //   if (element.close) {
    //     element.close = true;
    //   }else{
    //     element.close = false;
    //   }
    //   this.daysService.save(element).subscribe(
    //     response => {
    //       limit1++;
    //       if (response.result == true) {
    //         if (limit<limit1) {
    //           let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    //         let status: NbComponentStatus = 'success';
    //         this.toastrService.show(status || 'Success', "Actualizado Exitosamente", { position, status });
    //         }
            
    //       } else {
    //         let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    //         let status: NbComponentStatus = 'warning';
    //         this.toastrService.show(status || 'Warning', response.message, { position, status });
    //       }
    //     },
    //     error => {
    //       let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    //       let status: NbComponentStatus = 'danger';
    //       this.toastrService.show(status || 'Danger', <any>error, { position, status });
    //     }
    //   );
    // });
 
    let ob = {list:this.tasks}
    
    console.log(ob)
    this.daysService.save(ob).subscribe(
      response => {
        console.log(response)
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          let status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', "Actualizado Exitosamente", { position, status });

          setTimeout(() => {
            window.location.reload();
          }, 300);

      },
      error => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    );

    
  
    
  }
  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      nameDay: {
        title: 'Día',
        editable: false
      },
      timeEnd: {
        title: 'Hora de inicio de labores',
        editable: true
      },
      timeStart: {
        title: 'Hora de Finalización de labores',
        editable: true
      }
    }
  };

  assignTask(event) {
    this._router.navigate(
      ['/task-carrier-assign/' + event.data.id]
    );
  }

}


function NumeroAMeses(mes){
  if (mes === 1) {
    return "ENERO"
  }else if (mes === 2) {
    return "FEBRERO"
  }
  else if (mes === 3) {
    return "MARZO"
  }
  else if (mes === 4) {
    return "ABRIL"
  }
  else if (mes === 5) {
    return "MAYO"
  }
  else if (mes === 6) {
    return "JUNIO"
  }
  else if (mes === 7) {
    return "JULIO"
  }
  else if (mes === 8) {
    return "AGOSTO"
  }
  else if (mes === 9) {
    return "SEPTIEMBRE"
  }
  else if (mes === 10) {
    return "OCTUBRE"
  }
  else if (mes === 11) {
    return "NOVIEMBRE"
  }
  else if (mes === 12) {
    return "DICIEMBRE"
  }
}

function NumeroADias(dia){
  if (dia === 1) {
    return "LUNES"
  }else if (dia === 2) {
    return "MARTES"
  }
  else if (dia === 3) {
    return "MIÉRCOLES"
  }
  else if (dia === 4) {
    return "JUEVES"
  }
  else if (dia === 5) {
    return "VIERNES"
  }
  else if (dia === 6) {
    return "SABADO"
  }
  else if (dia === 7) {
    return "DOMINGO"
  }
}



