import { Component, OnInit } from '@angular/core';
import { Person } from '../../models/person';
import { CarrierService } from '../../services/carrier.service';
import { NbToastrService, NbGlobalPosition, 
		     NbComponentStatus, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';    

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.scss'],
  providers: [CarrierService]
})
export class CarrierComponent implements OnInit {

  public person : Person;
  public carriers: Person[];
  public carriersList = new LocalDataSource();

  constructor(
    private _carrierService: CarrierService,
    private toastrService: NbToastrService,
    private router: Router
  ) {
    this.person = new Person(0, null, null, null, null, 1,0);
   }

  ngOnInit() {
    this.getAllCarriers();
  }

  getAllCarriers(){
    this._carrierService.getAll().subscribe(
      response => {
        if(response.result == true){
          this.carriers = response.records;
          this.carriersList = new LocalDataSource(this.carriers);
        }
      },
      error => {
        console.log();
      }
    );
  }

  settings = {
    hideSubHeader: false,
    mode: "external",
    actions: {
      edit: true,
      delete: false,
      add: false,
      columnTitle: "",
      position: "left",
      class: "action-colum"
    },
    edit: {
      editButtonContent: "<i class='fa fa-pencil-square-o' title='Editar'></i>"
    },
    columns: {
      name: {
        title: 'Nombre',
        editable: false
      },
      phone: {
        title: 'Teléfono',
        editable: false
      },
      mail: {
        title: 'Correo electrónico',
        editable: false
      },
      createAt: {
        title: 'Fecha de ingreso',
        editable: false
      }
    }
  };

  onSubmit(form){
    //console.log(this.person);
    this._carrierService.save(this.person).subscribe(
      response => {
        if(response.result == true){
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', response.message, { position, status });
          form.reset();
          this.getAllCarriers();
        }else{
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  addAddress(event){
    this.router.navigate(
      ['/personAddress/' + event.data._id]
    );
  }

}
