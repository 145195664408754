import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Task } from '../../models/task';
import { User } from '../../models/user';
import { UserToken } from '../../models/userToken';
import { TrackingStatus } from '../../models/trackingStatus';
import { TaskTracking } from '../../models/taskTracking';
import { TaskService } from '../../services/task.service';
import { UserService } from '../../services/user.service';
import { TrackingStatusService } from '../../services/trackingStatus.service';
import { LocationService } from 'src/app/services/location.service';
import {
  NbToastrService, NbGlobalPosition,
  NbComponentStatus, NbGlobalPhysicalPosition
} from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from '../../services/messaging.service';
const uuid = require('uuid');

import { FormControl, FormGroup, Validators } from '@angular/forms';

import contractStatus from '../../../../assets/tables/contractStatus.json';
import filteredContracts from '../../../../assets/tables/filteredContracts.json';

@Component({
  selector: 'app-carrier-tracking',
  templateUrl: './carrier-tracking.component.html',
  styleUrls: ['./carrier-tracking.component.scss'],
  providers: [TaskService, UserService, TrackingStatusService,ViewChild,LocationService]
})
export class CarrierTrackingComponent implements OnInit {
  @ViewChild('labelImport',null)
  labelImport: ElementRef;
  public task = {} as Task;
  task1;
  public userToken: UserToken[];
  public taskTracking = {} as TaskTracking;
  public carriers: User[];
  public trackingStatus: TrackingStatus[];
  status = "";
  public _taskStatus;
  formImport: FormGroup;
  fileToUpload: File = null;
  base64;
  latitudUser;
  longitudUser;
  text;
  user;
  fileLocation='';
  
  idStatusName="";
  public list: any = [];
  
  constructor(
    private toastrService: NbToastrService,
    private _route: ActivatedRoute,
    private _taskService: TaskService,
    private _userService: UserService,
    private _trackingStatusService: TrackingStatusService,
    private _router: Router,
    private locationService: LocationService,
    private messagingService: MessagingService
  ) {
    this.formImport = new FormGroup({
      importFile: new FormControl('', Validators.required)
   });
  }

  ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.user = user;
    this._route.paramMap.subscribe(async (params: any) => {
      const idTask = params.params.idTask;

      if (idTask !== undefined) {
        this.task.id = params.params.idTask;
        this.getOneTask(idTask);

        this.getAllCarrier();
      }
    });
  }

  getOneTask(idTask) {
    this._taskService.getOneTaskToAsign(idTask).subscribe(
      response => {
        if (response.result == true) {
          this.task = response.records[1][0];
          this.task1= this.task;
        
          this.idStatusName = this.task1.idStatusName;
          this.getAllStatus(this.task.idStatus);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllCarrier() {
    this._userService.getCarrier().subscribe(
      response => {
        if (response.result == true) {
          this.carriers = response.records[0];
          // console.log(response);
        }
      },
      error => {
        console.log();
      }
    );
  }

  getAllStatus(idStatus) {
    const _idStatus = { id: idStatus };

    this._trackingStatusService.getNext(_idStatus).subscribe(
      response => {
        if (response.result == true) {
          this.trackingStatus = response.records[1];

          this.status = this.trackingStatus[0].name;
          this.trackingStatus.forEach((i) => {
            this.list.push({ id: i.id, title: i.name });
          })
        }
      },
      error => {
        console.log();
      }
    );
  }

  action = (a) => {
    console.log(a);
  };

  swipeCallback = (a) => {
    //console.log('Callback Swipe', a);
  }

  save() {
    this.taskTracking.idTask = this.task.id;
    this.taskTracking.idStatus = this.task.idStatus + 1;
    this.taskTracking.kmTraveled = "0";
    
      this.taskTracking.imgTracking = this.fileLocation;
    
    
    
    this._trackingStatusService.updateTaskTracking(this.taskTracking).subscribe(
      response => {
        if (response.result == true) {
          // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          // const status: NbComponentStatus = 'success';
          // this.toastrService.show(status || 'Success', "Paso Cerrado Exitosamente", { position, status });
          // //form.reset();
          // this.getUserAdminToken();
          // // this.goToTaskTracking();
          if (this.taskTracking.idStatus >= 7) {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show(status || 'Success', "FELICITACIONES!! Tarea terminada exitosamente", { position, status });
          //form.reset();
            this.getUserAdminToken();
            
           this._router.navigate(
            ['/taskProgress']
          );
          }else{
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'success';
            this.toastrService.show(status || 'Success', "Paso Cerrado Exitosamente", { position, status });
            this.getUserAdminToken();
            setTimeout(function(){
            
              window.location.reload();
          },1000);
          }
         
          
        } else {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show(status || 'Warning', response.message, { position, status });
        }
      },
      error => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(status || 'Danger', <any>error, { position, status });
      }
    )
  }

  goToTaskTracking() {
    this._router.navigate(
      ['/taskProgress']
    );
  }

  /* Metodo para utilizar notificaciones push */
  getUserAdminToken() {
    this._userService.getUserTokenAdminPush().subscribe(
      response => {
        if (response.result == true) {
          this.userToken = response.records[0];

          let title = 'Notificación mTracking';
          let message = "Se actualizo el estado de la tarea: " + this.task.codeTask;

          this.userToken.forEach(m => {
            // Operar los mensajes para el admin de tarea nueva
            const params = {
              "notification": {
                "title": title,
                "body": message
              },
              "to": m.tokenPush
            };

            this.messagingService.sendMessage(params).subscribe(
              response => {
                //console.log(response);
              },
              error => {
                console.log(error);
              }
            );

          });

          //console.log(this.userToken);
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  // onFileChange(files: FileList) {
  //   this.labelImport.nativeElement.innerText = Array.from(files)
  //     .map(f => f.name)
  //     .join(', ');
  //   this.fileToUpload = files.item(0);
  //   console.log(this.fileToUpload);
  //   this.upload();
  // }

  onFileChange(files: FileList) {

    if (files.length>0) {
      if(files[0].name != undefined){
        // this.p2 = files.item(0);
        this.fileToUpload = files.item(0);
        this.upload();
       


      }
    }
 
    
  }
  

  // async upload(): Promise<any>{
    
  //   var f = this.fileToUpload; // FileList object
  //   var reader = new FileReader();
  //   // Closure to capture the file information.
  //   reader.onload = () => {
     

  //       // Cambio de ruta para almacenamiento
  //       var binaryData = reader.result;
       
  //       //Converting Binary Data to base 64
  //       const base64String = window.btoa(binaryData.toString());
  //       const contractCode = uuid.v4();
  //       this._trackingStatusService.uploadFileS3(this.fileToUpload, contractCode).then(
  //         response => {
  //           console.log(response)
  //         },
  //         err => {
  //         }
  //       )
  //   };
  //   // Read in the image file as a data URL.
  //   reader.readAsBinaryString(f);
  // }


  async upload(){
    const contractCode = uuid.v4();
    
    await this._trackingStatusService.uploadFileS3(this.fileToUpload, contractCode).then(
      (responsePI: any) => {
      
       this.fileLocation = responsePI.Location;
       const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
       const status: NbComponentStatus = 'success';
       this.toastrService.show(status || 'Success', "Imagen Cargada con éxito", { position, status });

      }
      )
  }


  async getPositionAllCarriers() {

    let _latitude;
    let _longitude;
    let _response;
    let _idStatus = this.task.idStatus;
    if (_idStatus == 4) {
      _latitude = this.task1.latitudePetitioner;
      _longitude = this.task1.longitudePetitioner;
      

      _response = "https://www.waze.com/ul?ll="+_latitude+"%2C"+_longitude+"&from=ll."+this.latitudUser+"%2C"+this.longitudUser+"&navigate=yes&zoom=15";
      window.open(_response, '_blank');

    } else if (_idStatus == 6) {
      _latitude = this.task.latitudeCustomer;
      _longitude = this.task.longitudeCustomer;

      _response = "https://www.waze.com/ul?ll="+_latitude+"%2C"+_longitude+"&from=ll."+this.latitudUser+"%2C"+this.longitudUser+"&navigate=yes&zoom=15";
      window.open(_response, '_blank');

    }else{
      _response = "Ubicación ya no disponible";
      
    }


   

    
  }

  async getLocation() {
    var a = true;
    this.locationService.getAllCarriers().then(
      response => {
        response.records[0].forEach(e => {
         
          if (e.userCognitoId === this.user.idCognito) {
            this.latitudUser = e.latitude;
            this.longitudUser = e.longitude;
            this.getPositionAllCarriers();
            a = false;
          }

        });
        if(a){
          this.getPositionAllCarriers();
        }
        // this.arrLocation.forEach(e => {
        //   //console.log(this.arrLocation);
        //   this.addMarker(e.longitude, e.latitude, e.name);
        // });

        //console.log(this.arrLocation);

      }, error => {
        console.log(error);
      }
    )
  }

}
